import Button from "./Button";
import AccessibilitySettings from "./settings/AccessibilitySettings";
import EyeGazePie from "./eyegaze/Pie";
import EyeGazeTarget from "./eyegaze/Target";
import EyeGazeConfig from "./settings/EyeGazeConfig";
import SwitchConfig from "./settings/SwitchConfig";
import SwitchRowSelector from "./SwitchRowSelector";
import KeyMap from '../keymap';

let _accessControllerInstance = null;

/**
 *
 * Accessibility Manager for working with Switches and Eye-Gaze
 *
 * TODO Docs!
 *
 * @namespace earthpixi.Access
 *
 */
export default class AccessibilityManager extends PIXI.utils.EventEmitter{
    static get Instance()
    {
        return _accessControllerInstance;
    }

    get Button()
    {
        return Button;
    }

    get AccessibilitySettings()
    {
        return AccessibilitySettings;
    }

    get EyeGazeConfig()
    {
        return EyeGazeConfig;
    }

    get SwitchConfig()
    {
        return SwitchConfig;
    }

    get DwellAnimation()
    {
        return this.settings.eyeGazeConfig.anim === "default" ? EyeGazeTarget : EyeGazePie;
    }

    constructor()
    {
        super();

        if (!_accessControllerInstance)
        {
            _accessControllerInstance = this;
            this.init();
        }
        // singleton, dont initiate again
        return _accessControllerInstance;
    }

    init()
    {
        this.localStorageString = "EarthpixiAccessibilitySettings";
        /**
         * @name newUser
         * @memberof earthpixi.Access
         * @type {boolean}
         */
        this.newUser = true;
        /**
         * @name settings
         * @memberof earthpixi.Access
         * @type {earthpixi.Access.AccessibilitySettings}
         */
        this.settings = this.loadLocal();
        this.clickSound = null;
        this.directEyeGazeMode = false;
        this.switchableButtons = [];
        this.gazeableButtons = [];
        this.allButtons = [];
        this.switch1IsDown = false;
        this.switch2IsDown = false;
        this.switchTimeOutId = null;
        this.switchDebounceBind = this.switchCancelDebounce.bind(this);
        this.switchAutoKeyUpBind = this.switchAutoKeyUp.bind(this);
        this.onKeyDownBind = this.onSwitchKeyDown.bind(this);
        this.onKeyUpBind = this.onSwitchKeyUp.bind(this);
        this.nowScanning = false;
        this.currentSwitchIndex = 0;
        this.scanGlowFilter = new PIXI.filters.GlowFilter(5 * earthpixi.resolution, 5, 1, 0xFF0000, 0.3);
        this.scanGlowFilter.resolution = earthpixi.resolution;
        this.soloButtonArr = null;

        this._block = false;

        this.switchRowSelector = new SwitchRowSelector(this.scanGlowFilter);

        this._egTargetClass = EyeGazeTarget;
        this._egPieClass = EyeGazePie;

        earthpixi.GamePad.on(earthpixi.GamePad.EVENTS.BUTTON_DOWN, this.onGamePadButton, this);
    }

    block()
    {
        this.reset('block');
        this._block = true;
    }

    unblock()
    {
        this._block = false;
    }

    save()
    {
        window.localStorage.setItem(this.localStorageString, JSON.stringify(this.settings.toJSON()));
        this.newUser = false;
    }

    loadExternal(data) {
        console.log('loading external: ', data)
        console.log('this.settings before op: ', this.settings)
        // this.settings = this.settings.copy(data);
        this.settings.copy(data);
        
        // if(this.settings.enableEyeGaze) {}
        this.eyeGaze(this.settings.enableEyeGaze)
        this.switchAccess(this.settings.enableSwitch)
        this.keyboardAccess(this.settings.keyboardConfig.enableKeyboard)

        setTimeout(() => {
            console.log('::EPIXI:: loaded external settings: ', this.settings)
        })
    }

    /**
     * @function earthpixi.Access#loadLocal
     * @return {earthpixi.Access.AccessibilitySettings}
     */
    loadLocal() {
        const localSettings = window.localStorage.getItem(this.localStorageString);
        console.log("EPIXI:: loadLocal ", localSettings);

        // in case new settings have been added since user last saved;
        // create fresh settings and assin locally saved (avoid missing settings added to framework recently)
        const freshSettings = new AccessibilitySettings();

        if (localSettings != null)
        {
            // console.log("load existing local settings");
            this.newUser = false;

            const parsedSettings = JSON.parse(localSettings);

            console.log('EPIXI:: parsed settings: ', parsedSettings)

            freshSettings.copy(parsedSettings);

            return freshSettings;
        }

        return freshSettings;
    }

    /**
     * @function earthpixi.Access#resetSettings
     */
    resetSettings() {
        this.settings = new AccessibilitySettings();
    }

    add(accSpr) {
        if (accSpr.eyeGazeAble && this.gazeableButtons.indexOf(accSpr) == -1)
        {
            this.gazeableButtons.push(accSpr);
        }

        if (accSpr.switchable && this.switchableButtons.indexOf(accSpr) == -1)
        {
            this.switchableButtons.push(accSpr);

            this.switchableButtons.sort(function (a, b)
            {
                return a.tabIndex == b.tabIndex ? 0 : Number(a.tabIndex > b.tabIndex) || -1;
            });
        }

        if (this.allButtons.indexOf(accSpr) == -1)
        {
            this.allButtons.push(accSpr);
        }
    }

    remove(accSpr)
    {
        for (let i = this.gazeableButtons.length; i--;)
        {
            if (this.gazeableButtons[i] === accSpr)
            {
                this.gazeableButtons.splice(i, 1);
                break;
            }
        }

        for (let i = this.switchableButtons.length; i--;)
        {
            if (this.switchableButtons[i] === accSpr)
            {
                this.switchableButtons.splice(i, 1);
                break;
            }
        }

        for (let i = this.allButtons.length; i--;)
        {
            if (this.allButtons[i] === accSpr)
            {
                this.allButtons.splice(i, 1);
                break;
            }
        }
    }

    /**
     * @function earthpixi.Access#soloButtons
     * @param {earthpixi.Access.Button[] | PIXI.DisplayObject[] | PIXI.DisplayObject} object buttons array or container with children
     */
    soloButtons(containerOrButtonsArr)
    {
        // console.log("solo");
        this.reset('soloButtons');

        if (containerOrButtonsArr instanceof Array)
        {
            this.soloButtonArr = containerOrButtonsArr;
        }
        else
        {
            const arr = [];

            this._getChildButtons(containerOrButtonsArr, arr);
            this.soloButtonArr = arr;
        }

        // console.log("solo", this.soloButtonArr);
    }

    /**
     * Add extra buttons after you;ve already solo'd some
     * @function earthpixi.Access#soloAddButtons
     * @param {earthpixi.Access.Button[] | PIXI.DisplayObject[] | PIXI.DisplayObject} object buttons array or container with children
     */
    soloAddButtons(containerOrButtonsArr)
    {
        this.reset('soloAddButtons');

        if (!this.soloButtonArr)
        { this.soloButtonArr = []; }

        if (containerOrButtonsArr instanceof Array)
        {
            this.soloButtonArr = this.soloButtonArr.concat(containerOrButtonsArr);
        }
        else
        {
            const arr = [];

            this._getChildButtons(containerOrButtonsArr, arr);
            this.soloButtonArr = this.soloButtonArr.concat(arr);
        }
    }

    /**
     * @function earthpixi.Access#freeSoloButtons
     */
    freeSoloButtons()
    {
        // console.log("free solo");

        this.reset('freeSolo');
        this.soloButtonArr = null;
    }

    _getChildButtons(container, arrToFill)
    {
        for (let i = 0; i < container.children.length; i++)
        {
            const child = container.children[i];

            if (child.interactive)
            {
                arrToFill.push(child);
            }
            if (child.children && child.children.length > 0)
            {
                this._getChildButtons(child, arrToFill);
            }
        }
    }

    /**
     * 
     * @function earthpixi.Access#eyeGaze
     * @param {boolean} enable
     */
    eyeGaze(enable)
    {
        this.reset('eyeGaze');

        if (enable === undefined)
        {
            enable = this.settings.eyeGazeActive;
        }

        if (enable)
        {
            // console.log("eye gaze enabled");
            this.settings.eyeGazeActive = true;

            // make sure its off first
            if (this.eyeMoveTicker)
            {
                this.eyeMoveTicker.destroy();
            }

            this.currentEyeMovePosition = this.currentEyeMovePosition || { x: 0, y: 0 };
            this.eyeMoveTicker = new PIXI.ticker.Ticker();
            this.eyeMoveTicker.add(this.onGazeMoveTick, this);
            this.eyeMoveTicker.start();
        }
        else if (this.settings.eyeGazeActive)
        {
            this.settings.eyeGazeActive = false;
            if (this.eyeMoveTicker)
            {
                this.eyeMoveTicker.stop();
                this.eyeMoveTicker.remove(this.onGazeMoveTick, this);
                this.eyeMoveTicker = null;
            }
        }
    }

    /**
     * @function earthpixi.Access#directMode
     * @param {boolean} enable
     */
    directMode(enable)
    {
        this.directEyeGazeMode = enable;
    }

    onGazeMoveTick(evt)
    {
        if (window.document.visibilityState !== "visible" || !window.document.activeElement)
        {
            // console.log("no focus, reset", window);
            this.reset('gazeMoveTick');

            return;
        }

        if (!this.settings.eyeGazeConfig.dwellClickActive)
        {
            return;
        }

        let pointerPosition;

        if (evt.data)
        {
            pointerPosition = evt.data.global;
        }
        else
        {
            pointerPosition = earthpixi.renderer.plugins.interaction.mouse.global;
        }

        // if not moved skip
        if (
            this._waitingForEyeGazeMove
            && pointerPosition.x == this.currentEyeMovePosition.x
            && pointerPosition.y == this.currentEyeMovePosition.y
        )
        {
            pointerPosition = null;

            return;
        }

        this._waitingForEyeGazeMove = false;
        this.currentEyeMovePosition = pointerPosition.clone();

        const eyeGazeTarget = earthpixi.renderer.plugins.interaction.hitTest(pointerPosition);

        if (eyeGazeTarget)
        {
            // check if hit already active item
            if (this.currentGazeItem && eyeGazeTarget === this.currentGazeItem)
            {
                // hit the active item again so exit
                pointerPosition = null;

                return;
            }

            if (this._resetEyeGazeItems)
            {
                this._resetEyeGazeItems = false;
                eyeGazeTarget.eyeGazeActive = false;
            }
        }
        // else console.log('no eye gaze target found!!!')

        // continue if not hit a target or target is new

        // reset any old target
        if (this.currentGazeItem)
        {
            //
            this.resetGazeItem(this.currentGazeItem);
            this.currentGazeItem = null;
            for (let i = 0; i < this.gazeableButtons.length; i++)
            {
                this.gazeableButtons[i].eyeGazeActive = false;
            }
            earthpixi.Access.removeDirectDwell();
        }

        // exit if no hit found
        if (!eyeGazeTarget){
            // console.log('exiting as no target found');
            this._resetEyeGazeItems = true;

            return;
        }

        // Activate new target
        // if we're in soloButton mode and target not in solo buttons, exit
        if (this.soloButtonArr && this.soloButtonArr.indexOf(eyeGazeTarget) === -1)
        {
            return;
        }

        // if a proper eyegaze item
        if (eyeGazeTarget.eyeGazeAble)
        {
            // console.log('will init');
            this.initGazeItem(eyeGazeTarget);

            return;
        }
        // else {
        //     // console.log('cannot init')
        // }

        // if in direct mode then any button will do
        if (this.directEyeGazeMode && eyeGazeTarget.eyeGazeAble !== false)
        {
            console.log('impossible')
            if (!eyeGazeTarget._events.pointerdown){ return; }

            this.resetGazeItem(eyeGazeTarget);
            this.initGazeItem(eyeGazeTarget, true);
            this.updateDirectDwell(pointerPosition);

            if (this.directDwellAnim)
            {
                earthpixi.stageOverlay.addChild(this.directDwellAnim);
            }
        }
    }

    initGazeItem(item, direct = false)
    {
        if (
            !item.eyeGazeActive
            && item.worldVisible
            && item.interactive
            && item.parent
            && item.parent.interactiveChildren
            && (item._events.pointerdown || item._events.pointerup)
        )
        {
            console.log("init gaze item", item);

            if (!earthpixi.Access.settings.eyeGazeConfig.directMode)
            {
                if (item.onlyUseInDirectMode)
                {
                    return;
                }
            }

            if (this.nowScanning)
            {
                console.log('reset switch scanning')
                this.resetSwitchScanning();
            }

            item.eyeGazeActive = true;
            this.currentGazeItem = item;

            if (item.customDwellAnim)
            {
                item.dwellAnim = new item.customDwellAnim(40);
            }
            else
            {
                item.dwellAnim = new earthpixi.Access.DwellAnimation(40);
            }

            item.dwellAnim.show(item, direct);

            if (item.customDwellTime)
            {
                item.dwellAnim.mainTween.duration(item.customDwellTime);
            }
        }
    }

    trackDirectDwellAnimation(dwellAnim)
    {
        this.removeDirectDwell();
        this.directDwellAnim = dwellAnim;
    }

    updateDirectDwell(pos) { //
        if (this.directDwellAnim && this.directDwellAnim.currentSprite)
        {
            const bounds = this.directDwellAnim.currentSprite.getBounds();
            const localBounds = this.directDwellAnim.currentSprite.getLocalBounds();

            const pos = new PIXI.Point(bounds.x, bounds.y);
            const local = earthpixi.stageOverlay.toLocal(pos);

            // console.log(bounds, localBounds);

            let x = local.x + (localBounds.width / 2) - this.directDwellAnim.offSet;
            let y = local.y + (localBounds.height / 2) - this.directDwellAnim.offSet;

            if (this.directDwellAnim.currentSprite.directModePositionOffset)
            {
                x += this.directDwellAnim.currentSprite.directModePositionOffset.x;
                y += this.directDwellAnim.currentSprite.directModePositionOffset.y;
            }

            this.directDwellAnim.position.set(x, y);
            // this.directDwellAnim.position.set(local.x -this.directDwellAnim.offSet , local.y-this.directDwellAnim.offSet);
        }
    }

    removeDirectDwell()
    {
        if (this.directDwellAnim)
        {
            if (this.directDwellAnim.parent)
            {
                this.directDwellAnim.parent.removeChild(this.directDwellAnim);
            }
            this.directDwellAnim = null;
        }
    }

    eyeGazeItemWasHit(button)
    {
        if (button)
        {
            if (button.dwellAnim)
            {
                button.dwellAnim.hide();
            }
            this.currentGazeItem = null;

            if (!button.eyeGazeAble)
            {
                button.eyeGazeActive = false;
            }

            if (button.repeat && this.settings.eyeGazeConfig.allowRepeat)
            {
                button.eyeGazeActive = false;
                this.currentGazeItem = null;

                if (!this.settings.eyeGazeConfig.directMode)
                {
                    this.initGazeItem(button);
                }
                else
                {
                    this._waitingForEyeGazeMove = true;
                }
            }
            else
            {
                this._waitingForEyeGazeMove = true;
            }
        }
    }

    resetGazeItem(item)
    {
        if (item && item._texture)
        {
            item.eyeGazeActive = false;

            if (item.onPointerOut)
            {
                item.onPointerOut();
            }

            if (item.dwellAnim)
            {
                item.dwellAnim.hide();
            }
        }
    }

    /**
     *  @function earthpixi.Access#switchAccess
     * @param {boolean} enable
     */
    switchAccess(enable)
    {
        this.reset('switchAccess');

        this.switchRowSelector.init();

        if (enable === undefined)
        {
            enable = this.settings.switchActive;
        }

        if (enable)
        {
            // this.switchRowSelector.init();

            const body = document.querySelector("body");

            // check in case called twice, disable key event first
            if (this.onKeyDownBind)
            {
                body.removeEventListener("keydown", this.onKeyDownBind);
                body.removeEventListener("keyup", this.onKeyUpBind);
            }

            this.settings.switchActive = true;
            body.addEventListener("keydown", this.onKeyDownBind);
            body.addEventListener("keyup", this.onKeyUpBind);
            body.focus();

            earthpixi.Keyboard.addKey(37, this.arrowKey, null, null, null, this);
            earthpixi.Keyboard.addKey(38, this.arrowKey, null, null, null, this);
            earthpixi.Keyboard.addKey(39, this.arrowKey, null, null, null, this);
            earthpixi.Keyboard.addKey(40, this.arrowKey, null, null, null, this);
        }
        else
        {
            // console.log("disable switch");
            if (this.settings.switchActive)
            {
                this.settings.switchActive = false;
                const body = document.querySelector("body");

                body.removeEventListener("keydown", this.onKeyDownBind);
                body.removeEventListener("keyup", this.onKeyUpBind);
                body.focus();
                window.focus = earthpixi.renderer.view;

                earthpixi.Keyboard.removeKey(37);
                earthpixi.Keyboard.removeKey(38);
                earthpixi.Keyboard.removeKey(39);
                earthpixi.Keyboard.removeKey(40);

                // earthpixi.Keyboard.removeKey(87);
                // earthpixi.Keyboard.removeKey(83);
                // earthpixi.Keyboard.removeKey(68);
                // earthpixi.Keyboard.removeKey(65);
            }
        }
    }

    keyboardAccess(enable){
        console.log('EPIXI:: evaluating keyboard Access. enable is ', enable, ' config is: ', this.settings.keyboardConfig.enableKeyboard);

        this.reset('keyboardAccess');

        let controller = this.settings.keyboardConfig;
        console.log('EPIXI:: controller is: ', controller)
        // enable = enable === undefined ? 
        //     controller && controller.enableKeyboard
        //     : false;
        
        if(enable || controller.enableKeyboard){
            console.log('EPIXI:: has keyboard access enabled')
            this.checkKeyEventListeners();
            // map key settings to actions
            
            // controller.selectMapping
            let selectMapping = KeyMap[controller.selectMapping]?.keyCode || controller.selectMapping;
            console.log('select key is: ', selectMapping)
            selectMapping && earthpixi.Keyboard.addKey(selectMapping, this.scanSelect, null, null, null, this);
            
            // controller.scanMapping
            let scanMapping = KeyMap[controller.scanMapping]?.keyCode || controller.scanMapping;
            console.log('scan key is: ', scanMapping)
            scanMapping && earthpixi.Keyboard.addKey(scanMapping, this.initScanning, null, null, null, this);

            this.addKeyboardArrowMapping();
            // scan action should trigger init scan
            // select action should trigger select
        }
        else {
            console.log('EPIXI:: will remove keyboard access')
            this.removeKeyboardMapping('keyboardAccess');
        }
    }

    removeKeyboardMapping(caller){
        console.log('EPIXI:: removing keyboard mapping for caller: ', caller)
        this.hardReset('keyboardAccess');
    }
    
    addKeyboardArrowMapping(){
        console.log('EPIXI:: adding keyboard arrow mapping')
        earthpixi.Keyboard.addKey(37, this.arrowKey, null, null, null, this);
        earthpixi.Keyboard.addKey(38, this.arrowKey, null, null, null, this);
        earthpixi.Keyboard.addKey(39, this.arrowKey, null, null, null, this);
        earthpixi.Keyboard.addKey(40, this.arrowKey, null, null, null, this);
    }

    removeKeyEventListeners(caller){
        console.log('EPIXI:: removing key event listeners for caller: ', caller)
        const body = document.querySelector("body");
        // body.removeEventListener("keydown", this.onKeyDownBind);
        // body.removeEventListener("keyup", this.onKeyUpBind);    
    }

    checkKeyEventListeners(){
        const body = document.querySelector("body");

        // check in case called twice, disable key event first
        if (this.onKeyDownBind){
            this.removeKeyEventListeners('checkKeyEventListeners');
        }

        body.addEventListener("keydown", this.onKeyDownBind);
        body.addEventListener("keyup", this.onKeyUpBind);
        body.focus();
    }

    onSwitchKeyDown(evt)
    {
        console.log('EPIXI:: onSwitchKeyDown: ', evt.keyCode, ' switchActive: ', this.settings.switchActive)
        if(!this.settings.switchActive){}

        const switch1Down = evt.keyCode === earthpixi.Access.settings.switchConfig.switch1KeyCode;
        const switch2Down
            = evt.keyCode === earthpixi.Access.settings.switchConfig.switch2KeyCode
            || [65, 66, 88, 89].includes(evt.keyCode);

        if (
            (this.switchTimeOutId || this._block)
            || !(switch1Down || switch2Down)
        )
        {
            return;
        }

        if (switch1Down)
        {
            this.switch1Down(evt);
        }

        if (switch2Down)
        {
            this.switch2Down(evt);
        }

        const body = document.querySelector("body");

        this.switchTimeOutId = setTimeout(this.switchDebounceBind, this.settings.switchConfig.debounceTimeout);

        body.focus();
    }

    onSwitchKeyUp(evt)
    {
        if (this._block)
        {
            return;
        }

        const switch1Down = evt.keyCode === earthpixi.Access.settings.switchConfig.switch1KeyCode;
        const switch2Down
            = evt.keyCode === earthpixi.Access.settings.switchConfig.switch2KeyCode
            || [65, 66, 88, 89].includes(evt.keyCode);

        if (switch1Down)
        {
            this.switch1Up();
        }

        if (switch2Down)
        {
            this.switch2Up();
        }

        const body = document.querySelector("body");

        body.focus();
    }

    switchCancelDebounce()
    {
        this.switchTimeOutId = null;
    }

    switchAutoKeyUp()
    {
        this.switch1IsDown = false;
        this.switchAutoKeyUpId = null;

        if (this.switchTimeOutId)
        {
            clearTimeout(this.switchTimeOutId);
            this.switchTimeOutId = null;
        }
    }

    arrowKey(evt)
    {
        //  console.log(evt);

        if (this.switchTimeOutId || this._block || !(this.settings.switchActive || this.settings.keyboardConfig.enableKeyboard))
        {
            console.log('EPIXI: arrowKey() ', 'switchTimeOutId: ', this.switchTimeOutId, ' _block: ', this._block, ' switchActive: ', this.settings.switchActive, ' keyboardConfig: ', this.settings.keyboardConfig.enableKeyboard)
            return;
        }

        let dirH = 0;
        let dirV = 0;

        switch (evt.keyCode)
        {
            case 39:
                dirH = 1;
                break;
            case 37:
                dirH = -1;
                break;
            case 38:
                dirV = -1;
                break;
            case 40:
                dirV = 1;
                break;

            case 68:
                dirH = 1;
                break;
            case 65:
                dirH = -1;
                break;
            case 87:
                dirV = -1;
                break;
            case 83:
                dirV = 1;
                break;
        }

        // cancel switch 1 scanning if on
        if (this.scanInterval)
        {
            clearInterval(this.scanInterval);
        }

        this.switchRowSelector.switch1Down(dirH, dirV, true);
    }

    onGamePadButton(button)
    {
        if (this._block)
        {
            return;
        }

        console.log("Access gamepad button", button);

        let move = false;
        let select = false;
        let menu = false;
        let dirH = 0;
        let dirV = 0;

        switch (button)
        {
            case earthpixi.GamePad.BUTTONS.D_L:
                dirH = -1;
                move = true;
                break;

            case earthpixi.GamePad.BUTTONS.D_R:
                dirH = 1;
                move = true;
                break;

            case earthpixi.GamePad.BUTTONS.D_UP:
                dirV = -1;
                move = true;
                break;

            case earthpixi.GamePad.BUTTONS.D_DOWN:
                dirV = 1;
                move = true;
                break;

            case earthpixi.GamePad.BUTTONS.X:
            case earthpixi.GamePad.BUTTONS.A:
            case earthpixi.GamePad.BUTTONS.SELECT:
                select = true;
                break;
            case earthpixi.GamePad.BUTTONS.HOME:
            case earthpixi.GamePad.BUTTONS.START:
                menu = true;
        }

        // cancel switch 1 scanning if on
        if (this.scanInterval)
        {
            clearInterval(this.scanInterval);
        }

        if (move)
        {
            console.log("here");
            this.switchRowSelector.switch1Down(dirH, dirV, true);
        }
        else if (select)
        {
            this.switchRowSelector.switch2Down({ gamepad: true });
        }
        else if (menu)
        {
            this.emit("menu");
        }
    }

    switch1Down(evt)
    {
        if (this._block)
        {
            return;
        }

        if (!this.switch1IsDown)
        {
            this.switch1IsDown = true;

            if (earthpixi.Access.settings.switchConfig.switchMode === 2)
            {
                this.switchAutoKeyUpId = setTimeout(this.switchAutoKeyUpBind, this.settings.switchConfig.switchAutoKeyTimeout);
            }

            if (!this.nowScanning)
            {
                // console.log("init scanning");
                this.initScanning();
            }
            else if (earthpixi.Access.settings.switchConfig.switchMode === 2)
            {
                this.scanNext(false, evt);
            }
            else
            {
                this.scanSelect(evt);
            }
        }
    }

    switch2Down(evt)
    {
        // console.log(evt);

        // if (earthpixi.Access.settings.switchConfig.switchMode == 1) {
        //   this.switchRowSelector.switch1Down();
        //   return;
        // }

        // //if(this.settings.switchConfig.rowSelectorMode){
        this.switchRowSelector.switch2Down(evt);
        // return;
        // }

        //
        // if (!this.switch2IsDown && earthpixi.Access.settings.switchConfig.switchMode == 2) {
        //   this.switch2IsDown = true;
        //
        //   if (this.nowScanning) {
        //     this.scanSelect(evt);
        //   }
        // }
    }

    switch1Up()
    {
        this.switch1IsDown = false;
    }

    switch2Up()
    {
        this.switch2IsDown = false;
    }

    initScanning()
    {
        this.nowScanning = true;

        if (this.currentSwitchIndex > 0)
        {
            this.currentSwitchIndex--;
        }

        // console.log(this.currentSwitchIndex);

        this.scanNext(true);

        if (earthpixi.Access.settings.switchConfig.switchMode == 1)
        {
            this.initScanTimer();
        }
    }

    sortButtonsOnPosition(a, b)
    {
        const aB = a.getBounds();
        const bB = b.getBounds();
        const aCompY = aB.y + aB.height;
        const bCompY = bB.y + aB.height;

        if (aCompY == bCompY) return aB.x - bB.x;

        return aCompY - bCompY;
        // return aB.x - bB.x;
    }

    // sort buttons by tabIndex unless, if same of no tab index sort on position left to right up to down
    sortButtonsOnPositionWithTabIndex(a, b)
    {
        const aB = a.getBounds();
        const bB = b.getBounds();
        const aCompY = aB.y + aB.height;
        const bCompY = bB.y + aB.height;

        if (a.tabIndex === b.tabIndex)
        {
            if (aCompY === bCompY)
            {
                return aB.x - bB.x;
            }

            return aCompY - bCompY;
        }

        return a.tabIndex - b.tabIndex;
    }

    initScanTimer()
    {
        if (this.scanInterval)
        {
            clearInterval(this.scanInterval);
        }
        this.scanInterval = setInterval(this.onScanTimer.bind(this), earthpixi.Access.settings.switchConfig.scanTime * 1000);
    }

    onScanTimer()
    {
        this.scanNext();
    }

    getAvailableSwitchButtons()
    {
        const availButtons = [];
        let i;

        // console.log("get buttons", this.soloButtonArr);

        for (i = 0; i < this.switchableButtons.length; i++)
        {
            if (
                earthpixi.Utils.onStage(this.switchableButtons[i])
                && this.switchableButtons[i].worldVisible
                && this.switchableButtons[i].interactive
                && this.switchableButtons[i].switchable
                && this.switchableButtons[i].parent.interactiveChildren
            )
            {
                if (this.soloButtonArr)
                {
                    if (this.soloButtonArr.indexOf(this.switchableButtons[i]) !== -1)
                    {
                        availButtons.push(this.switchableButtons[i]);
                    }
                }
                else
                {
                    availButtons.push(this.switchableButtons[i]);
                }
            }
        }

        // console.log(availButtons.length);

        if (this.settings.switchConfig.directMode && !this.soloButtonArr)
        {
            const additionalButtons = this.getInteractiveChilden(earthpixi.stage);

            for (i = 0; i < additionalButtons.length; i++)
            {
                if (!availButtons.includes(additionalButtons[i]))
                {
                    if (additionalButtons[i].tabIndex === undefined)
                    {
                        additionalButtons[i].tabIndex = -1;
                    }
                    availButtons.push(additionalButtons[i]);
                }
            }
        }

        availButtons.sort(this.sortButtonsOnPosition);

        return availButtons;
    }

    // go through all children
    getInteractiveChilden(container)
    {
        let interactiveChildren = [];

        for (let i = 0; i < container.children.length; i++)
        {
            const child = container.children[i];

            // current child
            if (
                child.interactive
                && child.worldVisible
                && (child.switchable || child.switchable === undefined)
                && child.parent
                && !(child instanceof Button)
            )
            {
                interactiveChildren.push(child);
            }

            // check current child for more children
            if (
                child.worldVisible
                && child.children
                && child.interactiveChildren
                && child.children.length > 0
            )
            {
                const moreChildren = this.getInteractiveChilden(child);

                if (moreChildren.length > 0)
                {
                    interactiveChildren = interactiveChildren.concat(moreChildren);
                }
            }
        }

        return interactiveChildren;
    }

    scanNext(init = false, evt)
    {
        // if(this.settings.switchConfig.rowSelectorMode){
        this.switchRowSelector.switch1Down();
        // return;
        // }

        /*
    //clean up previous selection
    if (this.currentSwitchButton) {
      this.currentSwitchButton.filters = null;
      let interactionManager = earthpixi.renderer.plugins.interaction;
      if (interactionManager.eventData.data) {
        interactionManager.eventData.data.currentTarget = this.currentSwitchButton;
        interactionManager.dispatchEvent(this.currentSwitchButton, 'pointerout', interactionManager.eventData);
      }
    }

    //find the next button
    let availButtons = this.getAvailableSwitchButtons();

    if (availButtons.length == 0) {
      return;
    }

    //console.log(availButtons);
    //availButtons.sort(this.sortButtonsOnPosition);

    if(this.currentSwitchIndex >= availButtons.length-1){
      this.currentSwitchIndex = 0;
    }else{
      if(!init){
        //console.log("inc");
        this.currentSwitchIndex += 1;
      }
    }
    //this.currentSwitchIndex = this.currentSwitchIndex > availButtons.length - 2 ? 0 : this.currentSwitchIndex +1;

    //console.log(this.currentSwitchIndex);

    this.currentSwitchButton = availButtons[this.currentSwitchIndex];
    this.currentSwitchButton.filters = [(this.currentSwitchButton.customFilter || this.scanGlowFilter)];

    let interactionManager = earthpixi.renderer.plugins.interaction;
    if (interactionManager.eventData.data) {
      interactionManager.eventData.data.currentTarget = this.currentSwitchButton;
      interactionManager.dispatchEvent(this.currentSwitchButton, 'pointerover', interactionManager.eventData);
    }
    */
    }

    scanSelect(evt)
    {
        this.switchRowSelector.switch2Down(evt);
    }

    resetButtonLists()
    {
        this.gazeableButtons = [];
        this.switchableButtons = [];

        for (let i = 0; i < this.allButtons.length; i++)
        {
            const accessibleButton = this.allButtons[i];

            if (accessibleButton.eyeGazeAble && this.gazeableButtons.indexOf(accessibleButton) === -1)
            {
                this.gazeableButtons.push(accessibleButton);
            }

            if (accessibleButton.switchable && this.switchableButtons.indexOf(accessibleButton) === -1)
            {
                this.switchableButtons.push(accessibleButton);

                this.switchableButtons.sort((a, b) =>
                    (a.tabIndex === b.tabIndex ? 0 : Number(a.tabIndex > b.tabIndex) || -1));
            }
        }
    }

    reset(caller)
    {
        // console.warn("reset");

        // try and focus
        console.log(`EPIXI: reset(${caller})`)
        earthpixi.renderer.view.focus();

        this._block = false;

        for (let i = 0; i < this.gazeableButtons.length; i++)
        {
            this.resetGazeItem(this.gazeableButtons[i]);
            // console.log("here");
        }

        if (this.soloButtonArr)
        {
            for (let i = 0; i < this.soloButtonArr.length; i++)
            {
                this.resetGazeItem(this.soloButtonArr[i]);
            }
        }

        this.resetSwitchScanning();

        this.resetButtonLists();
    }

    resetSwitchScanning()
    {
        console.log("EPIXI:: resetSwitchScanning");
        this.nowScanning = false;

        if (this.switchRowSelector._init)
        {
            this.switchRowSelector.reset();
        }

        if (this.scanInterval)
        {
            clearInterval(this.scanInterval);
            this.scanInterval = null;
        }

        if (this.currentSwitchButton)
        {
            this.currentSwitchButton.filters = null;
            const interactionManager = earthpixi.renderer.plugins.interaction;

            if (interactionManager.eventData.data)
            {
                interactionManager.eventData.data.currentTarget = this.currentSwitchButton;
            }
            interactionManager.dispatchEvent(this.currentSwitchButton, "pointerout", interactionManager.eventData);
        }
        this.currentSwitchButton = null;
        this.currentSwitchIndex = 0;
        this.switch1IsDown = false;
        this.switch2IsDown = false;
    }

    hardReset(caller)
    {
        console.log(`EPIXI: hardReset(${caller})`)

        this.reset('hardReset');
        const body = document.querySelector("body");
        this.removeKeyEventListeners('hardReset')

        // body.removeEventListener("keydown", this.onKeyDownBind);
        // body.removeEventListener("keyup", this.onKeyUpBind);

        earthpixi.Keyboard.reset('hardReset');

        // earthpixi.GamePad.off(earthpixi.GamePad.EVENTS.BUTTON_DOWN, this.onGamePadButton, this);

        if (this.eyeMoveTicker)
        {
            this.eyeMoveTicker.stop();
            this.eyeMoveTicker.remove(this.onGazeMoveTick, this);
        }

        this.switchableButtons = [];
        this.gazeableButtons = [];
        this.allButtons = [];
        this.soloButtonArr = null;
        this.directEyeGazeMode = false;
    }
}

global.PIXI.accessibility.AccessibilityManager.prototype.update = function update()
{
    if (!this.renderer.renderingToScreen)
    {
        return;
    }

    // update children...
    this.updateAccessibleObjects(this.renderer._lastObjectRendered);

    const rect = this.renderer.view.getBoundingClientRect();
    const sx = rect.width / (this.renderer.width / this.renderer.resolution);
    const sy = rect.height / (this.renderer.height / this.renderer.resolution);

    let div = this.div;

    div.style.left = `${rect.left}px`;
    div.style.top = `${rect.top}px`;
    div.style.width = `${this.renderer.width}px`;
    div.style.height = `${this.renderer.height}px`;

    for (let i = 0; i < this.children.length; i++)
    {
        const child = this.children[i];

        if (child.renderId !== this.renderId)
        {
            child._accessibleActive = false;

            global.PIXI.utils.removeItems(this.children, i, 1);
            this.div.removeChild(child._accessibleDiv);
            this.pool.push(child._accessibleDiv);
            child._accessibleDiv = null;

            i--;

            if (this.children.length === 0)
            {
                this.deactivate();
            }
        }
        else
        {
            // map div to display..
            div = child._accessibleDiv;
            let hitArea = child.hitArea;
            const wt = child.worldTransform;

            if (child.hitArea)
            {
                div.style.left = `${(wt.tx + hitArea.x * wt.a) * sx}px`;
                div.style.top = `${(wt.ty + hitArea.y * wt.d) * sy}px`;

                div.style.width = `${hitArea.width * wt.a * sx}px`;
                div.style.height = `${hitArea.height * wt.d * sy}px`;
            }
            else
            {
                hitArea = child.getBounds();

                this.capHitArea(hitArea);

                div.style.left = `${hitArea.x * sx}px`;
                div.style.top = `${hitArea.y * sy}px`;

                div.style.width = `${hitArea.width * sx}px`;
                div.style.height = `${hitArea.height * sy}px`;
            }
        }
    }

    // increment the render id..
    this.renderId++;
};

global.PIXI.accessibility.AccessibilityManager.prototype.addChild = function addChild(displayObject)
{
    //    this.activate();

    let div = this.pool.pop();

    if (!div)
    {
        div = document.createElement("button");

        div.style.width = `${100}px`;
        div.style.height = `${100}px`;
        div.style.backgroundColor = this.debug ? "rgba(255,0,0,0.5)" : "transparent";
        div.style.position = "absolute";
        div.style.zIndex = 2;
        div.style.borderStyle = "none";

        div.addEventListener("click", this._onClick.bind(this));
        div.addEventListener("focus", this._onFocus.bind(this));
        div.addEventListener("focusout", this._onFocusOut.bind(this));
    }

    if (displayObject.accessibleTitle)
    {
        div.title = displayObject.accessibleTitle;
    }
    else if (!displayObject.accessibleTitle && !displayObject.accessibleHint)
    {
        div.title = `displayObject ${this.tabIndex}`;
    }

    if (displayObject.accessibleHint)
    {
        div.setAttribute("aria-label", displayObject.accessibleHint);
    }

    displayObject._accessibleActive = true;
    displayObject._accessibleDiv = div;
    div.displayObject = displayObject;

    this.children.push(displayObject);
    this.div.appendChild(displayObject._accessibleDiv);
    displayObject._accessibleDiv.tabIndex = displayObject.tabIndex;
};

global.PIXI.accessibility.AccessibilityManager.prototype.updateAccessibleObjects = function updateAccessibleObjects(displayObject)
{
    if (!displayObject.visible)
    {
        return;
    }

    if (displayObject.accessible && displayObject.interactive && displayObject.parent && displayObject.parent.interactiveChildren)
    {
        if (!displayObject._accessibleActive)
        {
            this.addChild(displayObject);
        }

        displayObject.renderId = this.renderId;
    }

    const children = displayObject.children;

    // tab through items in order they were added

    // for (var i = children.length - 1; i >= 0; i--) {
    for (let i = 0; i < children.length; i++)
    {
        this.updateAccessibleObjects(children[i]);
    }
};

global.PIXI.accessibility.AccessibilityManager.prototype._onClick = function _onClick(e)
{
    const interactionManager = this.renderer.plugins.interaction;

    interactionManager.eventData.data.currentTarget = e.target.displayObject;
    interactionManager.dispatchEvent(e.target.displayObject, "pointerup", interactionManager.eventData);
};

global.PIXI.accessibility.AccessibilityManager.prototype._onFocus = function _onFocus(e)
{
    const interactionManager = this.renderer.plugins.interaction;

    interactionManager.eventData.data.currentTarget = e.target.displayObject;
    interactionManager.dispatchEvent(e.target.displayObject, "pointerover", interactionManager.eventData);
};

global.PIXI.accessibility.AccessibilityManager.prototype._onFocusOut = function _onFocusOut(e)
{
    const interactionManager = this.renderer.plugins.interaction;

    interactionManager.eventData.data.currentTarget = e.target.displayObject;
    interactionManager.dispatchEvent(e.target.displayObject, "pointerout", interactionManager.eventData);
};
