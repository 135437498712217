/**
 *
 * Tools for drawing shapes with the Canvas 2d Context (able to get smooth rounded graphics, and gradients)
 *
 * Each creates a texture a returns a PIXI.Sprite, so re-use the Sprite.texture if need to create more of the same thing.
 *
 * Draw a Rectangle with gradient fill using canvas 2d context (smoother graphics), use hex colours
 * ```js
 *
 * let mySpr = earthpixi.utils.Draw.DrawRectGradient(
 *  {x:0,y:0,width:200, height:200},
 *  [0xFF00FF,"#FFEE00","red"]
 * )
 * ```
 *
 * @namespace earthpixi.utils.Draw
 */

export default {

    /**
   *
   * Used by 2d drawing context
   *
   * @private
   */
    DrawCanvas(cb)
    {
        const canvas = document.createElement("canvas");
        const res = earthpixi.resolution;

        if (typeof cb === "function") cb(canvas);
        const tex = PIXI.Texture.fromCanvas(canvas, 0);

        tex.baseTexture.resolution = earthpixi.resolution;
        tex.baseTexture.update();

        return tex;
    },

    /**
   * @memberof earthpixi.utils.Draw
   * @static
   * @param {PIXI.Rectangle | object} rect
   * @param {string|number} colour - html hex colour string "#FF0000" / 0xFFFFFFF /"green"
   * @returns {PIXI.Sprite}
   */
    DrawRect(rect, colour)
    {
        const tex = earthpixi.utils.Draw.DrawCanvas(function (canvas)
        {
            // we are now in a 2D context
            canvas.width = (rect.width * earthpixi.resolution) + (2 * earthpixi.resolution); // you need to specify your canvas width and height otherwise it'll have a size of 0x0 and you'll get an empty sprite
            canvas.height = (rect.height * earthpixi.resolution) + (2 * earthpixi.resolution);

            // document.body.appendChild(canvas);

            const context = canvas.getContext("2d"); // get  canvas 2D context

            context.imageSmoothingEnabled = true;
            context.fillStyle = (typeof colour === "string") ? colour : PIXI.utils.hex2string(colour);

            const x = earthpixi.resolution;
            const y = earthpixi.resolution;
            const width = rect.width * earthpixi.resolution;
            const height = rect.height * earthpixi.resolution;

            const safeGap = earthpixi.resolution;

            context.rect(safeGap, safeGap, (rect.width * earthpixi.resolution), (rect.height * earthpixi.resolution));
            context.fill();
        });

        const sprite = new PIXI.Sprite(tex);

        return sprite;
    },

    /**
   Draw a Rectangle with gradient fill using canvas 2d context (smoother graphics)
   * ```js
   *
   * let mySpr = earthpixi.utils.Draw.DrawRectGradient(
   *  {x:0,y:0,width:200, height:200},
   *  [0xFF00FF,"#FFEE00","red"]
   * )
   * ```
   * @static
   * @memberof earthpixi.utils.Draw
   * @param {PIXI.Rectangle | object} rect
   * @param {array} gradientPoints - array of hex colours or strings "#FF0000" / 0xFFFFFFF /"green"
   * @returns {PIXI.Sprite}
   */
    DrawRectGradient(rect, gradientPoints)
    {
        const tex = earthpixi.utils.Draw.DrawCanvas(function (canvas)
        {
            // we are now in a 2D context
            canvas.width = (rect.width * earthpixi.resolution) + (2 * earthpixi.resolution); // you need to specify your canvas width and height otherwise it'll have a size of 0x0 and you'll get an empty sprite
            canvas.height = (rect.height * earthpixi.resolution) + (2 * earthpixi.resolution);

            // document.body.appendChild(canvas);

            const context = canvas.getContext("2d"); // get  canvas 2D context

            context.imageSmoothingEnabled = true;

            const grd = context.createLinearGradient(0, 0, 0, canvas.height);

            for (let i = 0; i < gradientPoints.length; i++)
            {
                let colour = gradientPoints[i];

                colour = (typeof colour === "string") ? colour : PIXI.utils.hex2string(colour);
                grd.addColorStop(i * (1 / (gradientPoints.length - 1)), colour);
            }

            context.fillStyle = grd;

            const x = earthpixi.resolution;
            const y = earthpixi.resolution;
            const width = rect.width * earthpixi.resolution;
            const height = rect.height * earthpixi.resolution;

            const safeGap = earthpixi.resolution;

            context.rect(safeGap, safeGap, (rect.width * earthpixi.resolution), (rect.height * earthpixi.resolution));
            context.fill();
        });

        const sprite = new PIXI.Sprite(tex);

        return sprite;
    },

    /**
   * Draw a Rectangle with Rounds corners using canvas, use hex colour
   *
   * ```js
   *
   * let mySpr = earthpixi.utils.Draw.DrawRoundedRect(
   *  {x:0,y:0,width:200, height:200},
   *  20,
   *  "#FF00FF"
   * )
   * ```
   * @static
   * @memberof earthpixi.utils.Draw
   * @param {PIXI.Rectangle | object} rect
   * @param {number} rad - corner radius
   * @param {string|number} colour - hex colour or string "#FF0000" / 0xFFFFFFF /"green"
   * @param {string|null} [strokeColor]
   * @param {string|null} [strokeWidth]
   *
   * @returns {PIXI.Sprite}
   */
    DrawRoundedRect(rect, rad, colour, strokeColor = null, strokeWidth = null)
    {
        const tex = earthpixi.utils.Draw.DrawCanvas(function (canvas)
        {
            // we are now in a 2D context
            // you need to specify your canvas width and height otherwise it'll have a size of 0x0 and you'll get an empty sprite
            canvas.width = (rect.width * earthpixi.resolution)
                + (2 * earthpixi.resolution); canvas.height
                = (rect.height * earthpixi.resolution) + (2 * earthpixi.resolution);

            const context = canvas.getContext("2d"); // get  canvas 2D context

            context.imageSmoothingEnabled = true;
            context.fillStyle = (typeof colour === "string") ? colour : PIXI.utils.hex2string(colour);

            const x = earthpixi.resolution;
            const y = earthpixi.resolution;
            const width = rect.width * earthpixi.resolution;
            const height = rect.height * earthpixi.resolution;
            const radius = rad * earthpixi.resolution;

            context.beginPath();
            if (strokeColor)
            {
                context.lineWidth = strokeWidth * earthpixi.resolution;
                context.lineJoin = "round";
            }
            context.moveTo(x + radius, y);
            context.lineTo(x + width - radius, y);
            context.quadraticCurveTo(x + width, y, x + width, y + radius);
            context.lineTo(x + width, y + height - radius);
            context.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
            context.lineTo(x + radius, y + height);
            context.quadraticCurveTo(x, y + height, x, y + height - radius);
            context.lineTo(x, y + radius);
            context.quadraticCurveTo(x, y, x + radius, y);
            context.closePath();
            context.fill();

            if (strokeWidth && strokeColor)
            {
                context.strokeStyle = (typeof strokeColor === "string") ? strokeColor : PIXI.utils.hex2string(strokeColor);
                context.stroke();
            }
        });

        const sprite = new PIXI.Sprite(tex);

        return sprite;
    },

    /** Draw a circle using canvas
   *
   *  ```js
   *
   * let mySpr = earthpixi.utils.Draw.DrawCircle(
   *  50,
   *  "#FF00FF"
   * )
   * ```
   * @static
   * @memberof earthpixi.utils.Draw
   * @param rad
   * @param {string|number} colour - hex colour 0xFFF or string "#FF0000" / 0xFFFFFFF /"green"
   * @returns {PIXI.Sprite}
   */

    DrawCircle(rad, colour)
    {
        const tex = earthpixi.utils.Draw.DrawCanvas(function (canvas)
        {
            // we are now in a 2D context
            canvas.width = (rad + 2) * 2 * earthpixi.resolution; // you need to specify your canvas width and height otherwise it'll have a size of 0x0 and you'll get an empty sprite
            canvas.height = (rad + 2) * 2 * earthpixi.resolution;

            const context = canvas.getContext("2d"); // get  canvas 2D context
            // context.imageSmoothingEnabled = true;

            context.fillStyle = (typeof colour === "string") ? colour : PIXI.utils.hex2string(colour);
            context.arc((rad + 1) * earthpixi.resolution, (rad + 1) * earthpixi.resolution + 1, rad * earthpixi.resolution, 0, 2 * Math.PI);
            context.fill();
        });

        const sprite = new PIXI.Sprite(tex);

        return sprite;
    }

};
