export default {

    screenFadeComplete()
    {
        if (earthpixi.screenTween) earthpixi.screenTween.kill();
        earthpixi.screenTween = null;

        if (earthpixi.currentScreenGrab)
        {
            earthpixi.stage.removeChild(earthpixi.currentScreenGrab);
            earthpixi.currentScreenGrab.texture.requiresUpdate = false;
            earthpixi.currentScreenGrab.texture.destroy(true);
            earthpixi.currentScreenGrab.destroy();
        }
        earthpixi.currentScreenGrab = null;
        if (earthpixi.audioAwake) earthpixi.currentScreen.emit("audiounlock");
        earthpixi.currentScreen.run();
        earthpixi.currentScreen.emit("run");
    },

};
