import EyeGazeConfig from "./EyeGazeConfig";
import SwitchConfig from "./SwitchConfig";
import KeyboardConfig from "./KeyboardConfig";

/**
 * @class
 * @memberof earthpixi.Access
 */
export default class AccessibilitySettings {
   /**
    *
    */
   constructor() {
      /**
       *
       * @type {boolean}
       */
      this.switchActive = false;
      /**
       *
       * @type {boolean}
       */
      this.eyeGazeActive = false;
      /**
       *
       * @type {boolean}
       */
      this.clickAnim = true;
      /**
       *
       * @type {{}}
       */
      this.mouseConfig = {};
      /**
       *
       * @type {{}}
       */
      this.touchConfig = {};

      /**
       *
       * @type {earthpixi.Access.EyeGazeConfig}
       */
      this.eyeGazeConfig = new EyeGazeConfig();
      /**
       *
       * @type {earthpixi.Access.SwitchConfig}
       */
      this.switchConfig = new SwitchConfig();
      
      // adding keyboard config

      this.keyboardConfig = new KeyboardConfig();
   }

   /**
    *
    * @param settings
    */
   copy(newSettings) {
      if (newSettings) {
         console.log('AccessibilitySettings.js -> copying settings: ', newSettings)

         let settings
         if (newSettings.hasOwnProperty('settings')) {
            settings = newSettings.settings
         } else {
            settings = newSettings
         }

         if (settings.switchActive !== undefined) {
            this.switchActive = settings.switchActive;
         }

         if (settings.eyeGazeActive !== undefined) {
            this.eyeGazeActive = settings.eyeGazeActive;
         }

         if (settings.clickAnim !== undefined) {
            this.clickAnim = settings.clickAnim;
         }

         if (settings.mouseConfig !== undefined) {
            this.mouseConfig = settings.mouseConfig;
         }

         if (settings.touchConfig !== undefined) {
            this.touchConfig = settings.touchConfig;
         }

         this.eyeGazeConfig.copy(settings.eyeGazeConfig);
         this.switchConfig.copy(settings.switchConfig);
         
         settings.keyboardConfig && this.keyboardConfig.copy(settings.keyboardConfig);
      } else {
         console.log('AccessibilitySettings.js: no settings object passed: ', newSettings);
         console.trace();
      }
   }

   /**
    *
    * @return {object}
    */
   toJSON() {
      return {
         switchActive: this.switchActive,
         eyeGazeActive: this.eyeGazeActive,
         clickAnim: this.clickAnim,
         mouseConfig: this.mouseConfig,
         touchConfig: this.touchConfig,
         eyeGazeConfig: this.eyeGazeConfig.toJSON(),
         switchConfig: this.switchConfig.toJSON(),
         keyboardConfig: this.keyboardConfig.toJSON()
      };
   }
}