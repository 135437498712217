import Modernizr from "../../libs/modernizr";
// import mini_svg_uri from "mini-svg-data-uri";
import ismobilejs from "ismobilejs";
const isMobile = ismobilejs(window.navigator);
/**
 * @static
 * @namespace earthpixi.utils.Display
 *
 *
 */

export default {

    /**
   * Sort Array of display objects by y position
   *
   * ```js
   *  earthpixi.utils.Display.sortOnY(earthpixi.currentScreen.children);
   * ```
   *
   * @static
   * @memberof earthpixi.utils.Display
   * @param {array} arrayOfDisplayObjects to sort
   */
    sortOnY(arrayOfDisplayObjects)
    {
    // sort children by y
        arrayOfDisplayObjects.sort(function (a, b)
        {
            return a.y - b.y;
        });

        return arrayOfDisplayObjects;
    },

    /**
   * Turn on or off mipmaps on all currently loaded resouces (might not be needed on more recent versions of PIXI)
   *
   * @memberof earthpixi.utils.Display
   * @param {bool} enable
   */
    setMipMaps(enable)
    {
        for (const key in earthpixi.resources)
        {
            if (!earthpixi.resources.hasOwnProperty(key)) continue;
            const resource = earthpixi.resources[key];

            if (resource.texture != null)
            {
                resource.texture.baseTexture.mipmap = enable;
            }
        }
    },

    /**
   * For externally loaded images, when texture is loaded you can re-create as PO2 and set mipmaps (nessy api images)
   * @memberof earthpixi.utils.Display
   * @param {PIXI.Sprite} sprite
     * @returns {PIXI.Sprite}
     *
   */
    hackMipmaps(sprite)
    {
        if (!earthpixi.renderer instanceof PIXI.WebGLRenderer)
        { return sprite; }

        const w = sprite.width;
        const h = sprite.height;
        const b = sprite.height > sprite.width ? sprite.height : sprite.width;

        if (!b)
        { return sprite; }

        let po2 = 1;

        while (po2 < b)
        {
            po2 *= 2;
        }

        const baseRenderTex = new PIXI.BaseRenderTexture(po2, po2, PIXI.SCALE_MODES.LINEAR, earthpixi.resolution);

        baseRenderTex.mipmap = true;
        const renderTex = new PIXI.RenderTexture(baseRenderTex);

        earthpixi.renderer.render(sprite, renderTex);

        if (isMobile.android.device)
        {
            // Samsung tab A garbled image unless we render twice??  TODO remove in v5
            const baseRenderTex2 = new PIXI.BaseRenderTexture(po2, po2, PIXI.SCALE_MODES.LINEAR, earthpixi.resolution);

            baseRenderTex2.mipmap = true;
            const renderTex2 = new PIXI.RenderTexture(baseRenderTex2);

            earthpixi.renderer.render(sprite, renderTex2);
        }

        earthpixi.renderer.bindTexture(baseRenderTex, false, 0);
        // TODO remove in v5
        const glTex = baseRenderTex._glTextures[earthpixi.renderer.CONTEXT_UID];

        glTex.enableMipmap();
        glTex.enableLinearScaling();
        //

        const tex = new PIXI.Texture(baseRenderTex, new PIXI.Rectangle(0, 0, w, h));
        const newSpr = new PIXI.Sprite(tex);

        sprite.destroy();

        return newSpr;
    },

    /**
   *
   * Resize a PIXI.Sprite to fit the size of the stage based on current earthpixi resize mode.
   *
   * @memberof earthpixi.utils.Display
   * @param spr
   * @param [widthOverride]
   * @param [heightOverride]
   */
    fitBackground(spr, widthOverride, heightOverride)
    {
        let w = spr.width;
        let h = spr.height;

        if (widthOverride != null)
        {
            w = widthOverride;
        }

        if (heightOverride != null)
        {
            h = heightOverride;
        }

        let ratio;

        if (earthpixi.config.FIT_MODE === earthpixi.config.FIT_MODES.FIXED_HEIGHT)
        {
            ratio = h / earthpixi.config.STAGE_HEIGHT;
            spr.height = earthpixi.config.STAGE_HEIGHT;
            spr.width = w / ratio;
        }
        else
        {
            ratio = w / earthpixi.config.STAGE_WIDTH;
            spr.width = earthpixi.config.STAGE_WIDTH;
            spr.height = h / ratio;
        }

        const x = (spr.width - earthpixi.config.STAGE_WIDTH) / 2;

        spr.x = -x;
    },

    /**
   * Fit a sprite in a specified Rect, only works with default anchors at the moment
   *
   * @memberof earthpixi.utils.Display
   * @param obj
   * @param x
   * @param y
   * @param width
   * @param height
   * @param {bool} aligntop
   */
    fitInRect(obj, x, y, width, height, aligntop = false)
    {
        let objs = [];

        if (Array.isArray(obj))
        {
            objs = obj;
        }
        else
        {
            objs.push(obj);
        }

        for (let i = 0; i < objs.length; i++)
        {
            const ob = objs[i];

            const targetW = width || obj.width;
            const targetH = height || obj.height;

            if (ob.width > targetW)
            {
                ob.width = targetW;
                ob.scale.y = ob.scale.x;
            }

            if (ob.height > targetH)
            {
                ob.height = targetH;
                ob.scale.x = ob.scale.y;
            }

            ob.x = x + ((targetW - ob.width) / 2);
            ob.y = !aligntop ? y + ((targetH - ob.height) / 2) : y;
        }
    },

    getGameBounds()
    {
        if (this.maskOverride)
        {
            return new PIXI.Rectangle(0, 0, this.maskOverride.width, this.maskOverride.height);
        }

        return new PIXI.Rectangle(0, 0, earthpixi.config.STAGE_WIDTH, earthpixi.config.STAGE_HEIGHT);
    },

    /**
   *
   * Returns bounds of the visible game window in game sizes. Good for positioning elements based on current game window cropping.
   *
   * @memberof earthpixi.utils.Display
   *
   *
   * @returns {{left: number, right: number, top: number, bottom: number, width: number, height: number}}
   */
    getScreenBounds()
    {
        const gameBounds = earthpixi.utils.Display.getGameBounds();

        // width
        const actualWidth = gameBounds.width * earthpixi.stage.scale.x;
        const containerWidth = earthpixi.container ? earthpixi.container.offsetWidth : window.innerWidth;
        const widthDif = (containerWidth - actualWidth) / earthpixi.stage.scale.x;
        let screenRight = earthpixi.config.STAGE_WIDTH + ((gameBounds.width - earthpixi.config.STAGE_WIDTH) / 2);
        let screenLeft = -((gameBounds.width - earthpixi.config.STAGE_WIDTH) / 2);

        if (widthDif < 0)
        {
            screenRight += widthDif / 2;
            screenLeft += Math.abs(widthDif / 2);
        }

        // height
        const actualHeight = gameBounds.height * earthpixi.stage.scale.y;
        const containerHeight = earthpixi.container ? earthpixi.container.offsetHeight : window.innerHeight;
        const heightDif = (containerHeight - actualHeight) / earthpixi.stage.scale.y;

        let screenBottom = earthpixi.config.STAGE_HEIGHT + ((gameBounds.height - earthpixi.config.STAGE_HEIGHT) / 2);
        let screenTop = -((gameBounds.height - earthpixi.config.STAGE_HEIGHT) / 2);

        if (heightDif < 0)
        {
            screenBottom += heightDif / 2;
            screenTop += Math.abs(heightDif / 2);
        }

        const bounds = {
            left: screenLeft > 0 ? screenLeft : 0,
            x: screenLeft > 0 ? screenLeft : 0,
            right: screenRight < earthpixi.config.STAGE_WIDTH ? screenRight : earthpixi.config.STAGE_WIDTH,
            top: screenTop > 0 ? screenTop : 0,
            y: screenTop > 0 ? screenTop : 0,
            bottom: screenBottom < earthpixi.config.STAGE_HEIGHT ? screenBottom : earthpixi.config.STAGE_HEIGHT,
            width: actualWidth,
            height: actualHeight
        };

        return bounds;
    },

    updateMask(mask)
    {
        // hack for Pixi not scaling masks to thier parent:
        // https://github.com/pixijs/pixi.js/issues/1978
        if (mask.parent && mask.visible)
        {
            mask.visible = false;
            mask.updateTransform();
            // mask.parent.addChild(mask);
            mask.visible = true;
        }
    },

    resizeUpdate(resizeUpdateItem, bounds)
    {
        if (resizeUpdateItem._destroyed || !resizeUpdateItem)
        {
            return;
        }

        const screenBounds = bounds || earthpixi.utils.Display.getScreenBounds();

        if (resizeUpdateItem.anchorCenter !== undefined)
        {
            resizeUpdateItem.x = ((screenBounds.right - screenBounds.left) / 2) + screenBounds.left;
            resizeUpdateItem.y = ((screenBounds.bottom - screenBounds.top) / 2) + screenBounds.top;
            if (resizeUpdateItem.useWidth)
            {
                resizeUpdateItem.x -= resizeUpdateItem.width;
                resizeUpdateItem.y -= resizeUpdateItem.height;
            }
        }

        if (resizeUpdateItem.anchorCenterX !== undefined)
        {
            resizeUpdateItem.x = ((screenBounds.right - screenBounds.left) / 2) + screenBounds.left;
            if (resizeUpdateItem.useWidth)
            {
                resizeUpdateItem.x -= resizeUpdateItem.width;
                resizeUpdateItem.y -= resizeUpdateItem.height;
            }
        }

        if (resizeUpdateItem.anchorCenterY !== undefined)
        {
            resizeUpdateItem.y = ((screenBounds.bottom - screenBounds.top) / 2) + screenBounds.top;
            if (resizeUpdateItem.useWidth)
            {
                resizeUpdateItem.x -= resizeUpdateItem.width;
                resizeUpdateItem.y -= resizeUpdateItem.height;
            }
        }

        if (resizeUpdateItem.anchorRightDist !== undefined)
        {
            resizeUpdateItem.x = screenBounds.right - resizeUpdateItem.anchorRightDist;

            if (resizeUpdateItem.useWidth)
            {
                resizeUpdateItem.x -= resizeUpdateItem.width;
            }
        }

        if (resizeUpdateItem.anchorLeftDist !== undefined)
        {
            resizeUpdateItem.x = screenBounds.left + resizeUpdateItem.anchorLeftDist;
        }

        if (resizeUpdateItem.anchorBottomDist !== undefined)
        {
            resizeUpdateItem.y = screenBounds.bottom - resizeUpdateItem.anchorBottomDist;
            if (resizeUpdateItem.useHeight)
            { resizeUpdateItem.y -= resizeUpdateItem.height; }
        }

        if (resizeUpdateItem.anchorTopDist !== undefined)
        {
            resizeUpdateItem.y = screenBounds.top + resizeUpdateItem.anchorTopDist;
        }
    },

    getResizeScale()
    {
        let scale = 0;

        if (window.innerHeight > window.innerWidth)
        {
            scale = window.innerWidth / (earthpixi.config.STAGE_WIDTH);
        }
        else
        {
            scale = window.innerHeight / earthpixi.config.STAGE_HEIGHT;
        }

        return scale;
    },

    get videoAutoPlay()
    {
        return Modernizr.videoautoplay;
    },

    clearAnchors(resizeUpdateItem)
    {
        resizeUpdateItem.anchorRightDist = null;
        resizeUpdateItem.anchorLeftDist = null;
        resizeUpdateItem.anchorBottomDist = null;
        resizeUpdateItem.anchorTopDist = null;
    },

    ySort(container, des = true)
    {
        if (des)
        {
            container.children.sort(function (a, b)
            {
                return b.y - a.y;
            });
        }
        else
        {
            container.children.sort(function (a, b)
            {
                return a.y - b.y;
            });
        }
        container.onChildrenChange(0);
    },

    /**
   *
   *
   * Shortcuts to position a display object on the screen relative to stage size.
   *
   * ```js
   *  earthpixi.utils.Display.position.center(myObject);
   *
   * ```
   *
   *
   * @memberof earthpixi.utils.Display
   *
   * @property {function} center
   * @property {function} bottomCenter
   * @property {function} right
   * @property {function} bottomRight
   * @property {function} left
   * @property {function} bottomLeft
   *
   *
   *
   */
    position: {

        /**
     * @function
     * @memberof earthpixi.utils.Display.position
     * @param container
     */
        center: (container) =>
        {
            container.position.x = -((container.width - earthpixi.config.STAGE_WIDTH) / 2);
            container.position.y = (earthpixi.config.STAGE_HEIGHT - container.height) / 2;
        },

        /**
     * @function
     * @memberof earthpixi.utils.Display.position
     * @param container
     */
        centerX: (container) =>
        {
            container.position.x = -((container.width - earthpixi.config.STAGE_WIDTH) / 2);
        },

        /**
     * @function
     * @memberof earthpixi.utils.Display.position
     * @param container
     */
        centerY: (container) =>
        {
            container.position.y = (earthpixi.config.STAGE_HEIGHT - container.height) / 2;
        },

        /**
     * @function
     * @memberof earthpixi.utils.Display.position
     * @param container
     */
        bottomCenter: (container) =>
        {
            container.position.x = -((container.width - earthpixi.config.STAGE_WIDTH) / 2);
            container.position.y = earthpixi.config.STAGE_HEIGHT - container.height;
        },

        /**
     * @function
     * @memberof earthpixi.utils.Display.position
     * @param container
     */
        right: (container) =>
        {
            const bounds = earthpixi.utils.Display.getGameBounds();

            container.position.x = earthpixi.config.STAGE_WIDTH + ((bounds.width - earthpixi.config.STAGE_WIDTH) / 2) - container.width;
        },

        /**
     * @function
     * @memberof earthpixi.utils.Display.position
     * @param container
     */
        bottomRight: (container) =>
        {
            const bounds = earthpixi.utils.Display.getGameBounds();

            container.position.x = earthpixi.config.STAGE_WIDTH + ((bounds.width - earthpixi.config.STAGE_WIDTH) / 2) - container.width;
            container.position.y = earthpixi.config.STAGE_HEIGHT - container.height;
        },

        /**
     * @function
     * @memberof earthpixi.utils.Display.position
     * @param container
     */
        bottomLeft: (container) =>
        {
            const bounds = earthpixi.utils.Display.getGameBounds();

            container.position.x = -((bounds.width - earthpixi.config.STAGE_WIDTH) / 2);
            container.position.y = earthpixi.config.STAGE_HEIGHT - container.height;
        },

        /**
     * @function
     * @memberof earthpixi.utils.Display.position
     * @param container
     */
        left: (container) =>
        {
            const bounds = earthpixi.utils.Display.getGameBounds();

            container.position.x = -((bounds.width - earthpixi.config.STAGE_WIDTH) / 2);
        },

    },

    /**
   * If setting a mask over app, frameworks uses this as the bounds when calc anchors etc
   * @private
   */
    maskOverride: null,

    /**
   *
   *
   * Anchor a sprite / container to the screen edges to fit different screen sizes.
   * eg. sticks to edge when edges trimmed off on widescreen display.
   *
   * ```js
   *  // set anchor on object 30 pixels from right hand edge of screen
   *  earthpixi.utils.Display.anchor.right(myObject, 30);
   *
   * ```
   *
   *
   * @memberof earthpixi.utils.Display
   *
   * @property {function} center
   * @property {function} right
   * @property {function} bottom
   * @property {function} top
   * @property {function} left
   * @property {function} remove
   *
   *
   *
   */
    anchor: {

        /**
     * @memberof earthpixi.utils.Display.anchor
     * @param container
     * @param useWidth
     */
        center: (container, useWidth = true) =>
        {
            container.anchorCenter = true;
            container.useWidth = useWidth;
            if (earthpixi.resizeUpdateItems.indexOf(container) == -1)
            {
                earthpixi.resizeUpdateItems.push(container);
            }
            earthpixi.needsResize = true;
            //earthpixi.utils.Display.resizeUpdate(container);
        },

        centerX: (container, useWidth = true) =>
        {
            container.anchorCenterX = true;
            container.useWidth = useWidth;
            if (earthpixi.resizeUpdateItems.indexOf(container) == -1)
            {
                earthpixi.resizeUpdateItems.push(container);
            }
            earthpixi.needsResize = true;
            // earthpixi.utils.Display.resizeUpdate(container);
        },

        centerY: (container, useWidth = true) =>
        {
            container.anchorCenterY = true;
            container.useWidth = useWidth;
            if (earthpixi.resizeUpdateItems.indexOf(container) == -1)
            {
                earthpixi.resizeUpdateItems.push(container);
            }
            earthpixi.needsResize = true;
            // earthpixi.utils.Display.resizeUpdate(container);
        },

        right: (container, dist, useWidth = true) =>
        {
            container.anchorRightDist = dist;
            container.useWidth = useWidth;
            if (earthpixi.resizeUpdateItems.indexOf(container) == -1)
            {
                earthpixi.resizeUpdateItems.push(container);
            }
            earthpixi.needsResize = true;
            // earthpixi.utils.Display.resizeUpdate(container);
        },

        bottom: (container, dist, useHeight = true) =>
        {
            container.anchorBottomDist = dist;
            container.useHeight = useHeight;
            if (earthpixi.resizeUpdateItems.indexOf(container) === -1)
            {
                earthpixi.resizeUpdateItems.push(container);
            }
            earthpixi.needsResize = true;
            // earthpixi.utils.Display.resizeUpdate(container);
        },

        top: (container, dist, useHeight = true) =>
        {
            container.anchorTopDist = dist;
            container.useHeight = useHeight;
            if (earthpixi.resizeUpdateItems.indexOf(container) === -1)
            {
                earthpixi.resizeUpdateItems.push(container);
            }
            earthpixi.needsResize = true;
            // earthpixi.utils.Display.resizeUpdate(container);
        },

        left: (container, dist, useWidth = true) =>
        {
            container.anchorLeftDist = dist;
            container.useWidth = useWidth;
            if (earthpixi.resizeUpdateItems.indexOf(container) === -1)
            {
                earthpixi.resizeUpdateItems.push(container);
            }
            earthpixi.needsResize = true;
            // earthpixi.utils.Display.resizeUpdate(container);
        },

        remove: (container) =>
        {
            const index = earthpixi.resizeUpdateItems.indexOf(container);

            if (index !== -1)
            {
                earthpixi.resizeUpdateItems.splice(index, 1);
            }
            container.anchorLeftDist = undefined;
            container.anchorRightDist = undefined;
            container.anchorCenterX = undefined;
            container.anchorCenterY = undefined;
            container.anchorBottomDist = undefined;
            container.anchorTopDist = undefined;
            container.useWidth = undefined;

            earthpixi.needsResize = true;
        }

    }

};
