import howler from "howler/howler.core";
import ismobilejs from "ismobilejs";
const isMobile = ismobilejs(window.navigator);

let instance = null;

/**
 *
 * Audio Manager to play sounds and music.
 * Uses Howler js
 * https://github.com/goldfire/howler.js#documentation
 *
 * Examples:
 * ```js
 *
 * //Play a sound
 * earthpixi.Audio.playSFX("./sfx.mp3");

 * //play a pre loaded sound (to preload a sound with loader, use blob option)
 * ... {name:"sfx_spin", url:"sfx.mp3, options: {loadType: PIXI.loaders.Resource.LOAD_TYPE.XHR, xhrType: PIXI.loaders.Resource.XHR_RESPONSE_TYPE.BLOB}}
 *
 * earthpixi.Audio.playSFX(earthpixi.resources.sfx_spin.url);

 * //play looping sound continuously
 * var loopingSound = earthpixi.Audio.playSFX("./sfx.mp3", true);
 *
 * //stop looping sound
 * loopingSound.stop();
 *
 * //play sound after 2 second delay
 * earthpixi.Audio.playSFX("./sfx.mp3", false, 2);
 *
 * //play music track (only one music track can be played at a time)
 * earthpixi.Audio.playMusic("./game_music.mp3", true);

 * //here's a list of sounds
 * var list = [
 *  "sfx_rock_fall.mp3",
 *  "sfx_goat.url.mp3",
 *  "sfx_spin.url.mp3"
 * ];
 *
 * //play list of sounds one after the other
 * earthpixi.Audio.playList(list);

 * //play list of sounds after a delay,  then call a method
 * earthpixi.Audio.playList(list, this.soundsComplete, 1, null this);
 *
 *```
 *Sprites
 *
 * ```js
 *
 * //Preloading long sounds like sprite files,  use blob loading option in the asset list to make sure sound completely loads instead of streaming:
 * //sprite sound
 * assetList.push({name: 'audiosprite', url: earthpixi.config.AUDIO_ROOT + 'sprite.mp3',
 *    options: {
 *       loadType: PIXI.loaders.Resource.LOAD_TYPE.XHR,
 *       xhrType: PIXI.loaders.Resource.XHR_RESPONSE_TYPE.BLOB,
 *     }
 * });
 * //sprite data
 * assetList.push({name: 'audiospritedata', url: earthpixi.config.AUDIO_ROOT + 'sprite.json'};
 *
 *
 * //create a sound sprite from preloaded above
 * let audioBlob = URL.createObjectURL(earthpixi.resources.audiosprite.data);
 * earthpixi.Audio.createSprite("my_sprite_name",[audioBlob],earthpixi.resources.audiospritedata.data.sprite);
 *
 * //play something from the sprite
 * earthpixi.Audio.playSprite("my_sprite_name", "partId");
 *
 * ```
 *
 *
 *
 * @namespace earthpixi.Audio
 *
 */

export default class AudioManager
{
    /**
     * @private
     * @returns {*}
     */
    constructor()
    {
        if (!instance)
        {
            instance = this;
            this.init();
        }
        // singleton, dont initiate again
        return instance;
    }

    /**
     * @member earthpixi.Audio#useEPTimeScale
     * @returns {boolean}
     */
    get useEPTimeScale()
    {
        return this._useEPTimeScale;
    }

    /**
     * Set if new sounds are to be played faster or slower according to earthpixi.timeScale
     *
     * @member earthpixi.Audio#useEPTimeScale
     * @type {boolean}
     * @default true
     */
    set useEPTimeScale(val)
    {
        this._useEPTimeScale = val;
    }

    /**
     *
     * @private
     */
    init()
    {
        this._sfx = Object.create(null);
        this._sfxOnComplete = Object.create(null);
        this._musicComplete = Object.create(null);
        this._sprites = Object.create(null);
        this._spriteIdsPlaying = Object.create(null);
        this._spritesDelayed = Object.create(null);
        this._spritesQueueLoading = Object.create(null);
        this._musicEndBind = null;
        this._music = null;
        this._playListArr = [];
        this._playListID = "";
        this._playListCount = 0;
        this._playListDelay = 0.5;
        this._listComplete = false;
        this._paused = false;
        this._musicVolume = 1;
        this._sfxVolume = 1;
        this._muteMusic = false;
        this._muteSfx = false;
        this._useEPTimeScale = true;

        Howler.autoSuspend = false;
        Howler.autoUnlock = false;
        // Howler.usingHTMLAudio = false;
        Howler.html5PoolSize = 10; // HR, HS game audio pool was being maxed out, changed to 10

        this.checkSafeAudioContext();

        const ua = navigator.userAgent;

        // console.log(ua);

        this._ios15
            // safari 15 desktop is default user agent for ipad os 15
            = (ua.indexOf("OS X 10_15_") !== -1 && ua.indexOf("Safari") !== -1)
            // safari 15 on ios with desktop request off
            || (ua.indexOf("CPU OS 15_") !== -1 && ua.indexOf("Safari") !== -1);

        console.log(this._ios15 && isMobile.apple.device);

        if (!isMobile.apple.device)
        {
            this.checkAudioLock();
        }
    }

    /**
     * @private
     */
    checkSafeAudioContext()
    {
        let ac;

        if (isMobile.apple.device
            && Howler.ctx
            && Howler.ctx.sampleRate !== 44100
        )
        {
            console.log("try reset sample rate");
            try
            {
                ac = window.AudioContext
                    ? new window.AudioContext({ sampleRate: 44100 })
                    : new window.webkitAudioContext();
            }
            catch (e)
            {
                console.log("couldn't create audio context", e);
            }

            if (!ac) return;

            const buffer = ac.createBuffer(1, 1, 44100);
            const dummy = ac.createBufferSource();

            dummy.buffer = buffer;
            dummy.connect(ac.destination);
            dummy.start(0);
            dummy.disconnect(0);
        }

        if (ac && typeof ac.close !== "undefined")
        {
            ac.close();
            ac = null;
        }
    }

    checkHTML5AudioPool()
    {
        // console.log("current howls:", Howler._howls);
        // console.log("current pool:", Howler._html5AudioPool);

        let soundsCount = 0;

        if (Howler && Howler._howls)
        {
            for (const h of Howler._howls)
            {
                if (h._html5 && h._sounds && h._sounds.length)
                {
                    soundsCount += h._sounds.length;

                    if (soundsCount >= Howler._html5AudioPool - 1)
                    {
                        console.log("pool will be full, unload last oldest howl");
                        h.unload();
                        break;
                    }
                }
            }
        }
    }

    checkAudioLock()
    {
        // console.log("check audio", Howler);

        if (Howler.ctx)
        {
            if (Howler.ctx.state === "suspended")
            {
                console.log("audio got suspended", Howler.ctx.state);
                earthpixi.audioAwake = false;

                return;
            }
        }

        this.checkHTML5AudioPool();

        this.checkSafeAudioContext();

        earthpixi.activateAudio();
    }

    /**
     * @function earthpixi.Audio#wakeAudio
     * @static
     * @param evt
     */
    static wakeAudio(evt)
    {
        // if (evt.stopPropagation) evt.stopPropagation();
        // if (evt.data && evt.data.originalEvent && evt.data.originalEvent.stopPropagation) evt.data.originalEvent.stopPropagation();

        if (earthpixi.audioAwake) return;

        // audio unlock only works properly in ios now with touchend event
        if (evt && isMobile.apple.device && evt.type !== "touchend") return;

        console.log("wake audio");

        earthpixi.Audio.checkSafeAudioContext();

        Howler.volume(1.0); // init Howler audio context

        if (Howler.ctx && Howler.ctx.state && (Howler.ctx.state === "suspended" || Howler.ctx.state === "closed"))
        {
            Howler._audioUnlocked = false;
            // stop the unload call in howler
            Howler._mobileUnloaded = true;
            Howler._unlockAudio(earthpixi.Audio.onAwake, true);
        }
        else
        {
            earthpixi.Audio.onAwake();
        }
    }

    onAwake()
    {
        if (Howler.ctx && Howler.ctx.state === "suspended") return;

        // Let all sounds know that audio has been unlocked.
        for (let i = 0; i < Howler._howls.length; i++)
        {
            Howler._howls[i]._emit("unlock");
        }

        // resume all sfx and sounds played before waking audio
        if (!earthpixi.Audio._ios15) earthpixi.Audio.pauseAll(false);

        Howler._autoResume();

        // for (const key in earthpixi.Audio._sprites)
        // {
        //     console.log(earthpixi.Audio._sprites[key]);
        //     console.log(earthpixi.Audio._sprites[key]._state);
        //     //   earthpixi.Audio._sprites[key].play("silence");
        // }

        earthpixi.audioAwake = true;

        console.log("audio unlocked");

        if (earthpixi.currentScreen)
        {
            earthpixi.currentScreen.emit("audiounlock");
        }
    }

    /**
     * @function earthpixi.Audio#reset
     * @static
     * @param {boolean} [unload] whether to clear Howler audio cache, which can fix issues on iOS after playing videos/glitchy sound (Requires tap to re-activate audio)
     */
    reset(unload = false)
    {
        // console.warn("audio reset", unload);

        for (const sfx in this._sfx)
        {
            if (this._sfx[sfx])
            {
                this._sfx[sfx].stop();
                this._sfx[sfx].unload();
            }
        }
        this._sfx = Object.create(null);
        this.stopMusic();
        for (const spr in this._sprites)
        {
            // console.log(this._sprites[spr]);

            if (this._sprites[spr])
            {
                this._sprites[spr].off("end");
                this._sprites[spr].unload();
            }
        }

        this._sprites = Object.create(null);
        this._spritesQueueLoading = Object.create(null);
        this._spriteIdsPlaying = Object.create(null);
        this._sfxOnComplete = Object.create(null);
        this._musicComplete = Object.create(null);
        this._playListArr = [];
        this._playListCount = 0;
        this._playListDelay = 0.5;
        this._listComplete = false;
        this._paused = false;

        if (unload)
        {
            Howler.unload();
            earthpixi.audioAwake = false;
        }

        // console.warn("audio reset", Howler._howls.length, Howler._html5AudioPool.length);
    }

    /**
     * @function earthpixi.Audio#playSFX
     * @static
     * @param {string} src - url of sound file
     * @param {boolean} [loop]
     * @param {number | null} [delay] - Delay in seconds before starting sound.
     * @param {function} [onComplete] - Method to call when sound finishes.
     * @param {object} [onCompleteScope] - Where to call onComplete from, otherwise scope will be the sound object
     * @param {string} [format = "mp3"] - specify type of file to load (eg if using file or blob with not extention
     * @param {boolean|null} [html5 = null] - set Howl html5 to true, might be better for large files to start without fully downloading
     * @param {number} [volume = 1] - set Howl volume for this sound
     * @returns {Howl}
     */
    playSFX(src, loop = false, delay = null, onComplete = null, onCompleteScope = null, format = "mp3", html5 = null, volume = 1)
    {
        this.stopSFX(src);

        this.checkAudioLock();

        const extFormat = this.getFormat(src);

        // const h5 = html5 || null;
        const h5 = html5 || (isMobile.apple.device && this._ios15) ? true : null;

        this._sfx[src] = new Howl({
            src: [src],
            // autoplay: !delay,
            autoplay: false,
            volume: this._sfxVolume,
            html5: h5,
            loop,
            rate: this._useEPTimeScale ? earthpixi.timeScale : 1,
            // preload: "true",
            format: extFormat ? extFormat : format
            // onend: this._onSoundEnd.bind(this, src)
        });

        this._sfx[src].once("end", this._onSoundEnd.bind(this, src));
        if (!delay) this._sfx[src].once("load", this._onSoundLoad.bind(this, src));
        this._sfx[src].once("play", this._onSoundPlay.bind(this, src));

        // this._sfx[src].pause();

        if (onComplete)
        {
            this._sfxOnComplete[src] = { onComplete, onCompleteScope };
        }

        if (delay)
        {
            const timeDelay = this.useEPTimeScale ? delay / earthpixi.timeScale : delay;

            this._sfx[src].delayTimeOut = setTimeout(this._onDelayedSFX.bind(this, src), timeDelay * 1000);
        }
        // else
        // {
        //     // this._sfx[src].play();
        // }

        return this._sfx[src];
    }

    /**
     *
     * Play a part af a sprite you've created with earthpixi.Audio.createSprite()
     * Returns id from Howl.play, or null if delay used.
     *
     * To stop the sound use earthpixi.Audio.stopSprite(spriteId, partName)
     *
     *
     * @function earthpixi.Audio#playSprite
     * @static
     * @param {string} spriteId - sprite id when created
     * @param {string} partName - name of sprite part to play
     * @param {number} [delay] - Delay in seconds before starting sound.
     * @param {function} [onComplete] - Method to call when sound finishes.
     * @param {object} [onCompleteScope] - Where to call onComplete from, otherwise scope will be the sound object
     * @param {object} [onStart] - call when sound starts
     * @returns {string | null} id from Howl.play() used in sprite, or null if delay used
     */
    playSprite(spriteId, partName, delay = 0, onComplete = null, onCompleteScope = null, onStart)
    {
        if (!this._sprites[spriteId] || !this._sprites[spriteId]._sprite[partName])
        {
            console.warn("mm: sprite, or sprite part missing", spriteId, ",", partName);

            return;
        }

        // console.log(this._sprites[spriteId]);

        if (this._sprites[spriteId]._state !== "loaded")
        {
            console.log("mm: state not loaded, queued");

            const data = { partName, delay, onComplete, onCompleteScope, onStart };

            if (this._spritesQueueLoading[spriteId])
            {
                this._spritesQueueLoading[spriteId].push(data);
            }
            else
            {
                this._spritesQueueLoading[spriteId] = [data];
            }

            return;
        }

        // check not playing already and stop
        this.stopSprite(spriteId, partName);

        if (delay)
        {
            const delayInfo = {
                timeOutId: 0,
                spriteId,
                partName,
                delay,
                onComplete,
                onCompleteScope,
                onStart
            };

            const timeDelay = this.useEPTimeScale ? delay / earthpixi.timeScale : delay;

            delayInfo.timeOutId = setTimeout(this.playDelayedSprite.bind(delayInfo), timeDelay * 1000);

            this._spritesDelayed[delayInfo.timeOutId] = delayInfo;

            this._sprites[spriteId].play("silence");

            return null; // no play id when delayed so return null
        }

        // console.log("play sprite", spriteId, partName, this._sprites[spriteId]._sounds[0]._paused);

        this.checkAudioLock();

        const pId = this._sprites[spriteId].play(partName);

        this._spriteIdsPlaying[pId] = partName;

        if (onComplete)
        {
            this._sfxOnComplete[pId] = { onComplete, onCompleteScope };
        }

        if (onStart) onStart();
        else console.log('mm: no on start method')
        return pId;
    }

    /**
     * @function earthpixi.Audio#stopSprite
     * @static
     * @param {string} spriteId id you gave a sprite you created
     * @param {string} [partName] specify part name, or stops anything playing from sprite
     * @param {boolean} [unload] unload the sprite if not needed anymore
     */
    stopSprite(spriteId, partName, unload = false)
    {
        if (this._sprites[spriteId])
        {
            if (partName)
            {
                for (const id in this._spriteIdsPlaying)
                {
                    if (this._spriteIdsPlaying[id] === partName)
                    {
                        this._sprites[spriteId].stop(typeof id === "string" ? parseInt(id) : id);

                        delete this._spriteIdsPlaying[id];
                        break;
                    }
                }
            }
            else
            {
                this._sprites[spriteId].stop();
            }

            for (const id in this._spritesDelayed)
            {
                const sprDelayed = this._spritesDelayed[id];

                if (
                    sprDelayed
                    && sprDelayed.spriteId === spriteId
                    && sprDelayed.partName === partName
                )
                {
                    clearTimeout(id);
                    delete this._spritesDelayed[id];
                    break;
                }
            }
        }

        if (this._spritesQueueLoading[spriteId])
        {
            delete this._spritesQueueLoading[spriteId];
        }

        if (unload)
        {
            if (this._sprites[spriteId])
            {
                this._sprites[spriteId].unload();
                delete this._sprites[spriteId];
            }
        }
    }

    /**
     * @private
     */
    playDelayedSprite(delayInfo)
    {
        clearTimeout(this.timeOutId);

        // check not cleared and still valid (in case sounds unloaded)
        if (earthpixi.Audio._spritesDelayed[this.timeOutId])
        {
            delete earthpixi.Audio._spritesDelayed[this.timeOutId];

            const pId = earthpixi.Audio.playSprite(this.spriteId, this.partName, 0, this.onComplete, this.onCompleteScope, this.onStart);
        }
    }

    /**
     * @function earthpixi.Audio#playMusic
     * @static
     *
     * @param {string} src url of sound file
     * @param {boolean} [loop]
     * @param {function} [onComplete]
     * @param {string} [fileType]
     * @param {object} [onCompleteScope] - Where to call onComplete from, otherwise scope will be the sound object
     * @param {boolean} [html5] - Where to call onComplete from, otherwise scope will be the sound object
     * @returns {Howl}
     */
    playMusic(src, loop = false, onComplete = null, fileType = null, onCompleteScope = null, html5 = null)
    {
        this.checkAudioLock();

        earthpixi.currentScreen.screenMusic = src;

        if (this._music)
        {
            // dont play if playing track already
            if (this._music._src === src
                && !this._music._sounds[0].ended
                && this._music._state === "loaded"
            )
            {
                return;
            }

            // stop previous playing track
            this.stopMusic();
        }

        if (this._musicVolume === 0 || this._muteMusic)
        {
            return;
        }

        if (onComplete)
        {
            this._musicComplete[src] = onComplete;
            onComplete._userScope = onCompleteScope;
        }

        const extFormat = this.getFormat(src);

        // const h5 = html5 || null;
        const h5 = html5 || (isMobile.apple.device && this._ios15) ? true : null;

        this._music = new Howl({
            src: [src],
            autoplay: false,
            loop,
            rate: this._useEPTimeScale ? earthpixi.timeScale : 1,
            volume: this._musicVolume,
            format: extFormat ? extFormat
                : fileType ? [fileType] : null,
            html5: h5,
            autoSuspend: false, // temp fix for chrome
            // onend: this._onSoundEnd.bind(this, src)
        });
        this._musicEndBind = this._onSoundEnd.bind(this, src);
        this._music.on("end", this._musicEndBind);
        this._music.pause();
        this._music.play();

        return this._music;
    }

    // adjust music volume

    adjustVolume({musicVolume, sfxVolume, musicMuted, sfxMuted}){
        // {
        //     sfxVolume: number
        //     sfxMuted: boolean
        //     musicVolume: number
        //     musicMuted: boolean
        // }
        console.log('Adjusting volume!: ', {musicVolume, sfxVolume, musicMuted, sfxMuted})

        let newMusicVolume = musicVolume / 100
        let newSfxVolume = sfxVolume / 100

        console.log('will set: ', {newSfxVolume, newMusicVolume})
        // set volume levels
        if(this._music && this._music.volume){
            console.log('setting music volume directly: ')
            this._musicVolume = newMusicVolume
            this._music.volume(this._musicVolume)
        }
        else{
            this._musicVolume = newMusicVolume
        }
        
        // sfx will never work like this, since this._sfx only lives for as long as an effect is playing. better to set sfx volume
        if(this._sfx && this._sfx.volume){
            console.log('setting sfx volume directly: ')
            this._sfxVolume = newSfxVolume
            this._sfx.volume(this._sfxVolume)
        }
        else{
            this._sfxVolume = newSfxVolume
        }

        // muting
        this._muteMusic = musicMuted
        this.muteMusic(musicMuted)

        this._muteSfx = sfxMuted

        console.log('this music: ', this._music)
        console.log('this music volume: ', this._musicVolume)
    }

    /**
     * @function earthpixi.Audio#muteMusic
     * @static
     * @param bool
     */
    muteMusic(bool)
    {
        console.log("muteMusic", bool);

        if (bool !== undefined)
        {
            this._muteMusic = bool;
        }
        else
        {
            this._muteMusic = !this._muteMusic;
            // console.log("mute switched to", this._muteMusic, this._music);
        }

        if (this._music)
        {
            this._music.volume(this._muteMusic ? 0 : this._musicVolume);
            if (this._music._html5)
            {
                this._music.mute(this._muteMusic);
            }
        }
        else
        if (
            earthpixi.currentScreen
            && earthpixi.currentScreen.screenMusic
            && !this._muteMusic
            && this._musicVolume !== 0
        )
        {
            earthpixi.Audio.playMusic(earthpixi.currentScreen.screenMusic, true);
        }
    }

    /**
     * Creates a sound sprite (sound sprites created with gulp-audiosprite, see boiler plate example)
     *
     *
     * @function earthpixi.Audio#createSprite
     * @static
     * @param {string} id Name to use when referring to it later
     * @param {array} urls - Audio file urls for supported formats ["sprite.mp3", "sprite.ac3", "sprite.m4a"]
     * @param {string} spriteData - Sprite data from sound sprite json file. If json loaded with Loader, then pass in earthpixi.resources.mySpriteJson.data.sprite
     * @param {number} [poolSize] - number of playing sounds to pool see Howl docs, if you are playing loads of sounds from this sprite and they stop working, may need to increase this
     * @returns {Howl}
     *
     */
    createSprite(id, urls, spriteData, poolSize = 12)
    {
        // fix any silence track looping
        if (spriteData.silence)
        {
            spriteData.silence[2] = false;
        }

        // console.warn("create sprite", id, typeof urls[0], urls[0]);

        // const h5 = null;
        const h5 = (isMobile.apple.device && this._ios15) ? true : null;
        //  const blob = urls[0].includes("blob");

        // console.log("blob", blob);

        const sprConfig = {
            src: urls,
            html5: h5,
            sprite: spriteData,
            pool: poolSize,
            preload: true,
            rate: this._useEPTimeScale ? earthpixi.timeScale : null,
            format: "mp3",
            autoplay: false
        };

        const spr = new Howl(sprConfig);

        spr._id = id;

        spr.on("end", this._onSpriteEnd.bind(this, id));
        // spr.on("play", () => { console.log("onplay", id, Howler.masterGain.gain.value); });
        // console.log(spr._state);

        if (spr._state !== "loaded")
        {
            spr.on("load", this._onSpriteLoad.bind(this, id));
        }

        if (this._sprites[id] && this._sprites[id].unload) this._sprites[id].unload();

        this._sprites[id] = spr;
        // attempt to init sprite if it has a silence track
        // if (spriteData.silence)
        // {
        //        this._sprites[id].play("silence");
        // }

        return spr;
    }

    /**
     * Stops music track playing
     *
     * TODO Make playing multiple music tracks a thing
     * @function earthpixi.Audio#stopMusic
     * @static
     */
    stopMusic()
    {
        if (this._music)
        {
            this._music._queue = [];
            this._music.off("end", this._musicEndBind);
            this._music.stop();
            this._music.unload();
            this._music = null;
        }

        if (earthpixi.currentScreen && earthpixi.currentScreen.screenMusic)
        {
            earthpixi.currentScreen.screenMusic = null;
        }
    }

    /**
     * Stop a sound effect
     * @function earthpixi.Audio#stopSFX
     * @static
     * @param {string} src - url of the sound, or id of the sprite to stop
     */
    stopSFX(src)
    {
        if (this._sfx[src])
        {
            this._sfx[src].stop();
            this._sfx[src].unload();
            // console.log(this._sfx[src]);
            delete this._sfx[src];
        }

        if (this._sprites[src])
        {
            this._sprites[src].stop();
        }
    }

    /**
     * @function earthpixi.Audio#stopAllSFX
     * @static
     * @param {boolean} [reset] clear SFX and sprites cache
     */
    stopAllSFX(reset = false)
    {
        for (const key in this._sfx)
        {
            if (this._sfx[key])
            {
                // this._sfx[key].stop();

                if (this._sfx[key].delayTimeOut)
                {
                    clearTimeout(this._sfx[key].delayTimeOut);
                }

                // sfx should be unloaded as we always play from url
                //
                // if (reset && this._sfx[key].unload)
                // {
                this._sfx[key].stop();
                this._sfx[key].unload();
                delete this._sfx[key];
                // }
            }
        }

        for (const key in this._sprites)
        {
            if (this._sprites[key])
            {
                for (const sprPId in this._spriteIdsPlaying)
                {
                    // console.log(this._sprites[key], this._spriteIdsPlaying[sprPId]);
                    this._sprites[key].stop(sprPId);
                    this._sprites[key].stop(typeof sprPId === "string" ? parseInt(sprPId) : sprPId);
                }

                this._sprites[key].stop();

                if (this._sprites[key].delayTimeOut)
                {
                    clearTimeout(this._sprites[key].delayTimeOut);
                }

                if (reset)
                {
                    // console.log("unload sprite", key);
                    this._sprites[key].off("end");
                    this._sprites[key].unload();
                    delete this._sprites[key];
                }
            }
        }

        for (const key in this._spritesDelayed)
        {
            if (this._spritesDelayed[key])
            {
                clearTimeout(key);
                delete this._spritesDelayed[key];
            }
        }

        this._spritesDelayed = Object.create(null);
        this._spritesQueueLoading = Object.create(null);

        this._playListArr = [];

        if (this._playListDelayInterval)
        {
            clearInterval(this._playListDelayInterval);
            this._playListDelayInterval = null;
        }

        if (reset)
        {
            this.reset();
        }
    }

    /**
     * Pause / Resume currently playing sounds, also called as part of earthpixi.pause(bool)
     *
     * @function earthpixi.Audio#pauseAll
     * @static
     * @param bool
     */
    pauseAll(bool)
    {
        this.pauseSfx(bool);
        this.pauseSprites(bool);
    }

    pauseSfx(bool)
    {
        for (const key in this._sfx)
        {
            if (bool)
            {
                if (this._sfx[key] && this._sfx[key]._state === "loaded" && !this._sfx[key]._sounds[0]._ended)
                {
                    this._sfx[key].pause();
                }
            }
            else if (this._sfx[key] && this._sfx[key]._state === "loaded" && !this._sfx[key]._sounds[0]._ended)
            {
                this._sfx[key].play();
            }
        }
    }

    pauseSprites(bool)
    {
        for (const key in this._sprites)
        {
            if (bool)
            {
                for (let i = 0; i < this._sprites[key]._sounds.length; i++)
                {
                    if (!this._sprites[key]._sounds[i]._ended)
                    {
                        this._sprites[key].pause(this._sprites[key]._sounds[i]._id);
                        // console.log("pause " + this._sprites[key]._sounds[i]._id);
                    }
                }
            }
            else
            {
                for (let i = 0; i < this._sprites[key]._sounds.length; i++)
                {
                    if (
                        this._sprites[key]._sounds[i]._paused
                        && this._sprites[key]._sounds[i]._node
                        && !this._sprites[key]._sounds[i]._ended
                    )
                    {
                        this._sprites[key].play(this._sprites[key]._sounds[i]._id);
                    }
                }
            }
        }
    }

    _onSpriteLoad(spriteId)
    {
        // console.log("sprite loaded");
        if (this._spritesQueueLoading[spriteId])
        {
            for (const data of this._spritesQueueLoading[spriteId])
            {
                if (data.list)
                {
                    this.playList(data.soundsArr, data.onComplete, data.delaySeconds, data.spriteId, data.onCompleteScope);
                }
                else
                {
                    // console.log("play now loaded", spriteId, data.partName);
                    this.playSprite(spriteId, data.partName, data.delay, data.onComplete, data.onCompleteScope);

                    if (data.onStart) data.onStart();
                }
            }

            delete this._spritesQueueLoading[spriteId];
        }
    }

    _onSpriteEnd(spriteId, id)
    {
        // looks like howler fixed
        // make sure this sprite track has ended, bug with chaining events playing same sprite
        // if(this._sprites[spriteId] && this._sprites[spriteId]._sounds){
        //   for(let i=0; i<this._sprites[spriteId]._sounds.length; i++){
        //     if(this._sprites[spriteId]._sounds[i]._id===id){
        //       console.log(this._sprites[spriteId],id,this._sprites[spriteId]._sounds[i]._ended);
        //       this._sprites[spriteId]._sounds[i]._ended = true;
        //       break;
        //     }
        //   }
        // }

        if (this._sfxOnComplete[id])
        {
            if (this._sfxOnComplete[id].onCompleteScope)
            {
                this._sfxOnComplete[id].onComplete.apply(this._sfxOnComplete[id].onCompleteScope, []);
            }
            else
            {
                this._sfxOnComplete[id].onComplete();
            }
            this._sfxOnComplete[id] = null;
        }

        if (this._playListArr[this._playListCount - 1])
        {
            if (this._playListArr[this._playListCount - 1] === this._spriteIdsPlaying[id])
            {
                this._onPlayListSoundComplete();
            }
        }

        delete this._spriteIdsPlaying[id];
    }

    _onSoundLoad(src)
    {
        if (this._sfx[src])
        {
            this._sfx[src].play();
        }
    }

    _onSoundEnd(src)
    {
        // console.log(`sound complete ${src}`, this._sfx[src]);
        if (this._sfx[src])
        {
            if (!this._sfx[src]._loop)
            {
                // console.log("unload", src);
                this._sfx[src].unload();
                this._sfx[src] = null;
            }
        }
        else if (this._music && this._music.src === src)
        {
            this._music.unload();
            this._music = null;
            earthpixi.currentScreen.screenMusic = null;
        }

        if (this._sfxOnComplete[src])
        {
            if (this._sfxOnComplete[src].onCompleteScope)
            {
                this._sfxOnComplete[src].onComplete.apply(this._sfxOnComplete[src].onCompleteScope, []);
            }
            else
            {
                this._sfxOnComplete[src].onComplete();
            }
            delete this._sfxOnComplete[src];
        }

        if (this._musicComplete[src])
        {
            this._musicComplete[src]();
            delete this._musicComplete[src];
        }

        if (this._playListArr[this._playListCount - 1])
        {
            if (this._playListArr[this._playListCount - 1] === src)
            {
                this._onPlayListSoundComplete();
            }
        }
    }

    _onSoundPlay(src)
    {
        // if (howl._tempVol !== undefined && howl._tempVol !== null)
        // {
        //     console.warn("sound playing, set temp vol", howl._tempVol, howl);
        //     howl.volume(howl._tempVol);
        //     howl._tempVol = null;
        // }
    }

    _onDelayedSFX(src)
    {
        // console.log("delayed sound", src);
        // scope is bound to Howl sound
        clearTimeout(this.delayTimeOut);
        // if paused then set timeout again
        if (earthpixi.Audio._paused)
        {
            // console.log("paused");
            const timeDelay = this.useEPTimeScale ? this.delayTimeOut / earthpixi.timeScale : this.delayTimeOut;

            this.delayTimeOut = setTimeout(earthpixi.Audio._onDelayedSFX.bind(this, src), timeDelay);
        }
        else
        {
            const howl = this._sfx[src];

            // console.log(howl._state);

            if (!howl) return;

            if (howl._state === "loading")
            {
                howl.once("load", () => { howl.play(); });
            }
            else if (howl._state === "loaded")
            {
                howl.play();
            }
            else
            {
                howl.unload();
            }
        }
    }

    /**
     * Play list of sound urls, or sprite names (use same sprite for all in the list)
     *
     * @function earthpixi.Audio#playList
     * @static
     * @param {array} soundsArr - Array of urls to sounds files
     * @param {function} [onComplete] - method to call when playing list is complete
     * @param {number} [delaySeconds] - gap between sounds
     * @param {string} [spriteId] - set if want to use sprite instead of sound file urls
     * @param {object} [onCompleteScope] - Where to call onComplete from, otherwise scope will be the sound object
     */
    playList(soundsArr, onComplete = null, delaySeconds = 0, spriteId = null, onCompleteScope = null)
    {
        this._playListDelay = delaySeconds;
        this._playListSprite = spriteId;

        if (spriteId)
        {
            if (!this._sprites[spriteId])
            {
                console.warn("sprite missing", spriteId);

                return;
            }

            if (!this._sprites[spriteId]._state === "loaded")
            {
                // console.log("sprite state not loaded, queued");

                const data = { list: true, soundsArr, spriteId, delaySeconds, onComplete, onCompleteScope };

                if (this._spritesQueueLoading[spriteId])
                {
                    this._spritesQueueLoading[spriteId].push(data);
                }
                else
                {
                    this._spritesQueueLoading[spriteId] = [data];
                }

                return;
            }
        }

        // if already playing same list make sure stop all sounds that could be playing
        const playlistID = soundsArr.length > 1 ? soundsArr.join : `${soundsArr[0]}_list`;

        if (this._playListID === playlistID)
        {
            for (let i = 0; i < this._playListArr.length; i++)
            {
                this.stopSFX(this._playListArr[i]);
            }
        }
        this._playListID = playlistID;

        if (this._playListDelayInterval)
        {
            clearInterval(this._playListDelayInterval);
        }

        this._playListCount = 0;
        this._playListArr = soundsArr;
        this._playPlayListSound();

        if (onComplete)
        {
            this._listComplete = onComplete;
            onComplete._userScope = onCompleteScope;
        }

        return this._playListID;
    }

    /**
     * add a sound to a currently playing list
     *
     * @function earthpixi.Audio#addToList
     * @static
     * @param {string} listId - id returned from playList()
     * @param {sound} sound url
     */
    addToList(listId, sound)
    {
        if (this._playListID !== listId)
        {
            console.warn("cant add to list, list id not playing");

            return;
        }

        this._playListArr.push(sound);
    }

    /**
     *
     * @private
     */
    _playPlayListSound()
    {
        if (this._playListCount < this._playListArr.length)
        {
            const src = this._playListArr[this._playListCount];

            this.stopSFX(src);

            if (this._playListSprite && src.indexOf(".mp3") == -1)
            {
                this.playSprite(this._playListSprite, src);
            }
            else
            {
                this.playSFX(src);
            }

            this._playListCount++;
        }
        else
        {
            if (this._listComplete)
            {
                if (this._listComplete._userScope)
                {
                    this._listComplete.apply(this._listComplete._userScope, []);
                }
                else
                {
                    this._listComplete();
                }

                this._listComplete = null;
            }
            this._playListDelay = 0;
            this._playListArr = [];
        }
    }

    /**
     * @private
     * @param url
     */
    getFormat(url)
    {
        let ext = (/^data:audio\/([^;,]+);/i).exec(url);

        if (!ext)
        {
            ext = (/\.([^.]+)$/).exec(url.split("?", 1)[0]);
        }

        if (ext)
        {
            ext = ext[1].toLowerCase();

            return ext;
        }

        return null;
    }

    /**
     *
     * @private
     */
    _onPlayListSoundComplete()
    {
        if (this._playListDelay > 0)
        {
            const timeDelay = this.useEPTimeScale ? this._playListDelay / earthpixi.timeScale : this._playListDelay;

            this._playListDelayInterval = setTimeout(this._playPlayListSound.bind(this), (timeDelay * 1000));
        }
        else
        {
            this._playPlayListSound();
        }
    }
}

