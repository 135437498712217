import config from "../config";
import ismobilejs from "ismobilejs";
const isMobile = ismobilejs(window.navigator);

export default function ()
{
    if (config.FORCE_RES)
    {
        console.log(`force res${config.FORCE_RES}`);

        return config.FORCE_RES;
    }

    const resizeScale = earthpixi.utils.Display.getResizeScale();

    if (resizeScale > 0.65 || window.devicePixelRatio >= 1.5)
    {
        if (checkWebGl() /* && !device.phone*/)
        {
            // if browser size is really small ignore 2x
            if (resizeScale < 0.5)
            {
                return 1;
            }

            return 2;
        }
    }

    return 1;
}

function checkWebGl()
{
    if (window.WebGLRenderingContext)
    {
        const canvas = document.createElement("canvas");

        document.body.appendChild(canvas);
        let gl;
        const possibleNames = ["webgl", "webgl2", "experimental-webgl2", "experimental-webgl"];

        for (let i = 0; i < possibleNames.length; i++)
        {
            gl = canvas.getContext(possibleNames[i]);
            if (gl)
            {
                break;
            }
        }
        // web gl context found
        if (gl)
        {
            if (isMobile.tablet && window.devicePixelRatio < 1.5)
            {
                return false;
            }

            const maxVertexUniformVectors = gl.getParameter(gl.MAX_VERTEX_UNIFORM_VECTORS);
            const maxTextureSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);

            PIXI.settings.SPRITE_MAX_TEXTURES = Math.min(
                PIXI.settings.SPRITE_MAX_TEXTURES,
                checkMaxSamplersInShader(PIXI.settings.SPRITE_MAX_TEXTURES, gl)
            );

            document.body.removeChild(canvas);

            if (maxVertexUniformVectors < 512 || maxTextureSize < 4096)
            {
                // console.log("WebGL performance not good enough for 2x res");

                return false;
            }

            return true;
        }

        // console.log("WebGL context not found");

        return false;
    }
    // console.log("WebGL not supported");

    return false;
}

function checkMaxSamplersInShader(maxSamplers, gl)
{
    if (maxSamplers === 0)
    {
        console.warn("Invalid value of `0` passed to `checkMaxSamplersInShader`");

        return maxSamplers;
    }

    const debugInfo = gl.getExtension("WEBGL_debug_renderer_info");
    const renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);

    // Mesa drivers may crash with more than 16 samplers and Firefox
    // will actively refuse to create shaders with more than 16 samplers.
    if (renderer.slice(0, 4).toUpperCase() === "MESA")
    {
        maxSamplers = Math.min(16, maxSamplers);
    }

    return maxSamplers;
}
