import minisvguri from "mini-svg-data-uri";
/**
 * * Create an SVG Sprite.
 *
 * See boiler plate for loading inline svg files.
 * Earthipixi has made some tweaks to PIXI and set up and a minisvg loader for this, so svgs get compiled into the minified code of your app. Cool huh??
 *
 * ```js
 * //import svg files like other js classes (webpack loader set up for this in boiler plate)
 * import logo_svg from "../ui/svgs/nessy_logo.svg";
 *
 * ...
 *
 * let logo = this.logo = new earthpixi.SVGSprite(logo_svg, 0.2);
 *
 *
 * ```
 *
 * @class
 * @memberof earthpixi
 * @extends {PIXI.Sprite}
 */
export default class SVGSprite extends PIXI.Sprite
{
    /**
     * @param {Object} svgData
     * @param {number} scale
     * @param {bool} [applyMiniSvg=false]
     * @param {bool} [waitForDom=false] Need this to make the logo work before loading screen
     */
    constructor(svgData, scale, applyMiniSvg = false, waitForDom = false)
    {
        super();

        this.svgSpriteData = {
            svgData,
            scale,
            applyMiniSvg
        };

        if (waitForDom)
        {
            const imgDom = new Image();

            imgDom.src = applyMiniSvg ? minisvguri(svgData) : svgData;

            imgDom.onload = () =>
            {
                this.onLoaded();
            };
        }
        else
        {
            this.onLoaded();
        }

        /**
         *
         * @type {boolean}
         */
        this.dontDestroy = false;
    }

    onLoaded()
    {
        const texture = PIXI.Texture.fromImage(
            this.svgSpriteData.applyMiniSvg ? minisvguri(this.svgSpriteData.svgData) : this.svgSpriteData.svgData,
            false, PIXI.settings.SCALE_MODE, (this.svgSpriteData.scale || 1) * earthpixi.resolution,
            earthpixi.resolution
        );

        texture.baseTexture.resolution = earthpixi.resolution;

        this.texture = texture;
    }

    /**
     *
     * @param {PIXI.DestroyOptions | Boolean} [options]
     */
    destroy(options)
    {

        this.svgSpriteData = null;
        super.destroy(options);

    }

    // updateBase(){
    //   let oldTexture = this.texture;
    //   this.texture = earthpixi.renderer.generateTexture(this,PIXI.settings.SCALE_MODE, earthpixi.resolution);
    //
    //   //oldTexture.destroy(true);
    // }
}
