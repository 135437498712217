// logic
/**
 * Please note that the keyCode for the uppercase letter and the charCode for the lowercase letter are being used here. This is because JavaScript character codes correspond to the ASCII table where upper-case and lower-case letters have different values. The keyCode property of the keyboard event object in JavaScript was not case-sensitive, so it always represented the uppercase letter, even if the lowercase one was pressed. On the other hand, the charCode property was case-sensitive.
 */

export default {
    "Digit0": {
        "keyCode": 48,
        "charCode": 48
    },
    "Digit1": {
        "keyCode": 49,
        "charCode": 49
    },
    "Digit2": {
        "keyCode": 50,
        "charCode": 50
    },
    "Digit3": {
        "keyCode": 51,
        "charCode": 51
    },
    "Digit4": {
        "keyCode": 52,
        "charCode": 52
    },
    "Digit5": {
        "keyCode": 53,
        "charCode": 53
    },
    "Digit6": {
        "keyCode": 54,
        "charCode": 54
    },
    "Digit7": {
        "keyCode": 55,
        "charCode": 55
    },
    "Digit8": {
        "keyCode": 56,
        "charCode": 56
    },
    "Digit9": {
        "keyCode": 57,
        "charCode": 57
    },
    // alaphabet
    "KeyA": {
        "keyCode": 65,
        "charCode": 97
    },
    "KeyB": {
        "keyCode": 66,
        "charCode": 98
    },
    "KeyC": {
        "keyCode": 67,
        "charCode": 99
    },
    "KeyD": {
        "keyCode": 68,
        "charCode": 100
    },
    "KeyE": {
        "keyCode": 69,
        "charCode": 101
    },
    "KeyF": {
        "keyCode": 70,
        "charCode": 102
    },
    "KeyG": {
        "keyCode": 71,
        "charCode": 103
    },
    "KeyH": {
        "keyCode": 72,
        "charCode": 104
    },
    "KeyI": {
        "keyCode": 73,
        "charCode": 105
    },
    "KeyJ": {
        "keyCode": 74,
        "charCode": 106
    },
    "KeyK": {
        "keyCode": 75,
        "charCode": 107
    },
    "KeyL": {
        "keyCode": 76,
        "charCode": 108
    },
    "KeyM": {
        "keyCode": 77,
        "charCode": 109
    },
    "KeyN": {
        "keyCode": 78,
        "charCode": 110
    },
    "KeyO": {
        "keyCode": 79,
        "charCode": 111
    },
    "KeyP": {
        "keyCode": 80,
        "charCode": 112
    },
    "KeyQ": {
        "keyCode": 81,
        "charCode": 113
    },
    "KeyR": {
        "keyCode": 82,
        "charCode": 114
    },
    "KeyS": {
        "keyCode": 83,
        "charCode": 115
    },
    "KeyT": {
        "keyCode": 84,
        "charCode": 116
    },
    "KeyU": {
        "keyCode": 85,
        "charCode": 117
    },
    "KeyV": {
        "keyCode": 86,
        "charCode": 118
    },
    "KeyW": {
        "keyCode": 87,
        "charCode": 119
    },
    "KeyX": {
        "keyCode": 88,
        "charCode": 120
    },
    "KeyY": {
        "keyCode": 89,
        "charCode": 121
    },
    "KeyZ": {
        "keyCode": 90,
        "charCode": 122
    },
    // special characters
    "Space": {
        "keyCode": 32,
        "charCode": 32
    },
    "Enter": {
        "keyCode": 13,
        "charCode": 13
    },
    "Tab": {
        "keyCode": 9,
        "charCode": 9
    },
    "Backspace": {
        "keyCode": 8,
        "charCode": 8
    },
    "Delete": {
        "keyCode": 46,
        "charCode": 46
    },
    "ArrowUp": {
        "keyCode": 38,
        "charCode": 0
    },
    "ArrowDown": {
        "keyCode": 40,
        "charCode": 0
    },
    "ArrowLeft": {
        "keyCode": 37,
        "charCode": 0
    },
    "ArrowRight": {
        "keyCode": 39,
        "charCode": 0
    },
    "F1": {
        "keyCode": 112,
        "charCode": 0
    },
    "F2": {
        "keyCode": 113,
        "charCode": 0
    },
    "F3": {
        "keyCode": 114,
        "charCode": 0
    },
    "F4": {
        "keyCode": 115,
        "charCode": 0
    },
    "F5": {
        "keyCode": 116,
        "charCode": 0
    },
    "F6": {
        "keyCode": 117,
        "charCode": 0
    },
    "F7": {
        "keyCode": 118,
        "charCode": 0
    },
    "F8": {
        "keyCode": 119,
        "charCode": 0
    },
    "F9": {
        "keyCode": 120,
        "charCode": 0
    },
    "F10": {
        "keyCode": 121,
        "charCode": 0
    },
    "F11": {
        "keyCode": 122,
        "charCode": 0
    },
    "F12": {
        "keyCode": 123,
        "charCode": 0
    },
    "Escape": {
        "keyCode": 27,
        "charCode": 27
    },
    "ShiftLeft": {
        "keyCode": 16,
        "charCode": 16
    },
    "ShiftRight": {
        "keyCode": 16,
        "charCode": 16
    },
    "ControlLeft": {
        "keyCode": 17,
        "charCode": 17
    },
    "ControlRight": {
        "keyCode": 17,
        "charCode": 17
    },
    "AltLeft": {
        "keyCode": 18,
        "charCode": 18
    },
    "AltRight": {
        "keyCode": 18,
        "charCode": 18
    },
    "MetaLeft": {
        "keyCode": 91,
        "charCode": 91
    },
    "MetaRight": {
        "keyCode": 92,
        "charCode": 92
    },
    "ContextMenu": {
        "keyCode": 93,
        "charCode": 0
    },
    "PageUp": {
        "keyCode": 33,
        "charCode": 0
    },
    "PageDown": {
        "keyCode": 34,
        "charCode": 0
    },
    "End": {
        "keyCode": 35,
        "charCode": 0
    },
    "Home": {
        "keyCode": 36,
        "charCode": 0
    },
    "Insert": {
        "keyCode": 45,
        "charCode": 0
    },
    "NumLock": {
        "keyCode": 144,
        "charCode": 0
    },
    "ScrollLock": {
        "keyCode": 145,
        "charCode": 0
    },
    "Semicolon": {
        "keyCode": 186,
        "charCode": 59
    },
    "Equal": {
        "keyCode": 187,
        "charCode": 61
    },
    "Comma": {
        "keyCode": 188,
        "charCode": 44
    },
    "Minus": {
        "keyCode": 189,
        "charCode": 45
    },
    "Period": {
        "keyCode": 190,
        "charCode": 46
    },
    "Slash": {
        "keyCode": 191,
        "charCode": 47
    },
    "Backquote": {
        "keyCode": 192,
        "charCode": 96
    },
    "BracketLeft": {
        "keyCode": 219,
        "charCode": 91
    },
    "Backslash": {
        "keyCode": 220,
        "charCode": 92
    },
    "BracketRight": {
        "keyCode": 221,
        "charCode": 93
    },
    "Quote": {
        "keyCode": 222,
        "charCode": 39
    }
};