/**
 * @class
 * @memberof earthpixi.Access
 *
 */

export default class KeyboardConfig {
    constructor() {
        this.enableKeyboard = false
        /** @type {number} */
        this.menuMapping = 'Escape'
        
        /** @type {number} */
        this.scanMapping = 'Space'
        
        /** @type {number} */
        this.selectMapping = 'Enter'
    }

    /** @param {KeyboardConfig} config */

    copy(config) {
        console.log('KBD: copying settings: ', config)
        if (!config) return;

        if (config.enableKeyboard !== undefined) {
            this.enableKeyboard = config.enableKeyboard
        }
        if (config.menuMapping !== undefined) {
            this.menuMapping = config.menuMapping
        }
        if (config.scanMapping !== undefined) {
            this.scanMapping = config.scanMapping
        }
        if (config.selectMapping !== undefined) {
            this.selectMapping = config.selectMapping
        }
        earthpixi.Access.keyboardAccess(this.enableKeyboard)
    }

    /** @return {object} */

    toJSON() {
        return {
            enableKeyboard: this.enableKeyboard,
            menuMapping: this.menuMapping,
            scanMapping: this.scanMapping,
            selectMapping: this.selectMapping
        }
    }
}