export default class GuideGrid extends PIXI.Container
{
    constructor()
    {
        super();

        this.dontDestroy = true;

        for (let i = 0; i < 14; i++)
        {
            const line = new PIXI.Graphics();

            line.lineStyle(1, 0x00bfe7, 0.3);
            const xPos = i * 100;

            line.moveTo(xPos, 0);
            line.lineTo(xPos, earthpixi.config.STAGE_HEIGHT);
            this.addChild(line);

            const numTx = new PIXI.Text(xPos.toString(), { fontFamily: "Arial", fontSize: 12, fill: 0x00bfe7, align: "center" });

            numTx.position.set(xPos + 5, 5);
            numTx.alpha = 0.4;
            this.addChild(numTx);
        }

        for (let i = 0; i < 8; i++)
        {
            const col = new PIXI.Graphics();

            col.lineStyle(1, 0x00bfe7, 0.3);
            const yPos = i * 100;

            col.moveTo(0, yPos);
            col.lineTo(earthpixi.config.STAGE_WIDTH, yPos);
            this.addChild(col);

            const numTx = new PIXI.Text(yPos.toString(), { fontFamily: "Arial", fontSize: 12, fill: 0x00bfe7, align: "center" });

            numTx.position.set(5, yPos + 5);
            numTx.alpha = 0.4;
            this.addChild(numTx);
        }
    }
}
