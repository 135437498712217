/**
 * @namespace earthpixi.utils.Phonegap
 */

export default {
    /**
   * Sets immersive mode for Android if running in cordova
   *
   * @static
   * @function earthpixi.utils.Phonegap#immersiveMode
   */
    immersiveMode: () =>
    {
        if (typeof AndroidFullScreen !== "undefined")
        {
            AndroidFullScreen.immersiveMode(() => {}, () => {});
        }
    }

};
