import AccessibilityManager from "./Accessibility";
import AccessibilitySettings from "./settings/AccessibilitySettings";
import AccessibleButton from "./Button";
import TestButton from "./TestButton";
import EyeGazePie from "./eyegaze/Pie";
import EyeGazeTarget from "./eyegaze/Target";

export default {
    TestButton,
    AccessibilitySettings,
    AccessibleButton,
    EyeGazePie,
    EyeGazeTarget,
    AccessibilityManager
};
