/**
 * Methods for handling Gyroscope on a mobile device
 *
 * TODO!
 *
 *
 *
 *
 */

export default {

};
