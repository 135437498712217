/**
 * Created by earth on 23/11/2017.
 * @ignore
 */
export default function (windowWidth, windowHeight, width, height, scale)
{
    // console.log(windowWidth, windowHeight, width, height, scale);

    earthpixi.renderer.resize(width, height);

    // scale
    earthpixi.stage.scale.x
    = earthpixi.stageBackdrop.scale.x
      = earthpixi.stageOverlay.scale.x
        = earthpixi.stage.scale.y
          = earthpixi.stageBackdrop.scale.y
            = earthpixi.stageOverlay.scale.y
              = scale;

    let stageX = 0;

    if (windowWidth < width)
    {
        stageX = -(width - windowWidth) / 2;
    }

    if (earthpixi.config.RENDERER_WIDTH)
    {
        stageX += scale * ((earthpixi.config.RENDERER_WIDTH - earthpixi.config.STAGE_WIDTH) / 2);
    }

    earthpixi.stage.x = earthpixi.stageBackdrop.x = earthpixi.stageOverlay.x = stageX;

    if (windowHeight < height)
    {
        earthpixi.stage.y
      = earthpixi.stageBackdrop.y
        = earthpixi.stageOverlay.y
          = -(height - windowHeight) / 2;
    }
    else
    {
        earthpixi.stage.y
      = earthpixi.stageBackdrop.y
        = earthpixi.stageOverlay.y
          = 0;
    }

    const bounds = earthpixi.utils.Display.getScreenBounds();

    // update anchors etc
    for (let i = 0; i < earthpixi.resizeUpdateItems.length; i++)
    {
        if (earthpixi.resizeUpdateItems[i])
        {
            earthpixi.utils.Display.resizeUpdate(earthpixi.resizeUpdateItems[i], bounds);
        }
    }

    // update masks  (bug with masks /special effect site
    for (let i = 0; i < earthpixi.maskUpdateItems.length; i++)
    {
        earthpixi.utils.Display.updateMask(earthpixi.maskUpdateItems[i]);
    }
}
