export default function (timestamp)
{
    if (earthpixi.needsResize)
    {
        earthpixi.onresize();
        earthpixi.needsResize = null;
    }

    earthpixi.time += timestamp;
    earthpixi.elapsedTime = earthpixi.time * 0.01666; // this is the default time elapse for PIXI ticker

    // render when enough time has passed to meet framerate
    if (earthpixi.time >= earthpixi._fpsDelta)
    {
        earthpixi.stats.begin();

        for (let i = 0; i < earthpixi.updateItems.length; i++)
        {
            earthpixi.updateItems[i].update(earthpixi.time, earthpixi.elapsedTime);
        }

        if (earthpixi.gamepadConnected)
        {
            earthpixi.GamePad.poll();
        }

        earthpixi.time = 0;
        earthpixi.application.render();

        earthpixi.stats.end();
    }
}
