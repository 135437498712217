import { phonegap } from "../utils";
import screenFade from "./screenFade";
/**
 * Set the current screen on the Pixi stage.  This removes any current screen and by default fades in new one.
 *
 * @memberof earthpixi
 * @function setScreen
 * @param {earthpixi.Screen} screen - Screen instance to show
 * @param {boolean} [fadeIn=true] - whether to fade in new screen
 * @param {boolean} [dontCutSounds=false] - whether to cancel all cancel any current playing sounds when new screen added
 */
export default function setScreen(screen, fadeIn = true, dontCutSounds = true)
{
    console.log('running setScreen() ', screen, fadeIn, dontCutSounds)

    if (!dontCutSounds)
    { earthpixi.Audio.stopAllSFX(); }

    console.log('stopped all SFX')

    if (!earthpixi._rendererReady)
    {

        console.log('Renderer not ready')
        earthpixi._rendererCheckDelayed = true;
        earthpixi._onRendererReady = () =>
        {
            console.log('onRenderReady')
            earthpixi.setScreen(screen, fadeIn, dontCutSounds);
        };

        return;
    }

    if (fadeIn)
    {
        console.log('fadingIn')
        // add the screen but fade in, add screen grab of old screen on top to fade out
        // console.log(screen);

        earthpixi.stage.addChild(screen);

        console.log('added child to stage')

        if (earthpixi.currentScreenGrab)
        {

            console.log('has screen grab')

            earthpixi.stage.addChild(earthpixi.currentScreenGrab);

            console.log('added screen grab')

            earthpixi.screenTween = TweenMax.to(earthpixi.currentScreenGrab, earthpixi.config.FADE_TIME, {
                alpha: 0,
                onComplete: screenFade.screenFadeComplete,
            });

            console.log('tweened')
        }
        else
        {
            console.log('no screen grab')
            earthpixi.currentScreen.alpha = 0;
            console.log('will tween')
            earthpixi.screenTween = TweenMax.to(earthpixi.currentScreen, earthpixi.config.FADE_TIME, {
                alpha: 1,
                onComplete: screenFade.screenFadeComplete,
            });

            console.log('screenTweened')
        }
    }
    else
    {
    // just add the screen
        console.log('not fadingIn')

        earthpixi.stage.addChild(screen);

        if (earthpixi.audioAwake)
        {
            // console.log("audio wake");
            /**
             * @event earthpixi.Screen#audiounlock
             */
            screen.emit("audiounlock");
        }

        screen.run();
        screen.emit("run");
    }

    earthpixi.utils.phonegap.immersiveMode();
}

