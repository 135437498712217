/**
 * Eyegaze config settings
 *
 * @class
 * @memberof earthpixi.Access
 *
 */
export default class EyeGazeConfig
{
    constructor()
    {
    /**
     *
     * @type {boolean}
     */
        this.hideMouseCursor = false;
        /**
     *
     * @type {number}
     */
        this.dwellTime = 1;
        /**
     *
     * @type {string}
     */
        this.anim = "default";
        /**
     *
     * @type {boolean}
     */
        this.dwellClickActive = true;
        /**
     *
     * @type {boolean}
     */
        this.directMode = false;
        /**
         *
         * @type {boolean}
         */
        this.allowRepeat = true;
    }

    /**
   *
   * @param config
   */
    copy(config)
    {
        if(config){
            if (config.hideMouseCursor !== undefined)
            { this.hideMouseCursor = config.hideMouseCursor; }
            if (config.dwellTime !== undefined)
            { this.dwellTime = config.dwellTime; }
            if (config.anim !== undefined)
            { this.anim = config.anim; }
            if (config.dwellClickActive !== undefined)
            { this.dwellClickActive = config.dwellClickActive; }
            if (config.directMode !== undefined)
            { this.directMode = config.directMode; }
            if (config.allowRepeat !== undefined)
            { this.allowRepeat = config.allowRepeat; }
        }
    }

    toJSON()
    {
        return {
            hideMouseCursor: this.hideMouseCursor,
            dwellTime: this.dwellTime,
            anim: this.anim,
            dwellClickActive: this.dwellClickActive,
            directMode: this.directMode,
            allowRepeat: this.allowRepeat
        };
    }
}
