/**
 *
 *
 * Loader for adding extra resources without having to change screen, adding to existing resources.
 *
 * Returns normal PIXI Resource Loader
 *
 *```js
 *
 * this.loader = new earthpixi.utils.InScreenLoader(
     [
        { name: "loadbar_tex", url: `earthpixi.config.IMG_ROOT + "loading/hairy_loadbar0@" + earthpixi.resolution + "x.json"},
        { name: "loadbar_hairy", url: earthpixi.config.SPINE_ROOT + "hairy_loadbar/hairy_loadbar.spine" },
        { name: "loadbar_text", url: earthpixi.config.SPINE_ROOT + "hairy_loadbar/loading_text.spine" },
     ],
     this.onLoad,
     this
    );

    this.loader.on("progress", (percent)=>{console.log("loaded "+ percent +"%")} , this);
 *
 * ```
 *
 *
 * @memberof earthpixi.utils
 */
export default class InScreenLoader extends PIXI.utils.EventEmitter
{
    /**
   *
   * @param {Array}assetList
   * @param {function} onLoaded method to call when assets loaded, receives resources array
   * @param {object} [onLoadedScope]
   * @returns PIXI.loaders.Loader
   */
    constructor(assetList, onLoaded, onLoadedScope)
    {
        super();

        this.onLoadedScope = onLoadedScope;
        this.onLoaded = onLoaded;

        this.loader = new PIXI.loaders.Loader();

        for (let i = 0; i < assetList.length; i++)
        {
            this.loader.add(assetList[i].name, assetList[i].url, assetList[i].options);
        }

        this.loader.on("progress", this.onLoadProgress, this);
        this.loader.load(this.loaded.bind(this));

        return this.loader;
    }

    loaded(loader, resources)
    {
    // add to earthpixi.resources
        for (const property in resources)
        {
            if (
                resources.hasOwnProperty(property)
          && !earthpixi.resources.hasOwnProperty(property)
            )
            {
                earthpixi.resources[property] = resources[property];
            }
            else
            {
                console.warn("resource already has:", property);
            }
        }

        if (this.onLoadedScope)
        {
            this.onLoaded.apply(this.onLoadedScope, [resources]);
        }
        else
        {
            this.onLoaded(resources);
        }
        this.emit("loaded", loader, resources);
    }

    onLoadProgress(evt)
    {
    /**
     * Progress event.
     *
     *
     * @event earthpixi.utils.InScreenLoader#progress
     * @param {number} Percentage
     */
        this.emit("progress", evt.progress);
    }
}
