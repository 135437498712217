import gamepads from "gamepads";
import { StandardMapping } from "gamepads";

let _gamepadControllerInstance = null;

/** *
 *
 *  controllers list
 *
 *
 *
 *
 *
 *
 */

export default class GamePadController extends PIXI.utils.EventEmitter
{
    constructor()
    {
        super();
        if (!_gamepadControllerInstance)
        {
            _gamepadControllerInstance = this;
            this.init();
        }

        return _gamepadControllerInstance;
    }

    init()
    {
        this.joystickUpperTol = 0.5;
        this.joystickLowerTolerence = 0.15;

        this.EVENTS = {
            BUTTON_DOWN: "button_down",
            BUTTON_UP: "button_up",
            LEFT_JOYSTICK: "l_stick",
            RIGHT_JOYSTICK: "r_stick",
        };

        this.DIRECTIONS = {
            UP: "UP",
            DOWN: "DOWN",
            LEFT: "LEFT",
            RIGHT: "RIGHT"
        };

        this.BUTTONS = {
            START: "START",
            SELECT: "SELECT",
            HOME: "HOME",
            A: "A",
            B: "B",
            X: "X",
            Y: "Y",
            L1: "L1",
            L2: "L2",
            R1: "R1",
            R2: "R2",
            D_UP: "D_UP",
            D_DOWN: "D_DOWN",
            D_L: "D_L",
            D_R: "D_R"
        };

        this.BUTTON_MAPS = {
            none: {
                0: this.BUTTONS.START, // start button on nimbus
                1: this.BUTTONS.A,
                2: this.BUTTONS.B,
                3: this.BUTTONS.X,
                4: this.BUTTONS.Y,
                5: this.BUTTONS.L1,
                6: this.BUTTONS.R1,
                7: this.BUTTONS.L2,
                8: this.BUTTONS.R2,
            },
            standard: {
                0: this.BUTTONS.A,
                1: this.BUTTONS.B,
                2: this.BUTTONS.X,
                3: this.BUTTONS.Y,
                4: this.BUTTONS.L1,
                5: this.BUTTONS.R1,
                6: this.BUTTONS.L2,
                7: this.BUTTONS.R2,
                8: this.BUTTONS.SELECT,
                9: this.BUTTONS.START,
                13: this.BUTTONS.D_DOWN,
                12: this.BUTTONS.D_UP,
                14: this.BUTTONS.D_L,
                15: this.BUTTONS.D_R,
                16: this.BUTTONS.HOME,
            },
            PS3: {
                3: this.BUTTONS.START,
                0: this.BUTTONS.SELECT,
                16: this.BUTTONS.HOME,
                15: this.BUTTONS.A,
                12: this.BUTTONS.B,
                14: this.BUTTONS.X,
                13: this.BUTTONS.Y,
                10: this.BUTTONS.L1,
                11: this.BUTTONS.R1,
                8: this.BUTTONS.L2,
                9: this.BUTTONS.R2,
                6: this.BUTTONS.D_DOWN,
                4: this.BUTTONS.D_UP,
                7: this.BUTTONS.D_L,
                5: this.BUTTONS.D_R,
            }
        };

        this._onButtonPress = this.onButtonPress.bind(this);
        this._onButtonRelease = this.onButtonRelease.bind(this);
        this._onJoystickMove = this.onJoystickMove.bind(this);

        this._useOnlyStandard = false; // set in case like a nimbus which provides 2 gamepads to chrome;
        this._preferedMap = "standard"; // set in case like a nimbus which provides 2 gamepads to chrome;

        gamepads.start();

        gamepads.addEventListener("connect", (e) =>
        {
            // console.log("Gamepad connected");
            const gamepad = e.gamepad;

            gamepad.dirMap = [0, 0];
            gamepad.flipY = false;

            if (
                gamepad.gamepad.mapping === "standard"
                || gamepad.gamepad.id.toLowerCase().indexOf("xinput") !== -1
            )
            {
                gamepad.buttonMap = this.BUTTON_MAPS.standard;
                this._useOnlyStandard = true;

                // gamepad.setAxisDeadzone(this.joystickLowerTolerence, this.joystickUpperTol);
                // gamepad.setAxisDeadzone(this.joystickLowerTolerence, this.joystickUpperTol);
                //console.log("mapping standard");

                // if (
                //     gamepad.gamepad.id.toLowerCase().indexOf("ps3") !== -1
                //     || gamepad.gamepad.id.toLowerCase().indexOf("xinput") !== -1
                // )
                // {
                //     gamepad.flipY = true;
                // }
            }
            else
            if (
                gamepad.gamepad.id.toLowerCase().indexOf("ps3") !== -1
                || gamepad.gamepad.id.toLowerCase().indexOf("playstation") !== -1
                || gamepad.gamepad.id.toLowerCase().indexOf("sony") !== -1
            )
            {
                gamepad.buttonMap = this.BUTTON_MAPS.PS3;
                gamepad.flipY = true;
                //console.log("mapping ps3");
            }
            else
            {
                gamepad.buttonMap = this.BUTTON_MAPS.none;
                gamepad.flipY = true;
                //console.log("mapping none");
            }

            //console.log(gamepad.flipY);

            // this.buttonMap = ;
            gamepad.addEventListener("buttonpress", this._onButtonPress);
            gamepad.addEventListener("buttonrelease", this._onButtonRelease);
            gamepad.addEventListener("joystickmove", this._onJoystickMove, StandardMapping.Axis.JOYSTICK_LEFT);
        });

        // reset this in case only non statndard controller left after disconnecting one
        gamepads.addEventListener("disconnect", (e) =>
        {
            this._useOnlyStandard = false;
        });
    }

    onButtonPress(e)
    {
        const gamepad = e.gamepad;

        if (gamepad.gamepad.mapping === this._preferedMap)
        {
            this._useOnlyStandard = true;
        }

        // console.log(e);

        if (this._useOnlyStandard && gamepad.gamepad.mapping !== this._preferedMap)
        { return; }

        this.emit(
            this.EVENTS.BUTTON_DOWN,
            gamepad.buttonMap[e.index]
        );
    }

    onButtonRelease(e)
    {
        // console.log(e);
        const gamepad = e.gamepad;

        if (gamepad.gamepad.mapping === this._preferedMap)
        {
            this._useOnlyStandard = true;
        }

        if (this._useOnlyStandard && gamepad.gamepad.mapping !== this._preferedMap)
        { return; }

        this.emit(
            this.EVENTS.BUTTON_UP,
            gamepad.buttonMap[e.index]
        );
    }

    onJoystickMove(e)
    {
        // console.log(e);

        const gamepad = e.gamepad;

        if (gamepad.gamepad.mapping === this._preferedMap)
        {
            this._useOnlyStandard = true;
        }

        if (this._useOnlyStandard && gamepad.gamepad.mapping !== this._preferedMap)
        { return; }

        const valH = e.values[0];
        let valV = e.values[1];

        if (gamepad.flipY)
        {
            valV = valV * -1;
        }

        if (Math.abs(valH) < this.joystickLowerTolerence && Math.abs(valV) < this.joystickLowerTolerence)
        {
            gamepad.dirMap = [0, 0];

            return;
        }

        // console.log(valH, valV);

        // if value has changed from positive to negative since last
        if (
            (gamepad.dirMap[0] === 0 && valH !== 0)
            || (gamepad.dirMap[0] > 0 && valH < 0)
            || (gamepad.dirMap[0] < 0 && valH > 0)
        )
        {
            const val = Math.abs(valH);

            if (val > this.joystickUpperTol)
            {
                gamepad.dirMap[0] = valH;
                // this.emit(this.EVENTS.LEFT_JOYSTICK, valH);
                this.emit(this.EVENTS.BUTTON_DOWN,
                    valH > 0 ? this.BUTTONS.D_R : this.BUTTONS.D_L
                );
            }
        }

        if (
            (gamepad.dirMap[1] === 0 && valV !== 0)
            || (gamepad.dirMap[1] > 0 && valV < 0)
            || (gamepad.dirMap[1] < 0 && valV > 0)
        )
        {
            const val = Math.abs(valV);

            if (val > this.joystickUpperTol)
            {
                gamepad.dirMap[1] = valV;
                this.emit(this.EVENTS.BUTTON_DOWN,
                    valV > 0 ? this.BUTTONS.D_DOWN : this.BUTTONS.D_UP
                );
            }
        }
    }

    reset()
    {

    }
}
