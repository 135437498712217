import cleanUp from "../core/cleanUp";
import Screen from "./Screen";
/**
 *
 * Base Loading Screen with loading bar, built on PIXI Loader.
 * Creates and displays a screen when assets are loaded.
 *
 * Extend to this to make your own loading screen.
 *
 * Looks at earthpixi.globalAssetsList if you've added to that.
 *
 * @class
 * @extends {earthpixi.Screen}
 * @memberof earthpixi
 *
 */
export default class LoadingScreen extends Screen
{
    /**
   *
   * @constructor
   * @param {Array} assetList Array of assets in PIXI loader format
   * @param {Class<earthpixi.Screen>} screenToLoad Screen class to load once assets for it are loaded
   * @param {boolean} [fadeIn=true]
   * @param {boolean} [clearImgCache=true]
   * @param {boolean} [clearAudioCache=true]
   * @param {boolean} [stopAudio=true]
   */
    constructor(assetList, screenToLoad, fadeIn = true, clearImgCache = true, clearAudioCache = true, stopAudio = true)
    {
        super(true);

        if (clearImgCache)
        {
            cleanUp.safeCleanUp();
        }

        this.loadBar = new PIXI.Graphics();
        this.loadBar.beginFill(0xffffff, 1);
        if (earthpixi.utils.Display.maskOverride)
        {
            this.loadBar.drawRect(0, 0, earthpixi.utils.Display.maskOverride.width, 10);
            this.loadBar.x = earthpixi.utils.Display.maskOverride.x;
        }
        else
        {
            this.loadBar.drawRect(0, 0, earthpixi.config.STAGE_WIDTH, 10);
        }

        this.loadBar.y = earthpixi.config.STAGE_HEIGHT - 10;
        this.loadBar.scale.set(0, 1);
        this.addChild(this.loadBar);
        earthpixi.utils.Display.anchor.bottom(this.loadBar, 0);

        this.assetsList = assetList;
        this.screenToLoad = screenToLoad;
        this.fadeIn = fadeIn;
        this.stopAudio = stopAudio;

        // stop any sound effects from previous screen?

        if (stopAudio || clearAudioCache)
        {
            earthpixi.Audio.stopAllSFX(clearAudioCache);
        }

        // remove any duplicates in the globalAssetList
        const removeDuplicates = (accumulator, currentItem, currentIndex, array) =>
        {
            for (let i = 0; i < accumulator.length; i++)
            {
                if (
                    accumulator[i].name === currentItem.name
                )
                {
                    console.warn("Duplicate asset removed", currentItem);

                    return accumulator;
                }
            }

            accumulator.push(currentItem);

            return accumulator;
        };

        // any global assets like fonts you want loaded on all screens?
        this.assetsList = this.assetsList.concat(earthpixi.globalAssetsList);

        this.assetsList = this.assetsList.reduce(removeDuplicates, []);

        // console.log(this.assetsList);
    }

    /**
   * @private
   */
    run()
    {
        PIXI.loader.reset();

        for (let i = 0; i < this.assetsList.length; i++)
        {
            let url = this.assetsList[i].url;

            // fix for asset list being loaded before getRenderer completing
            if(earthpixi._rendererCheckDelayed)
            {
                if (earthpixi.resolution === 2)
                {
                    url = url.replace("@1x", "@2x");
                    url = url.replace("1x.", "2x.");
                }
                if (earthpixi.resolution === 1)
                {
                    url = url.replace("@2x", "@1x");
                    url = url.replace("2x.", "1x.");
                }
            }

            PIXI.loader.add(this.assetsList[i].name, url, this.assetsList[i].options);
        }

        PIXI.loader.on("progress", this.onLoadProgress, this);
        PIXI.loader.load(this.onLoaded.bind(this));
    }

    cancel()
    {
        PIXI.loader.off("progress", this.onLoaded);
        PIXI.loader.reset();
    }

    /**
   * @private
   * @param loader
   * @param resources
   */
    onLoaded(loader, resources)
    {
        PIXI.loader.off("progress");
        earthpixi.resources = resources;

        /**
     * Loading complete event.
     *
     * @event earthpixi.LoadingScreen#loaded
     */
        this.emit("loaded");

        earthpixi.setScreen(new this.screenToLoad(), this.fadeIn, !this.stopAudio);
    }

    /**
   * @private
   * @param e
   */
    onLoadProgress(e)
    {
        this.loadBar.scale.x = e.progress / 100;
    }
}
