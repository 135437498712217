let _coverTex;

/**
 * @class
 * @memberof earthpixi.Access
 * @return {PIXI.Sprite}
 * @extends {PIXI.Sprite}
 */
export default class Button extends PIXI.Sprite
{
    /**
     *
     * @param {PIXI.Texture} staticTex
     * @param {PIXI.Texture} hoverTex
     * @param {string} accessName
     * @param {boolean} [repeatable=false]
     * @param {boolean} [toggleable=false]
     * @param {number} [padding=0]
     *
     */
    constructor(staticTex, hoverTex, accessName, repeatable = false, toggleable = false, padding = 0)
    {
        super(staticTex);

        // console.log('Will render button with static texture of...', staticTex)
        // console.log(this.width);
        // override Sprite anchor for custom one so we can listen for change
        this._anchor = new PIXI.ObservablePoint(this.anchorChange, this, 0, 0);

        this.staticTex = staticTex;
        this.hoverTex = hoverTex;
        this.customDwellAnim = null;
        this.repeat = repeatable;
        this.isDown = false;
        this.togglable = toggleable;
        this.padding = padding;
        this.tabIndex = -1;
        /**
         *
         * @type {boolean}
         * @default true
         */
        this.toggled = false;
        this.accessible = true;
        this.interactive = true;
        this.directModePositionOffset = { x: 0, y: 0 };
        this.buttonMode = true;
        /**
         * @type {boolean}
         * @default false
         */
        this.onlyUseInDirectMode = false;
        /**
         *
         * @type {PIXI.Sprite | undefined}
         * @default null
         */
        this.icon = null;
        this.accessibleHint = accessName;

        this.eyeGazeAble = true;
        this.eyeGazeActive = false;

        this.switchable = true;

        this.clickable = true;

        // listen for all events, want to replace on with our own so do it on the Sprite proto

        // PIXI.Sprite.prototype.on.call(this, "pointerover", this.onPointerOver, this);
        // PIXI.Sprite.prototype.on.call(this, "pointerout", this.onPointerOut, this);
        PIXI.Sprite.prototype.on.call(this, "pointerover", this.beforePointerOver, this);
        PIXI.Sprite.prototype.on.call(this, "pointerout", this.beforePointerOut, this);

        PIXI.Sprite.prototype.on.call(this, "pointerdown", this.onPointerDown, this);
        PIXI.Sprite.prototype.on.call(this, "pointerup", this.onPointerUp, this);
        // PIXI.Sprite.prototype.on.call(this, "pointerupoutside", this.onPointerOut, this);
        PIXI.Sprite.prototype.on.call(this, "added", this.onAdded, this);
        PIXI.Sprite.prototype.on.call(this, "removed", this.onRemoved, this);

        this.addHitCover();

        this.userEvents = {};
    }

    indicator = null
    dwellFill = null
    dwellTimeout = null
    startDwellTimeout = null
    pointerOverStartTime = 0
    pointerOutTimeout

    static get CoverTex()
    {
        if (!_coverTex)
        {
            _coverTex = new PIXI.Graphics();
            _coverTex.beginFill(0xFFFFFF, 1);
            _coverTex.drawRect(0, 0, 512, 512);
            _coverTex.endFill();
            _coverTex = earthpixi.renderer.generateTexture(_coverTex, PIXI.settings.SCALE_MODE, earthpixi.resolution);
        }

        return _coverTex;
    }

    /**
     * @private
     */
    addHitCover()
    {
        if (this.cover)
        {
            this.removeChild(this.cover);
            this.cover.destroy();
        }

        const pad = earthpixi.Access.settings.eyeGazeActive ? this.padding : 0;

        // console.log(pad);
        this.cover = new PIXI.Sprite(Button.CoverTex);
        this.cover.width = (this.width / this.scale.x) + (pad * 2);
        this.cover.height = (this.height / this.scale.y) + (pad * 2);
        this.cover.position.set(-pad, -pad);
        // this.cover.interactive = true;
        this.cover.alpha = 0;
        // this.hitArea = new PIXI.Rectangle(-pad, -pad, this.cover.width, this.cover.height);
        this.addChild(this.cover);

        this.anchorChange();
    }

    /**
     * Adds an icon sprite into button and centers it, call again to set new icon
     *
     * @param {PIXI.Texture} texture
     */
    addIcon(texture) {
        if (this.icon) this.icon.destroy();

        this.icon = new PIXI.Sprite(texture);
        this.icon.anchor.set(0.5);
        this.icon.x = this.width / 2;
        this.icon.y = this.height / 2;
        this.addChild(this.icon);
    }

    /**
     * Add a text to this button and center it, call again to set new text
     *
     * @param {string} text text size
     * @param {number} size text size
     * @param {string} font bitmap font name (needs to be loaded in texture cache)
     * @param {string} [props] any other font style properties wanted
     *
     */
    addText(text, size, font, props) {
        const style = { font: `${size}px ${font}`, tint: 0xFFFFFF, align: "center" };

        if (props)
        {
            Object.assign(style, props);
        }

        if (this._text) this._text.destroy();
        this._text = new PIXI.extras.BitmapText("", style);

        this._text.maxWidth = this.width - 10;
        // txt.anchor.set(0.5);

        this._text.text = text;

        this._text.x = (this.width - this._text.width) / 2;
        this._text.y = (this.height - this._text.textHeight) / 2;

        this.addChild(this._text);
    }

    /**
     * Use to add event to button, maps the event to work with eyegaze/switch
     *
     *  ```js
     *  myButton.addEvent("select ", this.onClick, this);
     *  myButton.on("over", this.onOver, this);
     *  myButton.on("out", this.out, this);
     *  myButton.addEvent("toggleOff", this.toggledOff, this);
     *  ```
     * @param {InteractionEventTypes} event
     * @param {Function} fn
     * @param {any} [context]
     * @param [data]
     * @param [data2]
     */
    addEvent(event, fn, context, data, data2)
    {
        // console.log(eventStr);

        if (event === "click" || event === "pointerdown")
        {
            event = "select";
            console.warn("use 'select' instead of 'click/pointerdown");
        }

        this.userEvents[event] = { method: fn, scope: context || this, data, data2 };
    }

    on(event, fn, context, data, data2)
    // on(eventStr, method, scope, data, data2)
    {
        // console.log(eventStr);

        if (event === "click" || event === "pointerdown")
        {
            event = "select";
            console.warn("use 'select' instead of 'click/pointerdown");
        }

        this.userEvents[event] = { method: fn, scope: context || this, data, data2 };
    }

    /**
     *
     * Manually set the hit area for mouse/ eyegaze
     *
     * @param {PIXI.Rectangle | PIXI.Circle} hitArea
     */
    setHitArea(hitArea)
    {
        // console.log("manual hit", hitArea);
        this._manualHitArea = hitArea;
        this.hitArea = hitArea;
    }

    onAdded()
    {
        earthpixi.Access.add(this);
        this.addChild(this.cover);
    }

    onRemoved()
    {
        earthpixi.Access.remove(this);
    }

    /**
     *
     * @param {number} scale
     * @param {number} [width]
     * @param {number} [height]
     */
    setScale(scale, width, height)
    {
        // console.log('I am setting scale!! ', scale, '/',  width, '/', height)
        if (scale)
        {
            this.scale.set(scale);

            this.saveScale = { x: this.scale.x, y: this.scale.y };

            // reset hit size
            this.addHitCover();

            return;
        }
        // else {
        //     console.log('will not be settings scale!!')
        // }

        if (width)
        {
            // console.log('has width: ', width)
            this.width = width;
        }

        if (height)
        {
            // console.log('has height: ', height)
            this.height = height;
        }

        // reset hit size
        this.addHitCover();
    }

    anchorChange()
    {
        // console.log('anchor changing!')
        this.cover.anchor.set(this.anchor.x, this.anchor.y);

        // console.log("anchor change", this.padding, earthpixi.Access.settings.eyeGazeActive);

        const pad = earthpixi.Access.settings.eyeGazeActive ? this.padding : 0;

        const hitX = -pad - (this.anchor.x * this.cover.width);
        const hitY = -pad - (this.anchor.y * this.cover.height);

        // this.hitArea = new PIXI.Rectangle(hitX, hitY, this.cover.width, this.cover.height);

        this._onAnchorUpdate();
    }

    addIndicator(){}

    checkBeforePointerOver(evt){
        // console.log('TEST: starting pointer over')
        this.pointerOverStartTime = Date.now()

        // console.log('TEST: updated pointer over time: ', this.pointerOverStartTime)

        this.startDwellTimeout = setTimeout(() => {
            // console.log(('TEST: should/will start pointer over'))
            this.onPointerOver(evt)
        }, 100)
    }

    beforePointerOver(evt){
        if(this.pointerOutTimeout !== null){
            clearTimeout(this.pointerOutTimeout)
        }
        this.addIndicator()
        this.pointerOverStartTime === 0 && this.checkBeforePointerOver()
    }

    onPointerOver(evt)
    {
        // console.log('pointer over!: ', earthpixi.renderer.plugins.interaction.mouse.global, evt)
        if (!earthpixi.Access.settings.eyeGazeConfig.directMode && this.onlyUseInDirectMode)
        {
            // console.log('CASE A: Not enabled')
            return;
        }
        
        if (!this.clickable)
        {
            // console.log('CASE B: Not clickable')
            return;
        }
        
        if (!this.togglable)
        {
            // console.log('CASE B: Not clickable')
            this.texture = this.hoverTex;
            this.isDown = true;
        }
        
        if (this.userEvents.over)
        {
            // console.log('CASE D: user event over!')
            this.userEvents.over.method.apply(this.userEvents.over.scope, [evt, this.userEvents.over.data, this.userEvents.over.data2]);
        }
    }

    beforePointerOut(evt){
        // console.log('TEST: on before-pointer-out')
        this.pointerOutTimeout = setTimeout(() => {
            // this.startPointerOutEvent(evt)
            this.onPointerOut(evt)
        }, 300)
    }

    onPointerOut(evt)
    {
        // console.log('pointer out')
        // console.log('pointer out: ', earthpixi.renderer.plugins.interaction.mouse.global, evt)

        // add temporary div to show where pointer left
        // evt && this.addOutDiv()

        if (!this.clickable)
        {
            return;
        }

        if (!this.togglable && this.staticTex)
        {
            // console.log(this.texture, this.staticTex);
            this.texture = this.staticTex;
            this.isDown = false;
        }
        if (this.userEvents.out)
        {
            // console.log(this.userEvents.out);
            this.userEvents.out.method.apply(this.userEvents.out.scope, [evt, this.userEvents.out.data, this.userEvents.out.data2]);
        }
        //
        if (this.saveScale)
        {
            this.scale.set(this.saveScale.x, this.saveScale.y);
        }

        this.eyeGazeActive = false;
        if (this.dwellAnim)
        {
            this.dwellAnim.hide();
        }
    }

    addOutDiv(){
        // console.log('adding out div')
        let outDiv = document.createElement('div')
        outDiv.style = `
            background: purple; 
            width: 100px; 
            height: 100px; 
            border-radius: 100px;
            position: fixed;
            z-index: 9999;
            left: ${earthpixi.renderer.plugins.interaction.mouse.global.x}px;
            top: ${earthpixi.renderer.plugins.interaction.mouse.global.y}px;
        `
        document.body.appendChild(outDiv)
    }

    onPointerDown(evt)
    {
        if (!this.clickable)
        {
            return;
        }

        if (evt && evt.stopPropagation)
        {
            evt.stopPropagation();
        }

        if (!this.togglable)
        {
            this.texture = this.hoverTex;
            this.isDown = true;
        }

        if (this.userEvents.down)
        {
            this.userEvents.down.method.apply(this.userEvents.down.scope, [evt, this.userEvents.down.data, this.userEvents.down.data2]);
        }

        this.eyeGazeActive = false;
        if (this.dwellAnim)
        {
            // console.log("hide");
            this.dwellAnim.hide();
        }

        earthpixi.Access.reset();
    }

    onPointerUp(evt)
    {
        if (evt.stopPropagation)
        {
            evt.stopPropagation();
        }

        if (!this.clickable)
        {
            return;
        }

        if (this.saveScale)
        {
            this.scale.set(this.saveScale.x, this.saveScale.y);
        }

        if (!this.togglable)
        {
            this.texture = this.staticTex;
            this.isDown = false;
        }

        this.select(evt);
    }

    onPointerSelect(evt)
    {
        if (evt && evt.stopPropagation)
        {
            evt.stopPropagation();
        }

        this.select(evt);
    }

    select(evt)
    {
        if (this.userEvents.select)
        {
            if (earthpixi.resolution === 2)
            {
                this.addParticle(this);
            }

            this.toggle();

            evt.target = this;

            if (this.togglable)
            {
                if (this.toggled)
                {
                    this.userEvents.select.method.apply(this.userEvents.select.scope, [evt, this.userEvents.select.data, this.userEvents.select.data2]);
                }
            }
            else
            {
                this.userEvents.select.method.apply(this.userEvents.select.scope, [evt, this.userEvents.select.data, this.userEvents.select.data2]);
            }
        }
    }

    /**
     *
     * @param bool
     */
    toggle(bool)
    {
        if (this.togglable)
        {
            if (bool !== undefined)
            {
                this.toggled = bool;
            }
            else
            {
                this.toggled = !this.toggled;
            }

            this.texture = this.toggled ? this.hoverTex : this.staticTex;
            this.isDown = this.toggled;
            if (this.saveScale)
            {
                this.scale.set(this.saveScale.x, this.saveScale.y);
            }

            if (!this.toggled && this.userEvents.toggleOff)
            {
                // console.log("toggle off");
                this.userEvents.toggleOff.method.apply(
                    this.userEvents.toggleOff.scope,
                    [null, this.userEvents.toggleOff.data, this.userEvents.toggleOff.data2]
                );
            }
        }
    }

    /**
     *@private
     */
    addParticle()
    {
        if (earthpixi.Access.clickSound)
        {
            earthpixi.Audio.playSFX(earthpixi.Access.clickSound);
        }

        if (earthpixi.Access.settings.clickAnim)
        {
            // particle system stuff
            if (this.particleContainer)
            {
                this.removeChild(this.particleContainer);
            }
            this.particleContainer = new PIXI.Container();

            earthpixi.stageOverlay.addChild(this.particleContainer);

            const globPos = this.toGlobal(new PIXI.Point(
                (this.width / 2) - (this.anchor.x * this.width),
                (this.height / 2) - (this.anchor.y * this.height)
            ));
            const overLayLocal = earthpixi.stageOverlay.toLocal(globPos);

            this.particleContainer.position.set(overLayLocal.x, overLayLocal.y);

            const emitter = this.createParticleBurst(this.particleContainer);

            emitter.playOnceAndDestroy();
        }
    }

    /**
     * @private
     * @param parent
     * @return {PIXI.particles.Emitter}
     */
    createParticleBurst(parent)
    {
        const config = {
            alpha: {
                start: 0.5,
                end: 1
            },
            scale: {
                start: 0.3,
                end: 0.1,
                minimumScaleMultiplier: 0.1
            },
            color: {
                start: "#ffffff",
                end: "#ffffff"
            },
            speed: {
                start: 150,
                end: 300
            },
            acceleration: {
                x: 10,
                y: 10
            },
            startRotation: {
                min: 0,
                max: 0
            },
            noRotation: true,
            rotationSpeed: {
                min: 100,
                max: 100
            },
            lifetime: {
                min: 0.5,
                max: 0.8
            },
            blendMode: "normal",
            frequency: 0.3,
            emitterLifetime: 0.41,
            maxParticles: 1000,
            pos: {
                x: 0,
                y: 0
            },
            addAtBack: false,
            spawnType: "burst",
            particlesPerWave: 8,
            particleSpacing: 45,
            angleStart: 0
        };

        // white circle
        const grp = new PIXI.Graphics();

        grp.beginFill(0xffffff, 1);
        grp.drawRect(0, 0, 16, 16);

        if (!earthpixi.Access.accessButtonParticleTex)
        {
            earthpixi.Access.accessButtonParticleTex = earthpixi.renderer.generateTexture(grp, PIXI.settings.SCALE_MODE, earthpixi.resolution);
        }

        const emitter = new PIXI.particles.Emitter(
            parent,
            [earthpixi.Access.accessButtonParticleTex],
            config
        );

        return emitter;
    }

    /**
     *
     * @param {PIXI.DestroyOptions | boolean} [options]
     */
    destroy(options)
    {
        PIXI.Sprite.prototype.off.call(this, "pointerover");
        PIXI.Sprite.prototype.off.call(this, "pointerout");
        PIXI.Sprite.prototype.off.call(this, "pointerdown");
        PIXI.Sprite.prototype.off.call(this, "pointerup");
        PIXI.Sprite.prototype.off.call(this, "pointerupoutside");
        PIXI.Sprite.prototype.off.call(this, "added");
        PIXI.Sprite.prototype.off.call(this, "removed");

        super.destroy(options);
    }
}
