export default class AssetList extends Array
{
    constructor()
    {
        super();
    }

    push()
    {
        const newItems = [];

        for (const i in arguments)
        {
            const item = arguments[i];

            if (this.checkForDupes(item))
            {
                newItems.push(item);
            }
            else
            {
                //console.warn("asset already added to list");
            }
        }

        for (const i in newItems)
        {
            super.push(newItems[i]);
        }
    }

    checkForDupes(item)
    {
        for (let i = 0; i < this.length; i++)
        {
            if (item.url === this[i].url)
            {
                return false;
            }
        }

        return true;
    }
}
