import DwellAnimation from "./DwellAnimation";

export default class Pie extends DwellAnimation
{
    constructor(radius)
    {
        super(radius);

        this.type = "pie";
        this.interactive = false;
        this.interactiveChildren = false;
        this._percentage = 0;
        this.rad = radius;
        this.config = {
            pieTint1: 0xFF4868,
            pieTint2: 0xFF5D4A,
            pieTint3: 0xFBB03B,
            pieTint4: 0x88EF7D
        };

        this.on("removed", function ()
        {
            if (this.mainTween != null)
            {
                earthpixi.Tween.kill(this.mainTween);
            }
        }, this);

        // create textures
        const piePiece = new PIXI.Graphics();

        piePiece.beginFill(0xFFFFFF, 1);
        // piePiece.lineStyle(2,0xFFFFFF,1);
        piePiece.moveTo(this.rad, 0);
        piePiece.arc(this.rad, this.rad, this.rad, 270 * PIXI.DEG_TO_RAD, 0, false);
        piePiece.lineTo(this.rad, this.rad);
        piePiece.lineTo(this.rad, 0);
        piePiece.endFill();
        this.pieTex = earthpixi.renderer.generateTexture(piePiece, PIXI.settings.SCALE_MODE, earthpixi.resolution);

        const bgCircle = new PIXI.Graphics();

        bgCircle.beginFill(0xFFFFFF, 1);
        bgCircle.drawCircle(this.rad, this.rad, this.rad);
        this.circleTex = earthpixi.renderer.generateTexture(bgCircle, PIXI.settings.SCALE_MODE, earthpixi.resolution);

        this.buildPie();
    }

    buildPie()
    {
        if (this.bg)
        {
            this.bg.destroy();
            this.bg2.destroy();
            this.tr.destroy();
            this.br.destroy();
            this.bl.destroy();
            this.bl.destroy();
        }

        const bgCircle = this.bg = new PIXI.Sprite(this.circleTex);

        bgCircle.tint = this.config.pieTint1;

        const piePieceTopLeft = this.tl = new PIXI.Sprite(this.pieTex);

        piePieceTopLeft.tint = this.config.pieTint1;
        piePieceTopLeft.anchor.set(0, 1);
        piePieceTopLeft.position.set(this.rad, this.rad);
        piePieceTopLeft.rotation = piePieceTopLeft.origRotation = 270 * PIXI.DEG_TO_RAD;

        const piePieceTopRight = this.tr = new PIXI.Sprite(this.pieTex);

        piePieceTopRight.tint = this.config.pieTint1;
        piePieceTopRight.anchor.set(0, 1);
        piePieceTopRight.position.set(this.rad, this.rad);

        const piePieceBottomRight = this.br = new PIXI.Sprite(this.pieTex);

        piePieceBottomRight.tint = this.config.pieTint1;
        piePieceBottomRight.anchor.set(0, 1);
        piePieceBottomRight.position.set(this.rad, this.rad);
        piePieceBottomRight.rotation = piePieceBottomRight.origRotation = 90 * PIXI.DEG_TO_RAD;

        const piePieceBottomLeft = this.bl = new PIXI.Sprite(this.pieTex);

        piePieceBottomLeft.tint = this.config.pieTint1;
        piePieceBottomLeft.anchor.set(0, 1);
        piePieceBottomLeft.position.set(this.rad, this.rad);
        piePieceBottomLeft.rotation = piePieceBottomLeft.origRotation = 180 * PIXI.DEG_TO_RAD;

        const piePieceTopRightMask = this.bg2 = new PIXI.Sprite(this.pieTex);

        piePieceTopRightMask.tint = this.config.pieTint1;
        piePieceTopRightMask.anchor.set(0, 1);
        piePieceTopRightMask.position.set(this.rad, this.rad);

        this.addChild(bgCircle);
        bgCircle.addChild(piePieceTopLeft);
        bgCircle.addChild(piePieceTopRight);
        bgCircle.addChild(piePieceBottomRight);
        bgCircle.addChild(piePieceBottomLeft);
        bgCircle.addChild(piePieceTopRightMask);
    }

    show(button, direct, delay = 0)
    {
        super.show(button, direct);

        // reset values
        this.bl.visible = true;
        this.br.visible = true;
        this.tr.visible = true;
        this.tr.position.set(this.rad, this.rad);
        this.bl.position.set(this.rad, this.rad);

        // this.calcScale(button);

        earthpixi.Tween.kill(this.mainTween);
        earthpixi.Tween.kill(this.colorTween);

        earthpixi.Tween.kill(this);
        earthpixi.Tween.kill(this.bg);
        earthpixi.Tween.kill(this.bg2);

        this.perc = 0;
        this.bg.tint = this.config.pieTint2;
        this.bg2.tint = this.config.pieTint2;

        this.mainTween = this.mainTween = earthpixi.Tween.to(this, earthpixi.Access.settings.eyeGazeConfig.dwellTime,
            { delay, perc: 100, ease: Linear.easeNone, onComplete: this.onSelect, onCompleteParams: [button], onCompleteScope: this }
        );

        this.colorTween = earthpixi.Tween.to([this.bg, this.bg2], earthpixi.Access.settings.eyeGazeConfig.dwellTime, {
            delay,
            colorProps: {
                tint: this.config.pieTint4,
                format: "number"
            }, ease: Linear.easeNone
        });
    }

    hide()
    {
        if (this.mainTween != null)
        {
            earthpixi.Tween.kill(this.mainTween);
            earthpixi.Tween.kill(this.colorTween);
            this.perc = 0;
        }

        earthpixi.Tween.kill(this);
        earthpixi.Tween.kill(this.bg);
        earthpixi.Tween.kill(this.bg2);

        super.hide();
    }

    get perc()
    {
        return this._percentage;
    }

    set perc(value)
    {
        this._percentage = value;
        const pie = this;

        if (value <= 25)
        {
            // set rotation of top left pie piece, others remain in place
            pie.tr.rotation = PIXI.DEG_TO_RAD * (90 * (value / 25));
            pie.br.rotation = pie.br.origRotation;
            pie.bl.rotation = pie.bl.origRotation;
            pie.tl.rotation = pie.tl.origRotation;
            pie.bg2.visible = false;

            return;
        }
        else if (value <= 50)
        {
            // set rotation of top left pie piece, others remain in place
            pie.tr.rotation = PIXI.DEG_TO_RAD * 180;
            pie.br.rotation = PIXI.DEG_TO_RAD * (180 * (value / 50));
            pie.bl.rotation = pie.bl.origRotation;
            pie.tl.rotation = pie.tl.origRotation;
            pie.bg2.visible = false;

            return;
        }
        else if (value <= 75)
        {
            // set rotation of top left pie piece, others remain in place
            pie.tr.rotation = PIXI.DEG_TO_RAD * 270;
            pie.br.rotation = PIXI.DEG_TO_RAD * 270;
            pie.bl.rotation = PIXI.DEG_TO_RAD * (270 * (value / 75));
            pie.tl.rotation = pie.tl.origRotation;
            pie.bg2.visible = false;

            return;
        }
        else if (value <= 100)
        {
            // set rotation of top left pie piece, others remain in place
            pie.tr.rotation = PIXI.DEG_TO_RAD * 0;
            pie.br.rotation = PIXI.DEG_TO_RAD * 0;
            pie.bl.rotation = PIXI.DEG_TO_RAD * 0;
            pie.tl.rotation = PIXI.DEG_TO_RAD * (360 * (value / 100));
            pie.bg2.visible = true;
            pie.tr.x = this.rad - (0.5 * earthpixi.resolution);
            pie.tr.y = this.rad + (0.5 * earthpixi.resolution);
            pie.bl.visible = false;
            pie.br.visible = false;
            pie.tr.visible = false;

            return;
        }
    }
}
