/**
 *
 * Useful filter for spine animations you want to make semi transparent, without seeing parts overlap
 *
 */

// PIXI built this in

export default class AlphaFilter extends PIXI.filters.AlphaFilter
{
    constructor(alpha)
    {
        super(alpha);
    }
}

// export default class AlphaFilter extends PIXI.Filter {
//   /**
//    *
//    * @param alpha 0-1
//    */
//   constructor(alpha){
//
//     let fragmentShader = [
//       'precision mediump float;',
//       '',
//       'varying vec2 vTextureCoord;',
//       'uniform sampler2D uSampler;',
//       '',
//       'void main(void)',
//       '{',
//       '      gl_FragColor = texture2D(uSampler, vTextureCoord);',
//       '      gl_FragColor.r *= '+alpha+';',
//       '      gl_FragColor.g *= '+alpha+';',
//       '      gl_FragColor.b *= '+alpha+';',
//       '      gl_FragColor.a *= '+alpha+';',
//       '}'
//     ].join('\n');
//
//     super(null, fragmentShader, null);
//   }
// }
