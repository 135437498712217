let _keyboardControllerInstance = null;

/**
 * Listen for Keyboard inputs,  listeners are reset when new screen loaded
 *
 * Examples:
 *
 * ```js
 * //start listening for the space key
 * earthpixi.Keyboard.addKey(
 *    32,
 *    this.onSpaceKeyDown,
 *    ()=>{console.log("key up")},
 *    ()=>{console.log("key press")},
 *    null,
 *    this
 * )
 * ```
 *
 * @namespace earthpixi.Keyboard
 *
 *
 */
export default class KeyboardController
{
    constructor()
    {
        if (!_keyboardControllerInstance)
        {
            _keyboardControllerInstance = this;
            this.init();
        }

        return _keyboardControllerInstance;
    }

    static get Instance()
    {
        if (!_keyboardControllerInstance)
        {
            _keyboardControllerInstance = new earthpixi.KeyBoardController();
        }

        return _keyboardControllerInstance;
    }

    init()
    {
        this.keys = {};
        this.keysByChar = {};

        window.addEventListener("keydown", this.onKeyDown.bind(this));
        window.addEventListener("keyup", this.onKeyUp.bind(this));
        window.addEventListener("keypress", this.onKeyPress.bind(this));
        // if (window !== window.top)
        // {
        //     window.top.addEventListener("keydown", this.onKeyDown.bind(this));
        //     window.top.addEventListener("keyup", this.onKeyUp.bind(this));
        //     window.top.addEventListener("keypress", this.onKeyPress.bind(this));
        // }

        /**
         *
         * Look up description for a keyCode
         *
         * ```js
         * console.log(earthpixi.Keyboard.KeyCodesDict[13])
         * //returns "enter"
         * ```
         *
         *
         *
         * @memberof earthpixi.Keyboard
         *
         */
        this.KeyCodesDict = {
            3: "break",
            8: "backspace / delete",
            9: "tab",
            12: "clear",
            13: "enter",
            16: "shift",
            17: "ctrl ",
            18: "alt",
            19: "pause/break",
            22: "space",
            20: "capslock",
            27: "escape",
            32: " ",
            33: "page up",
            34: "page down",
            35: "end",
            36: "home ",
            37: "left arrow",
            38: "up arrow",
            39: "right arrow",
            40: "down arrow",
            41: "select",
            42: "print",
            43: "execute",
            44: "Print Screen",
            45: "insert ",
            46: "delete",
            48: "0",
            49: "1",
            50: "2",
            51: "3",
            52: "4",
            53: "5",
            54: "6",
            55: "7",
            56: "8",
            57: "9",
            58: ":",
            59: "semicolon (firefox), equals",
            60: "<",
            61: "equals (firefox)",
            63: "ß",
            64: "@ (firefox)",
            65: "a",
            66: "b",
            67: "c",
            68: "d",
            69: "e",
            70: "f",
            71: "g",
            72: "h",
            73: "i",
            74: "j",
            75: "k",
            76: "l",
            77: "m",
            78: "n",
            79: "o",
            80: "p",
            81: "q",
            82: "r",
            83: "s",
            84: "t",
            85: "u",
            86: "v",
            87: "w",
            88: "x",
            89: "y",
            90: "z",
            91: "Windows Key / Left ⌘ / Chromebook Search key",
            92: "right window key ",
            93: "Windows Menu / Right ⌘",
            96: "numpad 0 ",
            97: "numpad 1 ",
            98: "numpad 2 ",
            99: "numpad 3 ",
            100: "numpad 4 ",
            101: "numpad 5 ",
            102: "numpad 6 ",
            103: "numpad 7 ",
            104: "numpad 8 ",
            105: "numpad 9 ",
            106: "multiply ",
            107: "add",
            108: "numpad period (firefox)",
            109: "subtract ",
            110: "decimal point",
            111: "divide ",
            112: "f1 ",
            113: "f2 ",
            114: "f3 ",
            115: "f4 ",
            116: "f5 ",
            117: "f6 ",
            118: "f7 ",
            119: "f8 ",
            120: "f9 ",
            121: "f10",
            122: "f11",
            123: "f12",
            124: "f13",
            125: "f14",
            126: "f15",
            127: "f16",
            128: "f17",
            129: "f18",
            130: "f19",
            131: "f20",
            132: "f21",
            133: "f22",
            134: "f23",
            135: "f24",
            144: "num lock ",
            145: "scroll lock",
            160: "^",
            161: "!",
            163: "#",
            164: "$",
            165: "ù",
            166: "page backward",
            167: "page forward",
            169: "closing paren (AZERTY)",
            170: "*",
            171: "~ + * key",
            173: "-",
            174: "decrease volume level",
            175: "increase volume level",
            176: "next",
            177: "previous",
            178: "stop",
            179: "play/pause",
            180: "e-mail",
            181: "mute/unmute (firefox)",
            182: "decrease volume level (firefox)",
            183: "increase volume level (firefox)",
            186: "semi-colon / ñ",
            187: "equal sign ",
            188: ",",
            189: "dash ",
            190: "period",
            191: "/",
            192: "'",
            193: "?",
            194: "numpad period (chrome)",
            219: "open bracket ",
            220: "\\",
            221: "close bracket ",
            222: "'", // apostrophe
            223: "`", // single quote
            224: "left or right ⌘ key (firefox)",
            225: "altgr",
            226: "< /git >",
            230: "GNOME Compose Key",
            233: "XF86Forward",
            234: "XF86Back",
            255: "toggle touchpad"
        };
    }

    /**
     *
     * Add a key event listener, gets destroyed when new screen loaded
     *
     * @function earthpixi.Keyboard#addKey
     * @static
     * @param keyCode
     * @param keyDownCallBack
     * @param keyUpCallBack
     * @param repeat
     * @param keyPressCallBack
     * @param callbackScope
     */
    addKey(keyCode, keyDownCallBack, keyUpCallBack, repeat, keyPressCallBack, callbackScope){
        
        console.log('EPIXI: Keyboard.addKey: ', keyCode);

        const keyEvent = { keyDown: keyDownCallBack, keyUp: keyUpCallBack, keyPress: keyPressCallBack, isDown: null, repeat, callbackScope };

        this.keys[keyCode] = keyEvent;

        const str = this.KeyCodesDict[keyCode] || "";
        let charCodeLC;
        let charCodeUC;


        if (str.length === 1)
        {
            charCodeLC = str.charCodeAt(0);
            charCodeUC = str.toUpperCase()
                .charCodeAt(0);
        }
        if (charCodeLC)
        {
            this.keysByChar[charCodeLC] = keyEvent;
        }
        if (charCodeUC)
        {
            this.keysByChar[charCodeUC] = keyEvent;
        }

        // add alternative apostrophes
        if (keyCode == 192)
        {
            this.keys[222] = keyEvent;
            this.keys[223] = keyEvent;
        }

        // add alternative ? mark
        if (keyCode == 191)
        {
            this.keys[193] = keyEvent;
        }

        window.document.body.focus();
    }

    /**
     * Returns key code from KeyCodesDict from a character string.
     *
     * @function earthpixi.Keyboard#getKeyCode
     *
     * @param {string} keyString
     * @returns {number} keycode
     */
    getKeyCode(keyString)
    {

        // depreciations
        if(keyString==="spacebar") keyString = " ";
        if(keyString==="comma") keyString = ",";
        if(keyString==="caps lock") keyString = "capslock";


        for (const key in this.KeyCodesDict)
        {
            if (this.KeyCodesDict.hasOwnProperty(key))
            {
                const value = this.KeyCodesDict[key];

                if (keyString == value)
                {
                    return key;
                }
            }
        }

        console.log("key not found, use one of the values in dict below");
        console.log(this.KeyCodesDict);
    }

    /**
     *
     * Remove a key event listener
     *
     * @function earthpixi.Keyboard#removeKey
     * @static
     * @param keyCode
     */
    removeKey(keyCode)
    {
        // console.log("add key");
        this.keys[keyCode] = null;

        // remove alternative apostrophes
        if (keyCode == 192)
        {
            this.keys[188] = null;
            this.keys[222] = null;
            this.keys[223] = null;
        }

        // remove alternative ? marks
        if (keyCode == 191)
        {
            this.keys[193] = null;
        }
    }

    /**
     * @private
     * @param evt
     */
    onKeyDown(evt)
    {
        evt = this.checkKeyCode(evt);
        console.log('::keyboard event triggered: ', evt);
        console.log('::keyboard keys: ', this.keys);
        if(!earthpixi.audioAwake) earthpixi.activateAudio(evt);

        if (
            this.keys[evt.keyCode] != null
            && this.keys[evt.keyCode].keyDown != null
            && this.keys[evt.keyCode].isDown != true
        )
        {
            if (this.keys[evt.keyCode].callbackScope)
            {
                this.keys[evt.keyCode].keyDown.apply(this.keys[evt.keyCode].callbackScope, [evt]);
            }
            else
            {
                this.keys[evt.keyCode].keyDown(evt);
            }
            if (this.keys[evt.keyCode])
            {
                this.keys[evt.keyCode].isDown = true;
            }
        }

        // if
    }

    onKeyPress(evt)
    {
        evt = this.checkKeyCode(evt);

        if(!earthpixi.audioAwake) earthpixi.activateAudio(evt);

        if (
            this.keysByChar[evt.charCode] != null
            && this.keysByChar[evt.charCode].keyPress != null
        )
        {
            if (this.keysByChar[evt.charCode].callbackScope)
            {
                this.keysByChar[evt.charCode].keyPress.apply(this.keysByChar[evt.charCode].callbackScope, [evt]);
            }
            else
            {
                this.keysByChar[evt.charCode].keyPress(evt);
            }
        }
    }

    /**
     * @private
     * @param evt
     */
    onKeyUp(evt)
    {
        evt = this.checkKeyCode(evt);

        if (this.keys[evt.keyCode] != null)
        {
            this.keys[evt.keyCode].isDown = false;

            if (this.keys[evt.keyCode].keyUp != null)
            {
                if (this.keys[evt.keyCode].callbackScope)
                {
                    this.keys[evt.keyCode].keyUp.apply(this.keys[evt.keyCode].callbackScope, [evt]);
                }
                else
                {
                    this.keys[evt.keyCode].keyUp(evt);
                }
            }
        }
    }

    checkKeyCode(evt)
    {
        if (evt.keyCode === undefined)
        {
            if (evt.which)
            {
                evt.keyCode = evt.which;
            }
            else
            {
                evt.keyCode = this.getKeyCode(evt.key.toLowerCase());
            }
        }
        else
        {
            return evt;
        }
    }

    /**
     * @function earthpixi.Keyboard#reset
     * Stop tracking all keys
     */
    reset(caller)
    {
        console.log('EPIXI: Keyboard.reset: ', caller);
        // this.keys = {};
        // this.keysByChar = {};
    }
}
