/**
 *
 * Use **earthpixi.Input.createInput()** instead of this class directly (registers it with input controller make it part of a form)
 *
 * @memberof earthpixi.Input
 * @class
 * @extends {PIXI.Container}
 *
 */
export default class InputField extends PIXI.Container
{
    /**
   *
   * @see earthpixi.Input.createInput
   * @param style
   * @param text
   * @param tint
   * @param width
   * @param [isEmail]
   * @param [hasOwnKeyBoard]
   * @param [bitmapText]
   * @param [mask]
   * @param [hitExtraHeight]
   * @param [numeric]
   * @param [decLimit]
   * @param [lines]
   * @param [padding]
   */
    constructor(style, text, tint, width,
        isEmail = false, hasOwnKeyBoard = false,
        bitmapText = true, mask = false, hitExtraHeight = 90,
        numeric = false, decLimit, lines = 1, padding = 0)
    {
        super();

        /**
         *
         * @type {Function|null}
         */
        this.customCheck = null;

        this.hasOwnKeyboard = hasOwnKeyBoard;
        this.bitmapText = bitmapText;
        this.numeric = numeric;

        this.decLimit = decLimit;
        this.numLines = lines;

        this.textField = null;

        if (this.bitmapText)
        {
            this.textField = new PIXI.extras.BitmapText("P", style);
        }
        else
        {
            this.textField = new PIXI.Text("P", style);
        }

        // this.textField.on("pointerdown", this.onTextClicked, this);
        // this.textField.interactive = true;
        this.textField.tint = tint;
        this.addChild(this.textField);

        /**
         * @type {string}
         */
        this.origText = text;

        let bgWidth = text.width;

        if (width !== null)
        {
            bgWidth = width;
        }

        // multiline
        if (this.numLines > 1)
        {
            this.textField.maxWidth = bgWidth - 10;
            for (let i = 1; i < this.numLines; i++)
            {
                this.textField.text += "\nP";
            }
        }

        this.bg = new PIXI.Graphics();
        this.bg.beginFill(0xFFFFFF, 1);
        this.bg.drawRect(0, 0, bgWidth + 10, (this.textField.textHeight || this.textField.height) + 5);
        this.addChildAt(this.bg, 0);

        if (mask)
        {
            this.tfmask = new PIXI.Graphics();
            this.tfmask.beginFill(0xFFFFFF, 1);
            this.tfmask.drawRect(0, 0, bgWidth + 10, (this.textField.textHeight || this.textField.height) + 5);
            this.addChildAt(this.tfmask, 0);
            this.textField.mask = this.tfmask;
        }

        this.currentTextLength = 0;
        this.caretIndex = 0;
        this.caret = new PIXI.Graphics();
        this.caret.beginFill(tint, 1);
        this.caret.drawRect(0, 0, 2 * earthpixi.resolution, this.textField._maxLineHeight * 1.3);

        this.bg.interactive = true;
        this.bg.hitArea = new PIXI.Rectangle(0, 0, bgWidth + 10, (this.textField.textHeight || this.textField.height) + 5 + hitExtraHeight);
        // this.on("mousedown", this.onTap, this);
        this.bg.on("pointerdown", this.onTap, this);
        // this.on("touchstart", this.onTap, this);

        // this.on("mouseup", this.onTapEnd, this);
        this.bg.on("pointerup", this.onTapEnd, this);
        // this.on("touchend", this.onTapEnd, this);

        this.valid = false;

        if (isEmail)
        {
            this.email = true;
            this.emailValid = false;
            if (this.bitmapText)
            {
                this.emailCheckText = new PIXI.extras.BitmapText("enter valid email", style);
            }
            else
            {
                this.emailCheckText = new PIXI.Text("enter valid email", style);
            }
            this.emailCheckText.position.set(0, this.textField.height * 3);
            this.emailCheckText.scale.set(1, 1);
            this.emailCheckText.visible = false;
            this.addChild(this.emailCheckText);
        }
        else
        {
            this.email = false;
            if (this.bitmapText)
            {
                this.fieldCheckText = new PIXI.extras.BitmapText("enter text", style);
            }
            else
            {
                this.fieldCheckText = new PIXI.Text("enter text", style);
            }
            this.fieldCheckText.position.set(0, this.textField.height * 3);
            this.fieldCheckText.scale.set(1, 1);
            this.fieldCheckText.visible = false;
            this.addChild(this.fieldCheckText);
        }

        this.active = false;
        earthpixi.Input.registerInput(this);
        this.caretTween = earthpixi.Tween.to(this.caret, 0.5, {
            alpha: 0, onComplete()
            {
                this.reverse();
            }, onReverseComplete()
            {
                this.restart();
            }
        });

        this.textField.text = this.origText;

        // if text empty then auto focus
        if (this.origText === "")
        {
            this.onTap();
        }
    }

    /**
   * @type {string}
   */
    get text()
    {
        return this.textField.text;
    }

    set text(val)
    {
        this.updateText(val);
    }

    onTap(evt)
    {
        if (evt)
        {
            this.onTextClicked(evt);
            evt.stopPropagation();
            evt.data.originalEvent.preventDefault();
            evt.data.originalEvent.stopPropagation();
        }

        earthpixi.Input.activateInput(this, true);

        if (earthpixi.Input.virtualTF)
        {
            earthpixi.Input.virtualTF.value = "";
            if(this.numeric){
                earthpixi.Input.virtualTF.setAttribute("pattern", "/\d*");
                earthpixi.Input.virtualTF.setAttribute("type", "number");
            }else{
                earthpixi.Input.virtualTF.setAttribute("pattern", false);
                earthpixi.Input.virtualTF.setAttribute("type", "text");
            }

            earthpixi.Input.virtualTF.setAttribute("email", this.email);

            if (document.activeElement)
            {
                document.activeElement.blur();
            }
            if (earthpixi.Input.virtualTF)
            {

                earthpixi.Input.virtualTF.focus({preventScroll:true});
            }
        }
    }

    onTextClicked(evt)
    {
        const clickPoint = evt.data.getLocalPosition(evt.target);
        // console.log(clickPoint);
        let dist = 10000;
        let index = this.textField.children.length - 1;
        let found = this.textField.children[index] || null;

        let yRef = 0;

        for (let i = 0; i < this.textField.children.length; i++)
        {
            const char = this.textField.children[i];

            if (char.height && yRef < char.y)
            {
                yRef = char.y;
            }

            const pointX = char.x; // + (char.width || this.textField.font.size/3);
            const newDist = this.distance({ x: pointX, y: yRef }, clickPoint);

            if (newDist < dist && (clickPoint.y - char.y < this.textField.maxLineHeight * 1.3))
            {
                dist = newDist;
                found = char;
                index = i;
            }
        }

        if (found)
        {
            if (
                found.y > this.textField.maxLineHeight
          || found.x + (found.width ? found.width : this.textField.font.size / 3) < clickPoint.x
            )
            {
                index++;
            }

            this.caretIndex = index;
            this.updateCaret();
        }
    }

    distance(p1, p2)
    {
        return Math.sqrt(this.distanceToSq(p1, p2));
    }

    distanceToSq(p1, p2)
    {
        const dx = p1.x - p2.x; const
            dy = p1.y - p2.y;

        return dx * dx + dy * dy;
    }

    onTapEnd(evt)
    {
        evt.stopPropagation();
        evt.data.originalEvent.preventDefault();
        evt.data.originalEvent.stopPropagation();
    }

    /**
   * Focus this input
   *
   */
    activate()
    {
    // console.log("activate");
        earthpixi.Input.activateInput(this, false);
    }

    _activate()
    {
        this.active = true;
        if (this.textField.text === this.origText)
        {
            this.updateText("");
        }
        this.addChild(this.caret);
        this.updateCaret();
    }

    /**
   * blur the input
   */
    deActivate()
    {
        earthpixi.Input.deActivateInput(this, false);
    }

    _deActivate()
    {
        if (this.caret.parent)
        {
            this.removeChild(this.caret);
        }
        if (this.textField.text.length < 2)
        {
            this.updateText(this.origText);
        }
    }

    updateCaret()
    {
        // update PIXI texfield so children in correct order
        this.textField.updateText(false);

        let chosenCaretIndex = 0;
        let targetCaretIndex = this.caretIndex;
        let spaceProblem = false;
        const spaceProblem2 = false;
        // console.log(">"+this.textField.text.charAt(this.caretIndex-1)+"<");
        let lineNumber = 0;
        let lineCount = 0;

        // console.log(this.textField.children);

        if (this.caretIndex !== 0)
        {
            let lineMeasure = this.textField.maxLineHeight;
            // go through each child to find num lines and

            for (let t = 0; t < this.textField.children.length; t++)
            {
                const charSpr = this.textField.children[t];

                chosenCaretIndex++;
                lineCount++;
                // have we gone onto new line
                // if the textfield goes to another line, one space is missing from the children in textfield, adjust caret index accordingly:
                if (charSpr.y > lineMeasure && charSpr.height > 0)
                {
                    targetCaretIndex--;
                    chosenCaretIndex++;
                    lineCount = 0;
                    lineNumber++;
                    // console.log("new line", targetCaretIndex, chosenCaretIndex,lineCount, charSpr.y, lineMeasure, this.textField.maxLineHeight, charSpr.height);
                    lineMeasure += this.textField.maxLineHeight;
                }

                // we're on the right edge of a line
                if (chosenCaretIndex - targetCaretIndex === 2)
                {
                    // console.log("problems", lineCount, lineNumber);
                    spaceProblem = true;
                    chosenCaretIndex = targetCaretIndex + 1 - (lineNumber - 1);
                    break;
                }
                else if (chosenCaretIndex > targetCaretIndex)
                {
                    // console.log("here", lineCount);
                    chosenCaretIndex = targetCaretIndex;
                    break;
                }
                else if (chosenCaretIndex === targetCaretIndex)
                {
                    // edge of second line
                    // console.log("done", chosenCaretIndex, lineNumber,lineCount);
                    break;
                }
            }
        }

        // TODO updateCaret for non-bitmap text based on PIXI.TextMetrics

        const isSpace = this.textField.text.charAt(this.caretIndex - 1) === " ";
        const currentChar = this.textField.children[chosenCaretIndex - 1] || this.textField.children[this.textField.children.length - 1];
        const prevChar = this.textField.children[chosenCaretIndex - 2]; // || this.textField.children[0];

        let spaceSize;

        if (this.textField.font)
        {
            spaceSize = this.textField.font.size / 3;
        }
        else
        if (!this.textField.style.fontSize.substr)
        {
            spaceSize = this.textField.style.fontSize / 3;
        }
        else
        {
            spaceSize = parseInt(this.textField.style.fontSize.substr(0, this.textField.style.fontSize.length - 2), 10) / 3;
        }

        if ((isSpace && this.textField.text.length === 1) || chosenCaretIndex === 0)
        {
            this.caret.position.set(this.textField.x, 2);
        }
        else
        {
            //
            let caretX = this.textField.x + (currentChar.x + (currentChar.width > 0 ? currentChar.width : spaceSize));
            let caretY = this.textField.y + (currentChar.y + currentChar.height - this.textField.maxLineHeight);

            if (isSpace)
            {
                // console.log(lineNumber, isSpace,chosenCaretIndex);
                caretX = this.textField.x + (prevChar.x + (prevChar.width > 0 ? prevChar.width : spaceSize) + spaceSize);
                caretY = this.textField.y + (prevChar.y + prevChar.height - this.textField.maxLineHeight);
            }

            this.caret.position.set(caretX, caretY);
        }
    }

    /**
     *
     * @param newChar
     */
    addText(newChar)
    {
        let str = " ";

        if (this.textField.text != " ")
        {
            str = this.textField.text.substr(0, this.caretIndex) + newChar + this.textField.text.substr(this.caretIndex, this.textField.text.length);
        }
        else
        {
            str = newChar;
        }

        this.caretIndex += newChar.length;
        this.updateText(str);
    }

    /**
     *
     */
    deleteText()
    {
        const str = this.textField.text.substr(0, this.caretIndex - 1) + this.textField.text.substr(this.caretIndex, this.textField.text.length);

        this.caretIndex--;
        this.updateText(str);
    }

    moveLeft()
    {
        if (this.caretIndex > 0)
        {
            this.caretIndex--;
            this.updateCaret();
        }
    }

    moveRight()
    {
        if (this.caretIndex < this.textField.text.length)
        {
            this.caretIndex++;
            this.updateCaret();
        }
    }

    updateText(str)
    {
        const originalTxt = this.textField.text;
        const newTxt = str;

        if (this.numeric)
        {
            if (!isNaN(parseFloat(str)) && isFinite(str))
            {
                if (this.decLimit)
                {
                    if (str.length > this.decLimit)
                    {
                        return;
                    }
                }
            }
            else
            if (str !== "")
            {
                return;
            }
            this.caretIndex = str.length;
        }

        this.textField.text = str;
        if (str.charAt(0) === " ")
        {
            this.textField.text = str.substr(1);
        }

        if (this.textField.text === " ")
        {
            this.caretIndex = 0;
        }

        if (earthpixi.Input.virtualTF)
        {
            earthpixi.Input.virtualTF.value = this.textField.text;
            earthpixi.Input.virtualTF.selectionStart = this.caretIndex;
            earthpixi.Input.virtualTF.selectionEnd = this.caretIndex;
        }

        earthpixi.Input.formSubmitSuppress = false;

        this.updateScroll();
        this.updateCaret();
        this.checkContent(false);
    }

    /**
   *
   * Validate the content,  if string then checks if there is something in there, if email then checks if valid email
   *
   *
   * @param {boolean} [showWarning=true] show text beneath field saying invalid input content
   * @returns {boolean}
   */
    checkContent(showWarning = true)
    {
        let valid = true;

        if (this.email)
        {
            if (this.textField.text.length < 2 || this.textField.text === this.origText)
            {
                valid = false;
            }
            else
            {
                valid = this.emailCheck();
            }

            this.emailCheckText.visible = (showWarning && !valid);
        }
        else
        {
            // console.log(this.textField.text.length);

            if (this.textField.text.length < 1 || this.textField.text === this.origText || this.textField.text === " ")
            {
                valid = false;
            }

            if (this.customCheck)
            {
                const customStr = this.customCheck();

                this.fieldCheckText.visible = (showWarning && valid !== true);
                if (typeof customStr === "string")
                {
                    this.fieldCheckText.text = customStr;
                }
            }
            else
            {
                this.fieldCheckText.visible = (showWarning && valid !== true);
            }
        }

        this.valid = valid === true;

        earthpixi.Input.checkAll();

        return this.valid;
    }

    updateScroll()
    {
        if (this.numLines > 1)
        { return; }

        let padForspace = 0;

        if (
            this.textField.text.charAt(this.textField.text.length - 1) === " "
      && this.textField.text.length > 1
        )
        {
            if (this.textField.font)
            {
                padForspace = this.textField.font.size / 2;
            }
            else
            if (!this.textField.style.fontSize.substr)
            {
                padForspace = this.textField.style.fontSize / 2;
            }
            else
            {
                padForspace = parseInt(this.textField.style.fontSize.substr(0, this.textField.style.fontSize.length - 2), 10) / 2;
            }
        }

        if (this.textField.width + 2 > this.bg.width)
        {
            this.textField.x = -((this.textField.width + padForspace) - this.bg.width) - 10;
        }
        else
        {
            this.textField.x = 0;
        }
    }

    emailCheck()
    {
        const re = /\S+@\S+\.\S+/;

        return re.test(this.textField.text);
    }

    destroy(options)
    {
        this.deActivate();
        this.customCheck = null;
        this.bg = null;
        earthpixi.Tween.kill(this.caretTween);
        super.destroy(options);
    }
}

