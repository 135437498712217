/**
 *
 *
 *
 * @namespace earthpixi.utils.Geom
 *
 */
export default {
    /**
     *
     * <img width=250 src="https://i.stack.imgur.com/biPCe.jpg" alt="example here">
     *
     * @memberof earthpixi.utils.Geom
     * @param point1
     * @param point2
     * @param knownX
     * @returns {number}
     */
    findYAlongLineKnowingX: (point1, point2, knownX) =>
        (((knownX - point2.x) * point1.y) + ((point1.x - knownX) * point2.y)) / (point1.x - point2.x),

    /**
     *
     * @memberof earthpixi.utils.Geom
     * @param point1
     * @param point2
     * @param knownY
     * @returns {number}
     */
    findXAlongLineKnowingY: (point1, point2, knownY) =>
        (((knownY - point2.y) * point1.x) + ((point1.y - knownY) * point2.x)) / (point1.y - point2.y),

    distance(pos1, pos2)
    {
        const dx = pos1.x - pos2.x;
        const dy = pos1.y - pos2.y;

        const sq = dx * dx + dy * dy;

        return Math.sqrt(sq);
    },

    /**
     * An independent Vector 2d class to work with
     * TODO make some of these methods static might be useful
     *
     *@memberof earthpixi.utils.Geom
     */
    Vector2: class Vector2
    {
        /**
         * @param x
         * @param y
         */
        constructor(x = 0, y = 0)
        {
            this.x = x;
            this.y = y;
        }

        /**
         * @function earthpixi.utils.Geom.Vector2#set
         * @param {earthpixi.utils.Geom.Vector2} v
         */
        set(v)
        {
            this.x = v.x;
            this.y = v.y;
        }

        /**
         * @function earthpixi.utils.Geom.Vector2#add
         * @param {earthpixi.utils.Geom.Vector2} v
         * @returns {earthpixi.utils.Geom.Vector2}
         */
        add(v)
        {
            this.x += v.x;
            this.y += v.y;

            return this;
        }

        /**
         * @function earthpixi.utils.Geom.Vector2#lerp
         * @param {earthpixi.utils.Geom.Vector2} v
         * @param alpha
         * @returns {earthpixi.utils.Geom.Vector2}
         */
        lerp(v, alpha)
        {
            this.x += (v.x - this.x) * alpha;
            this.y += (v.y - this.y) * alpha;

            return this;
        }

        /**
         * @function earthpixi.utils.Geom.Vector2#moveTowards
         * @param {earthpixi.utils.Geom.Vector2} v
         * @param distance
         * @returns {earthpixi.utils.Geom.Vector2}
         */
        moveTowards(v, distance)
        {
            if (Math.abs(this.distance(v)) <= distance)
            {
                this.x = v.x;
                this.y = v.y;

                return this;
            }

            // direction towards player
            const dirX = v.x - this.x;
            const dirY = v.y - this.y;

            // Normalize
            const dist = Math.sqrt(dirX * dirX + dirY * dirY);
            const moveToX = dirX / dist;
            const moveToY = dirY / dist;

            // Move towards the player
            this.x += moveToX * distance;
            this.y += moveToY * distance;

            // Rotate us to face direction
            this.rotation = Math.atan2(dirX, dirY);

            return this;
        }

        /**
         * @function earthpixi.utils.Geom.Vector2#moveTo
         * @param {earthpixi.utils.Geom.Vector2} v
         * @param percentage
         * @returns {earthpixi.utils.Geom.Vector2}
         */
        moveTo(v, percentage)
        {
            return this.lerp(v, percentage);
        }

        rad()
        {
            return Math.atan2(this.x, this.y);
        }

        deg()
        {
            return this.rad() * 180 / Math.PI;
        }

        equals(v)
        {
            return this.x === v.x && this.y === v.y;
        }

        /**
         * @function earthpixi.utils.Geom.Vector2#rotate
         * @param theta
         * @returns {earthpixi.utils.Geom.Vector2}
         */
        rotate(theta)
        {
            const xtemp = this.x;

            this.x = this.x * Math.cos(theta) - this.y * Math.sin(theta);
            this.y = xtemp * Math.sin(theta) + this.y * Math.cos(theta);

            return this;
        }

        /**
         * @function earthpixi.utils.Geom.Vector2#distance
         * @param {earthpixi.utils.Geom.Vector2} v
         * @returns {number}
         */
        distance(v)
        {
            return Math.sqrt(this.distanceToSq(v));
        }

        distanceToSq(v)
        {
            const dx = this.x - v.x;
            const dy = this.y - v.y;

            return dx * dx + dy * dy;
        }
    }

};
