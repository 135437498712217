/**
 *
 * Handle Mouse Wheel input
 *
 *
 * Example start tracking mouse wheel movements within the bounds of an object.
 * Gets cleared when screen changed.
 *
 * ```js
 * //start listening for mouse wheel movements
 * earthpixi.Wheel.addWheelItem(earthpixi.currentScreen, this.onWheel, this);
 *
 * onWheel(evt){
 *    console.log(evt.deltaY)
 * }
 *
 * ```
 *
 *
 * @namespace earthpixi.Wheel
 *
 */

export default class Wheel
{
    constructor()
    {
        this.addWheelListener(earthpixi.renderer.view, this.onWheel.bind(this), true);
    }

    /**
   *
   * @function earthpixi.Wheel#addWheelItem
   *
   * @param {PIXI.Container|earthpixi.GameObject} container
   * @param {functon} callback
   * @param {object} callbackScope
   */
    addWheelItem(container, callback, callbackScope)
    {
        container.onWheel = callback;
        callback._userScope = callbackScope;
        earthpixi.wheelItems.push(container);
    }

    onWheel(evt)
    {
        for (let i = 0; i < earthpixi.wheelItems.length; i++)
        {
            this.checkWheel(earthpixi.wheelItems[i], evt);
        }
    }

    checkWheel(item, evt)
    {
    // check if mouse in container
        const pos = earthpixi.renderer.plugins.interaction.mouse.getLocalPosition(item);

        if (
            pos.x > 0
      && pos.x < item.width
      && pos.y > 0
      && pos.y < item.height
        )
        {
            if (item.onWheel._userScope)
            {
                item.onWheel.apply(itm.onWheel._userScope, [evt]);
            }
            else
            {
                item.onWheel(evt);
            }
        }
    }

    addWheelListener(elem, callback, useCapture = false)
    {
        elem.addEventListener("wheel", callback, useCapture);
    }

    /**
   * Clear any wheel listeners
   *
   * @function earthpixi.Wheel#reset
   */
    reset()
    {
        for (let i = 0; i < earthpixi.wheelItems.length; i++)
        {
            earthpixi.wheelItems[i].onWheel = null;
        }
    }
}
