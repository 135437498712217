import DwellAnimation from "./DwellAnimation";

export default class Target extends DwellAnimation
{
    constructor(radius)
    {
        super(radius);

        this.type = "default";
        this.on("removed", function ()
        {
            if (this.mainTween != null)
            {
                this.mainTween.kill();
            }
        }, this);

        this.circleSpr = earthpixi.Utils.Draw.DrawCircle(radius, "#a8fffa");
        this.circleSpr.alpha = 0.7;
        this.circleSpr.anchor.set(0.5, 0.5);
        this.circleSpr.x = radius;
        this.circleSpr.y = radius;
        this.addChild(this.circleSpr);
    }

    show(button, direct, delay=0)
    {
        this.circleSpr.scale.set(1);
        super.show(button, direct);

        // this.circleSpr.x = button.width / 2;
        // this.circleSpr.y = button.height / 2;

        this.mainTween = this.mainTween = earthpixi.Tween.to(this.circleSpr.scale, earthpixi.Access.settings.eyeGazeConfig.dwellTime,
            { delay:delay, x: 0.1, y: 0.1, ease: Linear.easeNone, onComplete: this.onSelect, onCompleteParams: [button], onCompleteScope: this }
        );
    }

    hide()
    {
        earthpixi.Tween.kill(this.mainTween);
        super.hide();
    }
}
