import GameObject from "../base/GameObject";
import InScreenLoader from "./InScreenLoader";
import { uid } from "uid";
import SpineEP from "./SpineEP";
import SpineUtils from "./SpineUtils";

/**
 * Class to load and play a spine animation and associated sound sprite
 *
 * ```
 * let myAnimPlayer = new SpinePlayer(
 *     [
 *      `${earthpixi.config.SPINE_ROOT}anim1/textures0@${earthpixi.resolution}x.json`
 *      `${earthpixi.config.SPINE_ROOT}anim1/textures1@${earthpixi.resolution}x.json`
 *     ],
 *     `${earthpixi.config.SPINE_ROOT}anim1/spine.spine_data`
 *     `${earthpixi.config.AUDIO_ROOT}anim1/` // folder with sprite.json, sprite.mp3
 * );
 *
 *
 *
 * ```
 * @memberof earthpixi.utils
 * @extends PIXI.spine.Spine
 *
 */
export default class SpinePlayer extends GameObject
{
    /**
     *
     * @param {string | Array} textures
     * @param {string | Array} spineData
     * @param {string | null} [audioSpriteFolder]
     * @param {string} [skin]
     */
    constructor(textures, spineData, audioSpriteFolder = null, skin = null)
    {
        super();

        this.uid = uid(10);
        this._loaded = false;
        this._playing = false;
        this._currentAnimation = null;
        this._currentTimeScale = 1;
        this._currentSkinName = skin || "default";
        this._currentOnComplete = null;
        this._currentOnCompleteScope = null;
        this._hasAudio = audioSpriteFolder;
        this._loadedResources = null;

        if (!(textures instanceof Array))
        { textures = [textures]; }

        if (!(spineData instanceof Array))
        { spineData = [spineData]; }

        this.texAssetsLength = textures.length;
        this.spineAssetsLength = spineData.length;

        const assetList = [];

        for (let i = 0; i < textures.length; i++)
        {
            assetList.push({ name: `${this.uid}_${i}`, url: textures[i] });
        }

        for (let i = 0; i < spineData.length; i++)
        {
            assetList.push({ name: `anim_data${this.uid}_${i}`, url: spineData[i] });
        }

        if (this._hasAudio)
        {
            if (audioSpriteFolder.charAt(audioSpriteFolder.length - 1) === "/")
            {
                audioSpriteFolder = audioSpriteFolder.substring(0, audioSpriteFolder.length - 1);
            }

            assetList.push({ name: `audio_data${this.uid}`, url: `${audioSpriteFolder}/sprite.json` });
            assetList.push(
                {
                    name: `audio${this.uid}`, url: `${audioSpriteFolder}/sprite.mp3`,
                    options: {
                        loadType: PIXI.loaders.Resource.LOAD_TYPE.XHR,
                        xhrType: PIXI.loaders.Resource.XHR_RESPONSE_TYPE.BLOB,
                    }
                }
            );
        }

        if (assetList.length === 0)
        {
            console.warn("spine player no assets");

            return;
        }

        this.loader = new InScreenLoader(assetList, this.loaded, this);
    }

    /**
     *
     * @param {object} resources
     */
    loaded(resources)
    {
        this._loadedResources = resources;

        const loadedTextureNames = [];

        for (let i = 0; i < this.texAssetsLength; i++)
        {
            loadedTextureNames.push(`${this.uid}_${i}`);
        }
        this._spineAtlas = SpineUtils.createSpineAtlas(loadedTextureNames);

        if (this._hasAudio)
        {
            this.audioBlob = earthpixi.createObjectURL(earthpixi.resources[`audio${this.uid}`].data);
            this.audioSprite = earthpixi.Audio.createSprite(
                `audio${this.uid}`, [this.audioBlob],
                earthpixi.resources[`audio_data${this.uid}`].data.sprite
            );
        }

        this._spines = [];

        for (let i = 0; i < this.spineAssetsLength; i++)
        {
            this._spines.push(
                this.createSpine(`anim_data${this.uid}_${i}`)
            );
        }

        this._loaded = true;

        if (this._playing)
        {
            if (this._currentSkinName)
            {
                for (const spine of this._spines)
                {
                    spine.setSkin(this._currentSkinName);
                }
            }
            this.queueAnimations();
        }

        /**
         * Event fired when all assets required for animation have loaded and Spine created
         *
         * @event earthpixi.utils.SpinePlayer#loaded
         */
        this.emit("loaded", this);
    }

    createSpine(assetName)
    {
        const newSpine = new SpineEP(
            this._spineAtlas,
            earthpixi.resources[assetName].data,
            this.audioSprite ? `audio${this.uid}` : null, 1,
            true
        );

        newSpine.setSkin(this._currentSkinName);
        newSpine.addEvent("allcomplete", this.onAnimationEnd, this);

        this.addChild(newSpine);

        return newSpine;
    }

    /**
     * Available after loaded event fired
     *
     * @return {Array<earthpixi.utils.SpineEP>}
     * @type {Array<earthpixi.utils.SpineEP>}
     */
    get spines()
    {
        if (!this._spines)
        {
            throw "spines not loaded yet,  listen for on('loaded')";
        }

        return this._spines;
    }

    /**
     * Returns the first spine (only spine if just loaded one animation)
     * @return {earthpixi.utils.SpineEP}
     * @type {earthpixi.utils.SpineEP}
     */
    get spine()
    {
        if (!this._spines)
        {
            throw "spine not loaded yet,  listen for on('loaded')";
        }

        return this._spine[0];
    }

    /**
     *
     * @param {string} skinName
     */
    setSkin(skinName)
    {
        if (!this._loaded)
        {
            throw "need to wait until spine loaded to set skin again, listen for on('loaded')";
        }

        this._currentSkinName = skinName;
        for (const spine of this._spines)
        {
            spine.setSkin(this._currentSkinName);
        }
    }

    /**
     *
     * @param {string | Array} animations
     * @param {number} [timeScale]
     * @param {function} [onComplete]
     * @param {Object} [onCompleteScope]
     * @param {boolean} [loop] loop the animation or last animation in array
     */
    play(animations, timeScale = 1, onComplete = null, onCompleteScope = null, loop = false)
    {
        this._currentTimeScale = timeScale;
        this._currentAnimation = animations;
        this._currentLoop = loop;
        this._playing = true;

        this._currentOnComplete = onComplete;
        this._currentOnCompleteScope = onCompleteScope;

        if (this._loaded)
        {
            for (const spine of this._spines)
            {
                spine.state.timeScale = this._currentTimeScale;
            }
            this.queueAnimations();
        }
    }

    /**
     *
     * @param {number | null} index
     * @param {string | Array} animations
     * @param {number} [timeScale]
     * @param {function} [onComplete]
     * @param {Object} [onCompleteScope]
     * @param {boolean} [loop] loop the animation or last animation in array
     */
    playOne(index = null, animations, timeScale = 1, onComplete = null, onCompleteScope = null, loop = false)
    {
        this._currentTimeScale = timeScale;
        this._currentAnimation = animations;
        this._currentLoop = loop;
        this._playing = true;

        this._currentOnComplete = onComplete;
        this._currentOnCompleteScope = onCompleteScope;

        if (this._loaded)
        {
            for (const spine of this._spines)
            {
                spine.state.timeScale = this._currentTimeScale;
            }
            this.queueAnimations(index);
        }
    }

    /**
     * @private
     */
    queueAnimations(index = null)
    {
        const anims = typeof this._currentAnimation === "string" ? [this._currentAnimation] : this._currentAnimation;

        if (index !== null)
        {
            this._spines[index].state.setAnimation(0, anims[0], anims.length === 1 ? this._currentLoop : false);
        }
        else
        {
            for (const spine of this._spines)
            {
                spine.state.setAnimation(0, anims[0], anims.length === 1 ? this._currentLoop : false);
            }
        }

        for (let i = 1; i < anims.length; i++)
        {
            if (index !== null)
            {
                this._spines[index].state.setAnimation(0, anims[i], anims.length === 1 ? this._currentLoop : false);
            }
            else
            {
                for (const spine of this._spines)
                {
                    spine.state.addAnimation(0, anims[i], this._currentLoop, 0);
                }
            }
        }
    }

    /**
     *@private
     */
    resume()
    {
        for (const spine of this._spines)
        {
            spine.state.timeScale = this._currentTimeScale;
        }
        this.audioSprite.resume();
    }

    /**
     *@private
     */
    pause()
    {
        for (const spine of this._spines)
        {
            spine.state.timeScale = 0;
        }
        this.audioSprite.pause();
    }

    /**
     *@private
     */
    stop()
    {
        for (const spine of this._spines)
        {
            spine.state.timeScale = 0;
        }
        this.audioSprite.stop();
    }

    /**
     * @private
     */
    onAnimationEnd(eventName, trackEntry, spineCallingEvent)
    {
        // console.log("anim end", eventName, spineCallingEvent.ep_events.length);

        for (const spine of this._spines)
        {
            if (spine !== spineCallingEvent)
            {
                if (spine.state.getCurrent(0))
                {
                    if (!spine.state.getCurrent(0).isComplete())
                    { return; }
                }
            }
        }

        if (this._currentOnComplete)
        {
            this._currentOnComplete.apply(this._currentOnCompleteScope);
        }
        this.emit("allcomplete", this);
    }

    /**
     *
     * @param {object | boolean} options
     */
    destroy(options)
    {
        if (this.loader)
        {
            this.loader.reset();
            this.loader.destroy();
        }

        if (this._loadedResources)
        {
            for (const key in this._loadedResources)
            {
                if (earthpixi.resources.hasOwnProperty(key)) delete earthpixi.resources[key];
            }
        }
        if (this.audioSprite)
        {
            this.audioBlob = null;
            this.audioSprite.unload();
        }
        super.destroy(options);
    }
}

