/**
 * Clear stage mask set with earthpixi.setMask
 *
 * @memberof earthpixi
 * @function earthpixi#clearMask
 */
export default function clearMask()
{
    if (earthpixi.mask)
    {
        earthpixi.stageOverlay.removeChild(earthpixi.mask);
        earthpixi.mask.destroy();
        earthpixi.mask = null;
    }
}
