export default {
    safeCleanUp()
    {
    // save list of any textures and destroy any not currently being used
        earthpixi.savedBaseTextures = {};

        // save global resource baseTextures
        if (earthpixi.resources)
        {
            for (let i = 0; i < earthpixi.globalAssetsList.length; i++)
            {
                // if added to global asset list then save it
                const resourceName = earthpixi.globalAssetsList[i].name;

                if (earthpixi.resources[resourceName])
                {
                    const children = earthpixi.resources[resourceName].children;

                    if (children && children.length > 0)
                    {
                        for (let c = 0; c < children.length; c++)
                        {
                            if (children[c].url)
                            {
                                earthpixi.savedBaseTextures[children[c].url] = true;
                            }
                        }
                    }
                }
            }
        }

        if (earthpixi.oldScreen)
        { this.recurseOldChildren(earthpixi.oldScreen); }

        this.saveBaseTextures(earthpixi.stageOverlay);

        for (const property in PIXI.utils.TextureCache)
        {
            if (PIXI.utils.TextureCache[property])
            {
                if (earthpixi.savedBaseTextures[PIXI.utils.TextureCache[property].baseTexture.imageUrl] == null)
                {
                    PIXI.utils.TextureCache[property].destroy(true);
                }
            }
        }

        // PIXI.utils.clearTextureCache(); // this destroys saved base textures as well
        PIXI.utils.TextureCache = {};

        if (earthpixi.renderer.plugins
      && earthpixi.renderer.plugins.sprite
      && earthpixi.renderer.plugins.sprite.sprites)
        {
            earthpixi.renderer.plugins.sprite.sprites.length = 0;
        }

        if (earthpixi.renderer.rootRenderTarget
      && earthpixi.renderer.rootRenderTarget.filterData
        )
        {
            earthpixi.renderer.rootRenderTarget.filterData = null;
            earthpixi.renderer.rootRenderTarget.filterStack = null;
        }
    },

    // go through children and turn them off, so if the renderer tries to do a render after we've purged all the textures its ok!
    recurseOldChildren(container, destroy)
    {
        for (let i = 0; i < container.children.length; i++)
        {
            const child = container.children[i];

            child.interactive = false;
            child.renderable = false;
            child.visible = false;
            child.filters = null;
            child.screen = null;
            child.autoUpdate = false;// spines
            if (child.kill)
            {
                child.kill(); // particles
            }

            if (child.tweens)
            {
                for (let t = 0; t < child.tweens.length; t++)
                {
                    child.tweens[t].kill();
                }
            }
            child.tweens = [];

            if (child.texture != null && child.texture.baseTexture.hasLoaded)
            {
                if (child.texture.baseTexture.imageUrl != null)
                { earthpixi.savedBaseTextures[child.texture.baseTexture.imageUrl] = true; }
            }
            // //bitmap texts and others
            // if(child._texture!=null && child._texture.baseTexture!=null && child._texture.baseTexture.hasLoaded){
            //   if(child._texture.baseTexture.imageUrl!=null)
            //     earthpixi.savedBaseTextures[child._texture.baseTexture.imageUrl] = true;
            // }

            if (child.children && child.children.length > 0)
            {
                this.recurseOldChildren(child);
            }
        }
    },

    saveBaseTextures(container)
    {
        for (let i = 0; i < container.children.length; i++)
        {
            const child = container.children[i];

            if (child.texture != null && child.texture.baseTexture != null && child.texture.baseTexture.hasLoaded)
            {
                if (child.texture.baseTexture.imageUrl != null)
                { earthpixi.savedBaseTextures[child.texture.baseTexture.imageUrl] = true; }
            }
            // //bitmap texts
            // if(child._texture!=null && child._texture.baseTexture!=null && child._texture.baseTexture.hasLoaded){
            //   if(child._texture.baseTexture.imageUrl!=null)
            //     earthpixi.savedBaseTextures[child._texture.baseTexture.imageUrl] = true;
            // }

            if (child.children && child.children.length > 0)
            {
                this.saveBaseTextures(child);
            }
        }
    }
};
