export default function (timestamp)
{
    if (earthpixi.needsResize)
    {
        earthpixi.onresize();
        earthpixi.needsResize = null;
    }

    for (let i = 0; i < earthpixi.updateItems.length; i++)
    {
        earthpixi.updateItems[i].update(timestamp, timestamp * 0.01666);
    }

    earthpixi.application.render();
}
