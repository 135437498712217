export default class DwellAnimation extends PIXI.Container
{
    constructor(radius)
    {
        super();

        this.rad = radius;
        this.scaledRad = radius;
        this.offSet = 0;
        this.interactive = false;
        this.interactiveChildren = false;
        this.mainTween = null;
    }

    show(button, direct = false, delay = 0)
    {
        // console.log(button, direct);

        if (
            button.parent && button.visible
            && (button instanceof PIXI.Sprite ? button._texture : true)
        )
        {
            this.currentSprite = button;

            // console.log(direct);

            if (direct)
            {
                earthpixi.Access.trackDirectDwellAnimation(this);
            }
            else
            {
                this.currentSprite.parent.addChild(this);
            }

            this.calcScale();
        }
    }

    hide()
    {
        if (this.mainTween)
        {
            earthpixi.Tween.kill(this.mainTween);
        }
        if (this.parent)
        {
            this.parent.removeChild(this);
        }
    }

    onSelect(button)
    {
        this.hide();

        if (button && button.interactive)
        {
            const interactionManager = earthpixi.renderer.plugins.interaction;

            if (button.eyeGazeAble)
            {
                interactionManager.eventData.data.currentTarget = button;
                interactionManager.eventData.data.eyeGaze = true;
                interactionManager.dispatchEvent(button, "pointerup", interactionManager.eventData);
                earthpixi.Access.eyeGazeItemWasHit(button);

                return;
            }

            // normal interactive sprite in direct mode
            if (button._events.pointerdown)
            {
                interactionManager.eventData.data.currentTarget = button;
                interactionManager.eventData.data.eyeGaze = true;
                interactionManager.dispatchEvent(button, "pointerdown", interactionManager.eventData);
            }

            if (button._events.pointerup)
            {
                interactionManager.eventData.data.currentTarget = button;
                interactionManager.eventData.data.eyeGaze = true;
                interactionManager.dispatchEvent(button, "pointerup", interactionManager.eventData);
            }
        }

        this.emit("select");
    }

    calcScale()
    {
        const w = this.rad * 2;

        if (Math.abs(this.currentSprite.scale.x) === 1)
        {
            if (this.currentSprite.width < w)
            {
                this.scale.set(0.5, 0.5);
                this.offSet = this.rad / 2;
            }
            else
            {
                this.scale.set(1, 1);
                this.offSet = this.rad;
            }
        }
        else
        {
            const shrinkScale = Math.abs(this.currentSprite.scale.x) < 1 ? Math.abs(this.currentSprite.scale.x) : 1;
            const targetWidth = this.currentSprite.width / shrinkScale;
            const targetScale = (w / this.currentSprite.scale.x) / w;
            const origScale = this.currentSprite.scale.x;

            if (targetWidth < w)
            {
                this.scale.set(targetScale / 2);
            }
            else
            {
                this.scale.set(targetScale < 1 ? targetScale : 1);
            }

            this.currentSprite.scale.set(1);
            this.currentSprite.scale.set(origScale);
        }

        let localX; let
            localY;

        localX = (this.currentSprite.width - this.width) / 2;
        localY = (this.currentSprite.height - this.height) / 2;

        if (this.currentSprite.anchor)
        {
            localX -= (this.currentSprite.anchor.x * this.currentSprite.width);
            localY -= (this.currentSprite.anchor.y * this.currentSprite.height);
        }

        this.scaledRad = this.rad * this.scale.x;

        this.x = localX + this.currentSprite.x;
        this.y = localY + this.currentSprite.y;
    }
}
