export default class TestButton extends PIXI.Sprite {
    constructor(){
        super()
        console.log('TEST: instantiating test button')
        PIXI.Sprite.prototype.on.call(this, "pointerover", this.onPointerOver, this);
        PIXI.Sprite.prototype.on.call(this, "pointerout", this.onPointerOut, this);
        this.init()
    }

    bgFill = null
    indicator = null
    dwellFill = null
    dwellTimeout = null
    startDwellTimeout = null
    pointerOverStartTime = 0
    leaveIndicators = []
    leaveIndicatorParent = null

    init(){
        this.bgFill = new PIXI.Graphics()
        this.leaveIndicatorParent = new PIXI.Container()
        
        this.bgFill.beginFill(0xffffff, 1)
        this.bgFill.drawRect(0, 0, 500, 500)
        // this.bgFill.endFill()
        this.bgFill.cacheAsBitmap = true
        this.bgFill.height = 500
        this.bgFill.width = 500

        this.addChild(this.bgFill)
        this.addChild(this.leaveIndicatorParent)
        this.interactive = true


    }

    startDwell(){
        console.log('TEST: starting dwell!')
        this.dwellFill = new PIXI.Graphics()
        this.dwellFill.beginFill(0x909010, 1)
        this.dwellFill.drawCircle(0, 0, 40)
        this.addChild(this.dwellFill)

        // setInterval(() => {})
        this.dwellTimeout = setTimeout(() => {
            // handle click
            console.log('TEST: doing click')
            this.click()
            // this.pointerOverStartTime = 0
            // this.dwellFill.destroy()
            this.removeDwell()
        }, 1800)
    }

    removeDwell(){
        console.log('TEST: removing dwell ', this.dwellFill)
        if(this.dwellFill){
            // this.removeChild(this.dwellFill)
            this.dwellFill.destroy && this.dwellFill.destroy()
            this.dwellFill = null
        }
        console.log('TEST: END DWELL')
    }

    pointerUpdater = {
        0: (evt) => {
            console.log('TEST: starting pointer over')
            this.pointerOverStartTime = Date.now()

            console.log('TEST: updated pointer over time: ', this.pointerOverStartTime)

            this.startDwellTimeout = setTimeout(() => {
                console.log('TEST: should do pointer event ', this.startPointerOverEvent)
                this.startPointerOverEvent(evt)
            }, 100)
        }
    }

    click(){
        console.log('TEST: trigger click!')
    }

    addIndicator(){
        if(!this.indicator){
            this.indicator = new PIXI.Graphics()
            this.indicator.beginFill(0x901090, 1)
            // this.indicator.drawCircle(125, 125, 50)
            this.indicator.drawCircle(0, 0, 50)
            this.addChild(this.indicator)
        }
    }

    addLeaveIndicator(evt){
        console.log('POE: ', evt)
        // this.leaveIndicatorParent 
        // && this.leaveIndicatorParent.removeChildren 
        // && this.leaveIndicatorParent.removeChildren ()

        let lInd = new PIXI.Graphics()
        lInd.beginFill(0x0BB6C7, 1)
        lInd.drawCircle(0, 0, 50)
        this.leaveIndicators.push(lInd)
        // this.leaveIndicatorParent.addChild(lInd)
        this.addChild(lInd)
        // lInd.moveTo(evt.data.global.x, evt.data.global.y)
    }

    removeIndicator(){
        console.log('TEST: removing indicator ', this.indicator)
        if(this.indicator){
            // this.removeChild(this.indicator)
            this.indicator.destroy()
            this.indicator = null
        }
        console.log('...')
    }

    _onPointerOver = (evt) => {
        // prevent pointer out from being triggered
        if(this.pointerOutTimeout !== null){
            console.log('TEST: clearing pointer out')
            clearTimeout(this.pointerOutTimeout)
        }

        this.addIndicator()

        console.log('TEST: pointer over start time is: ', this.pointerOverStartTime)

        // if pointer start is 0 start a dwell phase
        typeof this.pointerUpdater[ this.pointerOverStartTime ] === 'function' 
            && this.pointerUpdater[ this.pointerOverStartTime ](evt)
    }

    onPointerOver(evt){
        console.log('TEST: on pointer over: ', evt)
        window.requestAnimationFrame(this._onPointerOver)
    }

    startPointerOverEvent(evt){
        console.log('TEST: startPointerOverEvent')
        this.startDwell()
    }

    startPointerOutEvent(evt){
        console.log('TEST: triggering pointer out: ', evt)

        this.dwellFill && this.removeDwell()
        this.indicator && this.removeIndicator()
        //

        this.pointerOverStartTime = 0
        console.log('TEST: midway through startPointerOutEvent')
     
        clearTimeout(this.startDwellTimeout)
        this.startDwellTimeout = null
     
        clearTimeout(this.dwellTimeout)
        this.dwellTimeout = null

        // ad leave indicator
        // this.addLeaveIndicator(evt)   
    }

    onPointerOut(evt){
        console.log('TEST: on pointer out: ', evt)
        // if
        this.pointerOutTimeout = setTimeout(() => {
            this.startPointerOutEvent(evt)
        }, 300)
    }
}

/**
1. onPointerMove
2. processInteractive -> func === this.processPointerMove
3. processPointerMove
    - processPointerOverOut
    - mouseOverRenderer

    Accessibility manager requires 
        - core.js which requires interaction manager
            - includes all the renderers (webgl and canvas)

 */