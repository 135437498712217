/**
 *
 * Config properties you can set before calling earthpixi.init()
 *
 * @namespace earthpixi.config
 */
export default {
    /**
     * Stage width to relate x positions of objects on the stage. For example you designed your game to 1366 x 768 (@1x) and 2732 x 1536 (@2x)
     *
     * @static
     * @memberof earthpixi.config
     * @type {number}
     * @default 1366
     */
    STAGE_WIDTH: 1366,

    /**
     * Stage height to relate y positions of objects on the stage. For example you designed your game to 1366 x 768 (@1x) and 2732 x 1536 (@2x)
     *
     * @static
     * @memberof earthpixi.config
     * @type {number}
     * @default 768
     */
    STAGE_HEIGHT: 768,

    /**
     * If you need to have overspill of graphics outside the Stage width without repositioning (like Nessy updating games with wide backgrounds)
     *
     * @static
     * @memberof earthpixi.config
     * @type {number}
     * @default 768
     *
     */
    RENDERER_WIDTH: null,

    /**
     * @static
     * @memberof earthpixi.config
     * @type {number}
     * @default 1
     */
    RESIZE_MODE: 1,

    /**
     * @static
     * @memberof earthpixi.config
     * @type {object}
     * @property {number} WINDOW Resize to window size
     * @property {number} DIV Resize to container div element specified in earthpixi.init()
     */
    RESIZE_MODES: {
        WINDOW: 1,
        DIV: 2
    },

    /**
     * The fit mode for the app
     * @memberof earthpixi.config
     * @type {number}
     * @default 1
     */
    FIT_MODE: 1,
    /**
     * @memberof earthpixi.config
     * @default 1
     * @property {number} FIXED_HEIGHT Resize to window with fixed stage height (trim edges)
     * @property {number} FIXED_WIDTH Resize to window with fixed stage width (trim top and bottom)
     *
     */
    FIT_MODES: {
        FIXED_HEIGHT: 1,
        FIXED_WIDTH: 2,
    },

    /**
     * If you want textures in TextureCache to get overwritten by new textures of same name, when loading assets
     *
     * @static
     * @memberof earthpixi.config
     * @type {boolean}
     * @default false
     */
    LOADING_OVERWRITE: false,

    /**
     * If you want to override th automatic resolution which earthpixi chooses,  set to 1 or 2
     *
     * @static
     * @memberof earthpixi.config
     * @type {number}
     * @default null
     */
    FORCE_RES: null,

    /**
     * If you want to show a Grid and screen co-ords when developing the app
     *
     * @static
     * @memberof earthpixi.config
     * @type {boolean}
     * @default false
     */
    SHOW_GRID: false,

    /**
     * Do you want to show a Mr Doob stats.js
     *
     * @static
     * @memberof earthpixi.config
     * @type {boolean}
     * @default false
     */
    SHOW_STATS: false,

    /**
     * Time in seconds for the default fade in/ fade out of screens and transitions
     *
     * @static
     * @memberof earthpixi.config
     * @type {number}
     * @default 0.5
     */
    FADE_TIME: 0.5,

    /**
     * Default background colour of stage when you initiate earthpixi.
     *
     * @static
     * @memberof earthpixi.config
     * @type {number}
     * @default 0x000000
     */
    BACKGROUND_COLOR: 0x000000,

    /**
     * Set to true to make the pixi canvas transparent, so any html body background colour is visible behind.
     *
     * @static
     * @memberof earthpixi.config
     * @type {boolean}
     * @default false
     */
    TRANSPARENT_STAGE: false,

    /**
     * Set to true to force using Canvas instead of WebGL renderer.. will probably break some things in earthpixi
     *
     * @static
     * @memberof earthpixi.config
     * @type {boolean}
     * @default false
     */
    FORCE_CANVAS: false,

    TARGET_FPS: 61,

    /**
     * Helper Method you can set which is called after earthpixi.init(). Set with earthpixi.config.customize = function(){...}
     *
     * @static
     * @memberof earthpixi.config
     * @function customize
     * @default null
     */
    customize: null,

    /**
     * default path to image/spritesheets to use
     * @static
     * @memberof earthpixi.config
     * @type {string}
     * @default "./"
     */
    IMG_ROOT: "./",

    /**
     * default path to sound files to use
     * @static
     * @memberof earthpixi.config
     * @type {string}
     * @default "./"
     */
    AUDIO_ROOT: "./",

    /**
     * default path to video files to use
     * @static
     * @memberof earthpixi.config
     * @type {string}
     * @default "./"
     */
    VIDEO_ROOT: "./",

    /**
     * default path to animation files to use
     * @static
     * @memberof earthpixi.config
     * @type {string}
     * @default "./"
     */
    SPINE_ROOT: "./",

    /**
     * default path to json or data files to use
     * @static
     * @memberof earthpixi.config
     * @type {string}
     * @default "./"
     */
    DATA_ROOT: "./",
    /**
     * default path to font files to use
     * @static
     * @memberof earthpixi.config
     * @type {string}
     * @default "./"
     */
    FONT_ROOT: "./"

};
