import "babel-polyfill";
import PIXI from "../libs/pixi";
import spine from "../libs/spine/pixi-spine.js";
// import spine from "pixi-spine/bin/pixi-spine.js";

import TweenMax from "../libs/greensock-js-business-green/src/uncompressed/TweenMax";
import ColorPropsPlugin from "../libs/greensock-js-business-green/src/uncompressed/plugins/ColorPropsPlugin";
import Physics2DPlugin from "../libs/greensock-js-business-green/src/uncompressed/plugins/Physics2DPlugin";
import PhysicsPropsPlugin from "../libs/greensock-js-business-green/src/uncompressed/plugins/PhysicsPropsPlugin";
import GSAPPixiPlugin from "../libs/greensock-js-business-green/src/uncompressed/plugins/PixiPlugin";

// import CSSPlugin from "../libs/greensock-js-business-green/src/uncompressed/plugins/CSSPlugin";
// import ThrowPropsPlugin from "../libs/greensock-js-business-green/src/uncompressed/plugins/ThrowPropsPlugin";
// import Draggable from "../libs/greensock-js-business-green/src/uncompressed/utils/Draggable";

import particles from "pixi-particles/dist/pixi-particles";
import * as filters from "pixi-filters";
import mutistyetext from "pixi-multistyle-text";
import hammer from "hammerjs";
import device from "ismobilejs";

import earthpixi from "./earthpixi";
/**
 * @module earthpixi
 */
export { earthpixi };

// couldn't get all filters to come up
// console.log(filters);
// console.log(global.PIXI.filters);
// Object.assign(filters, global.PIXI.filters);
global.PIXI.filters = Object.assign(global.PIXI.filters, filters);

global.PIXI.MultiStyleText = mutistyetext;

// override PIXI stuff
let saidHello = false;

global.PIXI.utils.sayHello = function (type)
{
    if (saidHello)
    {
        return;
    }

    const version = _VERSION;

    let str = "";

    window.console.log(
        `${str}ep v${version} | Made with PIXI.js http://www.pixijs.com/ | `
    + `Resolution: @${earthpixi.resolution}x | `
    + `aa: ${earthpixi.rendererOptions.antialias} | ${
    // 'Target FPS: ' + earthpixi.config.TARGET_FPS + '| ' +
        type}`
    );
    saidHello = true;
};

// get rid of PIXI texture cache warnings
global.PIXI.Texture.addToCache = function (texture, id)
{
    if (id)
    {
        if (global.PIXI.utils.TextureCache[id] && !earthpixi.config.LOADING_OVERWRITE)
        {
            return;
            // console.warn('Texture added to the cache with an id [' + id + '] that already had an entry');
            // return;
        }

        if (texture.textureCacheIds.indexOf(id) === -1)
        {
            texture.textureCacheIds.push(id);
        }

        global.PIXI.utils.TextureCache[id] = texture;
    }
};

global.PIXI.BaseTexture.addToCache = function (baseTexture, id)
{
    if (id)
    {
        if (global.PIXI.utils.BaseTextureCache[id] && !earthpixi.config.LOADING_OVERWRITE)
        {
            return;
            // console.warn('Texture added to the cache with an id [' + id + '] that already had an entry');
        }

        if (baseTexture.textureCacheIds.indexOf(id) === -1)
        {
            baseTexture.textureCacheIds.push(id);
        }

        global.PIXI.utils.BaseTextureCache[id] = baseTexture;
    }
};

global.PIXI.Spritesheet.prototype._updateResolution = function _updateResolution(resolutionFilename)
{
    const scale = parseFloat(this.data.meta.scale);

    // Use a defaultValue of `null` to check if a url-based resolution is set
    let resolution = (0, global.PIXI.utils.getResolutionOfUrl)(resolutionFilename, null);

    // No resolution found via URL
    if (resolution === null)
    {
        // Use the scale value or default to 1
        resolution = scale !== undefined ? parseFloat(scale) : 1;
    }
    else
    {
        // ep hack, use the tp meta scale

        // if @res is 2, expect ts sheet scale to be 1;
        // if @res is 1, expect ts sheet scale to be 0.5;
        const expectedSheetScale = resolution / 2;

        // if (resolution === 1)
        // {
        //     //eg sheet scale = 0.25 @1x
        //     // 0.25 / 0.5 * 1 = 0.5;
        //     // (scale / expectedScale) * resolution = 0.5
        //     fixScale = resolution * scale;
        // }
        //
        // if (resolution === 2)
        // {
        //     //eg sheet scale 0.5 @2x
        //     fixScale = resolution * scale;
        //     // 2 x 0.5 = 1;
        //     // resolution x scale = 1
        //     // 0.5 / expectedScale
        // }
        resolution = (scale / expectedSheetScale) * resolution;
    }

    // For non-1 resolutions, update baseTexture
    // if (resolution !== 1)
    // {
    this.baseTexture.resolution = resolution;
    this.baseTexture.update();
    // }

    return resolution;
};
