import scaleRenderer from "./scaleRenderer";

export default function ()
{
    let scale; let width; let height; let oW; let
        oH;

    if (earthpixi.resizePreFix)
    {
        earthpixi.resizePreFix();
    }

    // limit renderer size on safari 4096 max

    if (earthpixi.container)
    {
        oW = earthpixi.container.offsetWidth >= 2048 && earthpixi.resolution === 2 ? 2048 : earthpixi.container.offsetWidth;
        oH = earthpixi.container.offsetHeight;
    }
    else
    {
        oW = window.innerWidth >= 2048 && earthpixi.resolution === 2 ? 2048 : window.innerWidth;
        oH = window.innerHeight;
    }

    if (earthpixi.config.FIT_MODE === earthpixi.config.FIT_MODES.FIXED_HEIGHT)
    {
    // Fixed height

        if (oH > oW)
        {
            // portrait
            scale = oW / (earthpixi.config.RENDERER_WIDTH || earthpixi.config.STAGE_WIDTH);
            width = oW;
            height = earthpixi.config.STAGE_HEIGHT * scale;
        }
        else
        {
            // landscape
            scale = oH / earthpixi.config.STAGE_HEIGHT;
            width = (earthpixi.config.RENDERER_WIDTH || earthpixi.config.STAGE_WIDTH) * scale;
            height = oH;
        }
    }
    // Fixed width
    else if (oH > oW)
    {
        // portrait
        scale = oW / (earthpixi.config.RENDERER_WIDTH || earthpixi.config.STAGE_WIDTH);
        width = oW;
        height = earthpixi.config.STAGE_HEIGHT * scale;
    }
    else
    {
        // limit the canvas width to safe area (so super wide is not cutting off too much top/bottom)
        if (oW / oH > 1.6)
        {
            oW = oH * 1.6;
        }

        // landscape
        scale = oW / (earthpixi.config.RENDERER_WIDTH || earthpixi.config.STAGE_WIDTH);
        width = oW; // earthpixi.config.STAGE_WIDTH*scale;
        height = earthpixi.config.STAGE_HEIGHT * scale;
    }

    scaleRenderer(oW, oH, Math.ceil(width), Math.ceil(height), scale);

    if(earthpixi.currentScreen && earthpixi.currentScreen.parent){
        earthpixi.currentScreen.emit("resize");
    }
}

