/**
 * Set a mask on the stage if objects are spilling over the edges (this is possibly obsolete now with new resizing)
 *
 * @memberof earthpixi
 * @function earthpixi#setMask
 * @param {PIXI.Rectangle} rect - Rectangle to make mask
 */
export default function setMask(rect = new PIXI.Rectangle(0, 0, earthpixi.config.STAGE_WIDTH, earthpixi.config.STAGE_HEIGHT))
{
    if (earthpixi.mask != null)
    {
        earthpixi.stageOverlay.removeChild(earthpixi.mask);
        earthpixi.mask.destroy();
    }

    earthpixi.mask = new PIXI.Container();

    const edge = (rect.width - earthpixi.config.STAGE_WIDTH) / 2;

    rect.x = -edge;
    earthpixi.utils.Display.maskOverride = rect;

    let tex = new PIXI.Graphics();

    tex.beginFill(0xFFFFFF, 1);
    tex.drawRect(0, 0, 128, 128);
    tex = earthpixi.renderer.generateTexture(tex, PIXI.settings.SCALE_MODE, earthpixi.resolution);

    const left = earthpixi.mask.left = new PIXI.Sprite(tex);

    left.x = -512;
    left.y = -100;
    left.width = 512 - edge;
    left.height = earthpixi.config.STAGE_HEIGHT + 256;
    left.tint = earthpixi.config.BACKGROUND_COLOR;
    earthpixi.mask.addChild(left);

    const right = earthpixi.mask.right = new PIXI.Sprite(tex);

    right.x = earthpixi.config.STAGE_WIDTH + edge;
    right.y = -100;
    right.width = 512;
    right.height = earthpixi.config.STAGE_HEIGHT + 256;
    right.tint = earthpixi.config.BACKGROUND_COLOR;
    earthpixi.mask.addChild(right);

    earthpixi.stageOverlay.addChild(earthpixi.mask);
}
