import playButtonSVG from "./playButtonSVG";
/**
 *
 * Creates HTML Video player and places it on top of PIXI element, then removes it when finished
 *
 * Good for playing intro videos on tablets/ios.
 *
 * ```js
 * let htmlVidElement = earthpixi.Video.play("./vid.mp4",this.onIntroPlayed, this.onIntroClosed, this);
 * ```
 *
 *
 * @namespace earthpixi.Video
 * @static
 * @memberof earthpixi
 */
export default class VideoPlayer
{
    /**
     * @private
     */
    constructor()
    {
        this.videoElement = document.getElementById("videoplayer");

        if (this.videoElement === null)
        {
            this.videoElement = document.createElement("video");
            const src = document.createElement("source");

            src.src = "";
            src.type = "video/mp4";
            this.videoElement.appendChild(src);
        }

        // this.videoElement.style.display = "none";
        this.videoElement.style.width = "100%";
        this.videoElement.style.height = "100%";
        this.videoElement.style.position = "absolute";
        this.videoElement.setAttribute("autoplay", "true");
        this.videoElement.setAttribute("preload", "true");
        // this.videoElement.setAttribute("poster", "no_poster");
        this.videoElement.setAttribute("playsinline", "true");

        document.body.appendChild(this.videoElement);

        this.vidEndCallBack = this.onVidEnd.bind(this);
        this.videoElement.addEventListener("ended", this.vidEndCallBack);
    }

    /**
     *
     * Play a video file on top of PIXI, video closes when finished. Returns HTML Video Element
     *
     *
     * @function earthpixi.Video#play
     * @static
     * @param {string} url
     * @param {method} [onComplete] method to call when video ends
     * @param {method} [onClose] method to call when video is closed by user
     * @param {object} [scope] specify the scope to call the onComplete and onClose
     * @returns {HTMLVideoElement}
     */
    play(url, onComplete, onClose, scope)
    {
        this._completeScope = scope;

        const sources = this.videoElement.getElementsByTagName("source");

        sources[0].src = url;
        this.videoElement.style.display = "block";
        this.videoElement.style.backgroundColor = "#000000";
        this.videoElement.style.objectFit = "cover";
        this.videoElement.load();

        const promise = this.videoElement.play();
        const vidEl = this.videoElement;

        if (promise !== undefined)
        {
            promise.then(() =>
            {
                // Autoplay started!
                //console.log("autoplay");
            }).catch((error) =>
            {
                // Autoplay was prevented.
                //console.log("no autoplay");

                if (!this.playButton)
                {
                    const blob = new Blob([playButtonSVG], { type: "image/svg+xml" });
                    const url = URL.createObjectURL(blob);

                    this.setupPlayButton(url, "200px");
                }

                if (this.playButton)
                {
                    this.playButton.style.display = "block";
                }
                else
                {
                    vidEl.setAttribute("controls", "true");
                }
            });
        }

        if (onComplete !== null)
        {
            this.onSpecifiedComplete = onComplete;
        }
        else
        {
            this.onSpecifiedComplete = null;
        }
        if (onClose !== null)
        {
            this.onSpecifiedClose = onClose;
        }
        else
        {
            this.onSpecifiedClose = null;
        }

        if (this.closeButton)
        {
            this.closeButton.style.display = "block";
            // this.closeButton.style.top =  "30px";
            // this.closeButton.style.right =  "50px";
            // this.closeButton.style.zoom = (earthpixi.utils.Display.getResizeScale()*50) + "%";
        }

        return this.videoElement;
    }

    /**
     * Set an image to be used as a close button by the HTML Video player
     *
     * Places in top right corner of player
     *
     *
     *
     * @function earthpixi.Video#setupCloseButton
     * @static
     * @param {string} imgSrc
     * @param {number} [topMargin]
     * @param {number} [scale]
     */
    setupCloseButton(imgSrc, topMargin = 0, scale = 0.5)
    {
        if (!this.closeButton)
        {
            this.closeButton = document.createElement("img");
            this.closeButton.src = imgSrc;
            this.closeButton.style.display = "none";
            this.closeButton.style.position = "absolute";
            this.closeButton.style.top = `${30 + topMargin}px`;
            this.closeButton.style.right = "30px";
            // this.closeButton.style.zoom = (earthpixi.utils.Display.getResizeScale() * 100) + "%";
            this.closeButton.style.zoom = `${scale * 100}%`;
            document.body.appendChild(this.closeButton);

            this.onCloseButtonCallBack = this.onCloseButton.bind(this);
            this.closeButton.addEventListener("click", this.onCloseButtonCallBack);
        }
    }

    /**
     * Set an image to be used as a close button by the HTML Video player
     *
     * Places in top right corner of player
     *
     *
     *
     * @function earthpixi.Video#setupCloseButton
     * @returns {HTMLElement}
     * @static
     * @param {string} imgSrc
     * @param {string} [width]
     */
    setupPlayButton(imgSrc, width)
    {
        if (!this.playButton)
        {
            this.playButton = document.createElement("img");
            this.playButton.src = imgSrc;
            this.playButton.style.display = "none";
            this.playButton.style.position = "absolute";
            this.playButton.style.top = "50%";
            this.playButton.style.width = width;
            this.playButton.style.height = width;
            this.playButton.style.bottom = "50%";
            this.playButton.style.left = "50%";
            this.playButton.style.right = "50%";
            this.playButton.style.transform = "translate(-50%, -50%)";
            this.playButton.style.zoom = `${earthpixi.utils.Display.getResizeScale() * 100}%`;
            document.body.appendChild(this.playButton);

            this.onPlayButtonCallBack = this.onPlayButton.bind(this);
            this.playButton.addEventListener("click", this.onPlayButtonCallBack);
        }

        return this.playButton;
    }

    /**
     * @private
     */
    onVidEnd()
    {
        this.videoElement.style.display = "none";
        if (this.closeButton)
        {
            this.closeButton.style.display = "none";
        }
        if (this.onSpecifiedComplete)
        {
            if (this._completeScope)
            {
                this.onSpecifiedComplete.apply(this._completeScope, []);
            }
            else
            {
                this.onSpecifiedComplete();
            }
        }
        this.onSpecifiedComplete = null;
        this.onSpecifiedClose = null;
    }

    /**
     * @private
     * @param {Event} e
     */
    onCloseButton(e)
    {
        e.preventDefault();
        this.videoElement.pause();
        if (this.closeButton)
        {
            this.closeButton.style.display = "none";
        }
        if (this.playButton)
        {
            this.playButton.style.display = "none";
        }

        this.videoElement.style.display = "none";

        if (this.onSpecifiedClose)
        {
            if (this._completeScope)
            {
                this.onSpecifiedClose.apply(this._completeScope, []);
            }
            else
            {
                this.onSpecifiedClose();
            }
        }
    }

    /**
     * @private
     * @param {Event} e
     */
    onPlayButton(e)
    {
        //console.log("play!");
        e.preventDefault();
        const promise = this.videoElement.play();

        if (promise !== undefined)
        {
            promise.then(() =>
            {
                // Autoplay started!
                //console.log("play worked");
                this.playButton.style.display = "none";
            }).catch((error) =>
            {
                // Autoplay was prevented.
                //console.log(`play didn't work${error.message}`);
            });
        }
        else
        {
            //console.log("play promise undefined");
        }
    }
}
