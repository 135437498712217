export default function (timestamp)
{
    // at 60 fps, timestamp = 1
    // at 30 fps, timestamp = 2

    if (earthpixi.needsResize)
    {
        earthpixi.onresize();
        earthpixi.needsResize = null;
    }

    earthpixi.time += timestamp;
    earthpixi.elapsedTime = earthpixi.time * 0.01666; // time per frame running at 60fps

    // render when enough time has passed to meet framerate

    if (earthpixi.time >= earthpixi._fpsDelta)
    {
        for (let i = 0; i < earthpixi.updateItems.length; i++)
        {
            earthpixi.updateItems[i].update(earthpixi.time, earthpixi.elapsedTime);
        }

        if (earthpixi.gamepadConnected)
        {
            earthpixi.GamePad.poll();
        }

        earthpixi.time = 0;
        earthpixi.application.render();
        // earthpixi.application.ticker.update(earthpixi.ticker.elapsedTime);
    }
}
