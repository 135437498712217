/**
 *
 *
 * Just a PIXI.Container but we keep tabs on current tweens and update methods.
 *
 *
 * Example GameObject
 *
 * ```js
 * export default class MyGameObject extends earthpixi.GameObject {
 *
 *  constructor(){
 *   super();
 *
 *   // add stuff
 *
 *  }
 *
 *  //add an update method, and earthpixi will call it every frame if you add it to a screen
 *  update(delta, elapsedTime){
 *
 *
 *    //DELTA:
 *    //Running at 60fps,  delta = 1;
 *    //Running at 30fps,  delta = 2 (twice as much time passed between frames)
 *
 *    //Elapsed Time:
 *    //Running at 60fps,  elapsedTime = 0.01666 (time in seconds between frames)
 *    //Running at 30fps,  elapsedTime = 0.03332
 *
 *    //called each frame
 *    console.log(delta,elapsedTime)
 *  }
 * }
 *
 *
 * screen.addChild(new MyGameObject());
 *
 *
 *```
 *
 * @class
 * @extends {PIXI.Container}
 * @memberof earthpixi
 */
export default class GameObject extends PIXI.Container
{
    /**
     * @param {earthpixi.Screen} [screen] - optional specify screen the object is going in
     */
    constructor(screen)
    {
        super();

        /**
         *
         * @type {boolean}
         * @default false
         */
        this.dontDestroy = false;

        earthpixi.registerInstance(this);

        this.screen = screen || earthpixi.currentScreen;
        this.tweens = [];

        this.on("added", () =>
        {
            // remove from frame update items
            if (this.update)
            {
                earthpixi.addUpdateItem(this);
            }
        }, this);

        this.on("removed", () =>
        {
            // remove from frame update items
            earthpixi.removeUpdateItem(this);
        }, this);
    }

    /**
     *
     * @param {PIXI.DestroyOptions | boolean} [options]
     */
    destroy(options)
    {
        // console.log("destroyed",this);
        if (this.tweens)
        {
            for (let t = 0; t < this.tweens.length; t++)
            {
                earthpixi.Tween.kill(this.tweens[t]);
            }
        }
        earthpixi.removeUpdateItem(this);
        this.tweens = null;
        this.screen = null;
        super.destroy(options);
    }

    pauseTweens(pause)
    {
        if (pause)
        {
            for (let t = 0; t < this.tweens.length; t++)
            {
                this.tweens[t].pause();
            }
        }
        else
        {
            for (let t = 0; t < this.tweens.length; t++)
            {
                this.tweens[t].play();
            }
        }
    }
}
