import AccessibilityManager from "./accessibility/Accessibility";
import Accessibility from "./accessibility/index";
import audio from "./audio/Audio";
import GameObject from "./base/GameObject";
import LoadingScreen from "./base/LoadingScreen";
import Screen from "./base/Screen";
import SVGSprite from "./base/SVGSprite";
import config from "./config";
import clearMask from "./core/clearMask";
import init from "./core/init";
import setMask from "./core/setMask";
import setScreen from "./core/setScreen";
import GamePadController from "./input/GamePad";
import InputsController from "./input/InputsController";
import KeyboardController from "./input/Keyboard";
import Wheel from "./input/Wheel";
import utils from "./utils";
import AssetList from "./utils/AssetList";
import VideoPlayer from "./video";

let _audioInstance = null;
let _keyboardInstance = null;
let _gamePadInstance = null;
let _videoInstance = null;
let _hammerInstance = null;
let _inputsControllerInstance;
let _accessManageInstance;
let _wheelInstance;
let _fileInput;
let _epPaused = false;

/**
 *
 *
 * @namespace earthpixi
 */
export default {

    config,
    Screen,
    LoadingScreen,
    GameObject,
    SVGSprite,
    utils,
    init,
    setScreen,
    setMask,
    clearMask,
    Accessibility,

    // depreciations
    get Utils()
    {
        return utils;
    },

    get Audio()
    {
        if (!_audioInstance)
        {
            _audioInstance = new audio();
        }

        return _audioInstance;
    },

    /**
     * Is Audio context unlocked
     * @static
     * @memberof earthpixi
     * @type {boolean}
     */
    audioAwake: false,

    get Access()
    {
        if (!_accessManageInstance)
        {
            _accessManageInstance = new AccessibilityManager();
        }

        return _accessManageInstance;
    },

    get Wheel()
    {
        if (!_wheelInstance)
        {
            _wheelInstance = new Wheel();
        }

        return _wheelInstance;
    },

    get Keyboard()
    {
        if (!_keyboardInstance)
        {
            _keyboardInstance = new KeyboardController();
        }

        return _keyboardInstance;
    },

    get GamePad()
    {
        if (!_gamePadInstance)
        {
            _gamePadInstance = new GamePadController();
        }

        return _gamePadInstance;
    },

    get Video()
    {
        if (!_videoInstance)
        {
            _videoInstance = new VideoPlayer();
        }

        return _videoInstance;
    },

    get Input()
    {
        if (!_inputsControllerInstance)
        {
            _inputsControllerInstance = new InputsController();
        }

        return _inputsControllerInstance;
    },

    /**
     * Listen for Touch / Swipe Gestures (https://hammerjs.github.io)
     *
     * earthpixi.Hammer returns a Hammer instance to use on the current screen (gets reset when screen changed)
     *
     *
     * Hammer swipe example in a Screen: see Hammer docs for more info:
     *
     * ```js
     *
     * //Screen ready and faded in
     * run(){
     *
     *  // set 'swipe' gesture to just listen for horizontal swipes
     *  earthpixi.Hammer.get('swipe').set({direction: Hammer.DIRECTION_HORIZONTAL});
     *
     *  // listen for swipe gestures
     *  earthpixi.Hammer.on('swipe', this.onSwipe.bind(this));
     *
     * }
     *
     * onSwipe(evt){
     *
     *  //evt returns some swipe data to check direction
     *
     *  if (evt.deltaX > 0)
     *  {
     *
     *    this.moveRight();
     *
     *  } else {
     *
     *    this.moveLeft();
     *
     *  }
     *
     * }
     *
     *
     * ```
     *
     *
     *
     * @namespace earthpixi.Hammer
     * @returns {Hammer}
     *
     */
    get Hammer()
    {
        if (!_hammerInstance)
        {
            _hammerInstance = new Hammer(earthpixi.renderer.view);
        }

        return _hammerInstance;
    },

    /**
     *
     * Can use to replace Dom URL.createObject, but earthpixi cleans them up when screen changed, unless persistent is true.
     *
     * @memberof earthpixi
     * @param data
     * @param {boolean} [persistent=false]
     */
    createObjectURL(data, persistent = false)
    {
        // console.log(data);

        const newObjURL = URL.createObjectURL(data);

        if (!persistent)
        {
            this.objectURLs.push(newObjURL);
        }

        return newObjURL;
    },

    /**
     *
     * Create TweenMax tweens and timers that get cleared up automatically if screen changes.
     *
     * Loads of TweenMax plugins included in earthpixi. https://greensock.com/docs/TweenMax
     *
     * Examples
     *
     * ```js
     //tween position of sprite
     earthpixi.Tween.to(mySprite, 0.5, {x:200, y:200})

     //tween scale of sprite
     earthpixi.Tween.to(mySprite.scale, 1, {x:1.5, y:1.5, ease:Expo.easeOut})

     //tween y position, back and forth and repeat 5 times
     var bounceTween = earthpixi.Tween.to(mySprite, 1, {y:200, ease:Bounce.easeOut, repeat: 5, yoyo:true})

     //tween the speed of the bounce tween over time, slowing down the bounce to a stop
     earthpixi.Tween.to(bounceTween, 3,  {timeScale: 0, onComplete:earthpixi.Tween.kill, onCompleteParams:[bounceTween]}})

     //Create delayed call to a method,  much better than setTimeout
     let delayedCall = earthpixi.delayedCall(5, myFunc, ["param"], this);
     //returns a TweenMax object, useful for changing the timeScale or other properties on the fly

     ````

     Game Timers
     ```js
     //create empty tween to use as game timer, returns useful TweenMax object
     this.gameTimer = earthpixi.Tween.to(
     {},
     GAME_LENGTH_SECONDS,
     {onComplete:this.gameEnd, onCompleteScope:this, onUpdate:this.updateGame, onUpdateScope:this}
     );

     //pause it
     this.gameTimer.pause();
     //this.gameTimer.resume / this.gameTimer.restart() to restart game etc..

     //Run game at half the speed
     this.gameTimer.timeScale(0.5);
     for(var t=0 ; t<screen.tweens.length; t++){
      screen.tweens[t].timeScale(0.5);
   }

     ```

     Other Game Loops
     ```js
     //run gameUpdate every 250 milliseconds
     this.gameLoop = earthpixi.Tween.to({}, 0.25, {repeat: -1,onRepeat:this.gameUpdate, onRepeatScope:this});
     //or use frames, update every 20 frames (browser requestAnimation frames)
     this.gameLoop = earthpixi.Tween.to({}, 20, {useFrames:true,repeat: -1,onRepeat:this.gameUpdate, onRepeatScope:this});

     ```

     *
     *
     *
     * @namespace earthpixi.Tween
     *
     *
     */
    Tween: {
        /**
         * @memberof earthpixi.Tween
         * @param {Object} config
         * @returns {TimeLineMax}
         */
        TimeLine(config)
        {
            const tl = new TimelineMax(config);

            earthpixi.currentScreen.tweens.push(tl);

            return tl;
        },

        /**
         * @memberof earthpixi.Tween
         * @param target
         * @param time
         * @param props
         * @returns {TweenMax}
         */
        to(target, time, props)
        {
            const tween = new TweenMax(target, time, props);

            earthpixi.currentScreen.tweens.push(tween);

            return tween;
        },
        /**
         * Kill a tween properly, clearing it from TweenMax cache
         *
         * @memberof earthpixi.Tween
         * @param TweenMaxOrTweenedObject TweenMax to kill or object being tweened
         * @param {earthpixi.Screen} [screen] optionally specify screen otherwise uses current
         */
        kill(TweenMaxOrTweenedObject, screen)
        {
            if (!TweenMaxOrTweenedObject) return;

            if (!TweenMaxOrTweenedObject.target && !TweenMaxOrTweenedObject.timeline)
            {
                TweenMax.killTweensOf(TweenMaxOrTweenedObject, false);

                return;
            }

            const tweenmax = TweenMaxOrTweenedObject;
            const target = TweenMaxOrTweenedObject.target;

            tweenmax.invalidate();
            tweenmax.kill();

            const targetScreen = screen || earthpixi.currentScreen;

            if (!targetScreen)
            {
                return;
            }

            // remove from global tweens array
            const index = targetScreen.tweens.indexOf(tweenmax);

            if (index !== -1)
            {
                targetScreen.tweens[index] = null;
            }

            if (!tweenmax.target)
            {
                return;
            }

            // remove from TweenMax cache
            TweenMax.killTweensOf(target, false);

            tweenmax.target = null;
            let id = -1;

            for (const p in TweenLite._internals.tweenLookup)
            {
                if (TweenLite._internals.tweenLookup[p].target === target)
                {
                    id = p;
                }
            }
            if (id != -1)
            {
                TweenLite._internals.tweenLookup[id].target = null;
            }
        },

        /**
         *
         * Stop all tweens in current screen
         *
         * @param {earthpixi.Screen} [screen]
         * @memberof earthpixi.Tween
         */
        clearAll(screen)
        {
            const screenToClear = screen || earthpixi.currentScreen;

            if (!screenToClear)
            {
                return;
            }

            for (let t = 0; t < screenToClear.tweens.length; t++)
            {
                if (screenToClear.tweens[t] != null)
                {
                    earthpixi.Tween.kill(screenToClear.tweens[t]);
                }
            }
            screenToClear.tweens = [];
        },

        delayedCall(delay, callback, params, scope, useFrames)
        {
            const tween = TweenMax.delayedCall(delay, callback, params, scope, useFrames);

            earthpixi.currentScreen.tweens.push(tween);

            return tween;
        }
    },

    /**
     * Current resolution being used, either 1 or 2
     * @static
     * @memberof earthpixi
     * @type {number}
     */
    resolution: null,

    /**
     * Current PIXI renderer instance being used
     * @static
     * @memberof earthpixi
     * @type {PIXI.SystemRenderer}
     */
    renderer: null,

    /**
     * Current PIXI application instance created on init
     * @static
     * @memberof earthpixi
     * @type {PIXI.Application}
     */
    application: null,

    /**
     * Root Container of application
     * @static
     * @memberof earthpixi
     * @private
     * @type {PIXI.DisplayObject}
     */
    stage: null,

    /**
     * Permanent container that is on top of screen z. Add sprites to this that you want to stay on stage between loading different screens.
     *
     * @static
     * @memberof earthpixi
     * @type {PIXI.Container}
     */
    stageOverlay: null,

    /**
     * Permanent container that is below screen z. Add sprites to this that you want to stay on stage between loading different screens.
     *
     * @static
     * @memberof earthpixi
     * @type {PIXI.Container}
     */
    stageBackdrop: null,

    /**
     *
     * Get the current Screen on stage
     *
     * @static
     * @memberof earthpixi
     * @type {earthpixi.Screen}
     */
    currentScreen: null,

    /**
     * Set this flag to call a window resize on next frame updata, for instance if changing the fit mode
     *
     * @static
     * @memberof earthpixi
     * @type {bool}
     */
    needsResize: false,

    /**
     * @static
     * @memberof earthpixi
     * @type {bool}
     */
    gamepadConnected: false,

    /**
     * Current list of items that are called update() on each frame.
     *
     * @static
     * @see earthpixi.addUpdateItem
     * @memberof earthpixi
     * @type {array}
     */
    updateItems: [],

    // Private / internal tracking
    registeredGameObjects: [],

    objectURLs: [],

    pauseItems: [],

    resizeUpdateItems: [],

    /**
     *
     * @static
     * @memberof earthpixi
     * @type {array}
     */
    maskUpdateItems: [],

    wheelItems: [],

    screens: {
        assetLists: {}
    },

    /**
     * List of loader items to always add to your loading screen, so don't need to add them to evey asset list.
     * Need to be loading your screen assets with an earthpixi.LoadingScreen class.
     *
     * Example load this font for every screen
     * ```js
     * earthpixi.globalAssetsList.push({name: 'sweater_semi', url: earthpixi.config.FNT_ROOT + 'sweater_semi_bold' + earthpixi.resolution + "x.fnt"});
     * ```
     * @see earthpixi.LoadingScreen
     * @static
     * @memberof earthpixi
     * @type {Array}
     */
    globalAssetsList: new AssetList(),

    /**
     * List of your Loader resources available when a LoadingScreen is finished.
     * Need to be loading your screen assets with an earthpixi.Loading class.
     *
     * See boilerplate example
     *
     *
     * @see earthpixi.LoadingScreen
     * @static
     * @memberof earthpixi
     */
    resources: {},

    /**
     *  Add an object to earthpixi's list of things to call update() on each frame.
     *
     *  Object needs an update method.
     *
     *  Update will be called each frame until new Screen is set (update items clear when new screen set)
     *
     *  If you create a class that subclasses earthpixi.GameObject , and give it an update method, it gets added to the list automatically..
     *
     *  Usage:
     *  ```js
     *  myObject.update = (deltaTime, elapsedTime)=>{
     *    ...
     *  }
     *
     *  //call update each frame until next screen, or use removeUpdateItem()
     *  earthpixi.addUpdateItem(myObject);
     *
     *  ```
     * @memberof earthpixi
     * @param {Object} object
     */
    addUpdateItem: (object) =>
    {
        if (object.update)
        {
            if (earthpixi.updateItems.indexOf(object) === -1)
            {
                earthpixi.updateItems.push(object);
            }
        }
        else
        {
            // console.log('object already added, or object has no update method, cant be added to update items', object);
        }
    },

    /**
     *
     *  Remove object from earthpixi's list of things to call update() on each frame
     *
     *  Setting a new screen clears the update list also
     *
     *  Usage:
     *  ```js
     *  earthpixi.removeUpdateItem(myObject);
     *
     *  ```
     * @memberof earthpixi
     * @param {Object} object
     */
    removeUpdateItem: (object) =>
    {
        const index = earthpixi.updateItems.indexOf(object);

        if (index != -1)
        {
            earthpixi.updateItems.splice(index, 1);
        }
    },

    /**
     *  Add an object to earthpixi's list of things to call pause() on when calling earthpixi.pause().
     *  Object needs an 'pause(bool){...}' method.
     *  Pause items cleared when screen changed
     *
     *  Usage:
     *  ```js
     *  myObject.pause = (bool)=>{
     *    if(bool){
     *      //stop doing stuff
     *     }else{
     *      //resume doing stuff
     *     }
     *  }
     *
     *  earthpixi.addPauseItem(myObject);
     *
     *  ```
     * @memberof earthpixi
     * @param {Object} object
     */
    addPauseItem: (object) =>
    {
        if (object.pause && earthpixi.pauseItems.indexOf(object) == -1)
        {
            earthpixi.pauseItems.push(object);
        }
        else
        {
            // console.log('object already added, or object has no pause method, cant be added to update items');
        }
    },

    /**
     *  Remove  an object from earthpixi's list of things to call pause() on when calling earthpixi.pause().
     *
     *  Usage:
     *  ```js
     *  earthpixi.removePauseItem(myObject);
     *
     *  ```
     * @memberof earthpixi
     * @param {Object} object
     */
    removePauseItem: (object) =>
    {
        const index = earthpixi.pauseItems.indexOf(object);

        if (index != -1)
        {
            earthpixi.pauseItems.splice(index, 1);
        }
    },

    /**
     *
     * Experimental...  Scale speed of whole game, affects update(), Spine animations (using earthpixi.SpineEP) and Tweens.
     * Mostly using this to preview game animations slower, but could potentially use for a slow-mo game mode :)
     *
     * ```js
     *
     * //run game at half speed
     * earthpixi.timeScale = 0.5;
     *
     * //gradually speed up again over 2 seconds
     * earthpixi.Tween.to(earthpixi, 10, {timeScale:1})
     *
     * ```
     *
     * @member timeScale
     * @memberof earthpixi
     * @type {number}
     * @default 1.0
     */
    set timeScale(val)
    {
        earthpixi.ticker.speed = val;
        TweenMax.globalTimeScale(val);
    },

    get timeScale()
    {
        return earthpixi.ticker.speed;
    },

    /**
     * Sets stage colour and bgColor of html container if there is one.
     *
     * ```js
     * earthpixi.setStageColor(0x80d7ff);
     * ```
     *
     * @memberof earthpixi
     * @param {number} color
     */
    setStageColor: (color) =>
    {
        // console.log("here", color);
        earthpixi.config.BACKGROUND_COLOR = color;
        earthpixi.renderer.backgroundColor = color;
        earthpixi.stageCover.tint = earthpixi.config.BACKGROUND_COLOR;
        earthpixi.stageBG.tint = earthpixi.config.BACKGROUND_COLOR;
        if (earthpixi.container)
        {
            earthpixi.container.style.backgroundColor = PIXI.utils.hex2string(color);
        }

        if (earthpixi.mask)
        {
            earthpixi.mask.left.tint = earthpixi.config.BACKGROUND_COLOR;
            earthpixi.mask.right.tint = earthpixi.config.BACKGROUND_COLOR;
        }
    },

    /**
     * Pause / resume the application.  Pause all tweens, renderloop, sounds etc.  Done mostly automatically when browser window loses visibillity
     *
     * @memberof earthpixi
     * @param {boolean} pause pause true or false for resume
     *
     */
    pause(bool)
    {
        if (_epPaused == bool)
        {
            return;
        }

        // console.log("EP pause", bool);

        _epPaused = bool;

        earthpixi._pauseUpdate = bool;

        earthpixi.Audio.pauseAll(bool);
        PIXI.spine.Spine.globalAutoUpdate = !bool;

        for (let i = 0; i < earthpixi.pauseItems.length; i++)
        {
            const obj = earthpixi.pauseItems[i];

            obj.pause(bool);
        }

        bool ? TweenMax.pauseAll() : TweenMax.resumeAll();

        if (bool)
        {
            earthpixi.ticker.stop();
        }
        else
        {
            // earthpixi.ticker.update(earthpixi.time);
            earthpixi.ticker.start();
        }
    },

    /**
     *
     * Call a method after a time delay, same as TweenMax.delayedCall
     *
     * @memberof earthpixi
     * @param {number} delay in seconds
     * @param method
     * @param {array} [params]
     * @param {object} [scope]
     * @param {bool} [useFrames=false] use frames instead
     * @returns {TweenMax}
     */
    delayedCall(delay, method, params, scope, useFrames)
    {
        return this.DelayedCall(delay, method, params, scope, useFrames);
    },

    // depreciated
    DelayedCall(delay, method, params, scope, useFrames)
    {
        const tween = TweenMax.delayedCall(delay, method, params, scope, useFrames);

        earthpixi.currentScreen.tweens.push(tween);

        return tween;
    },

    /**
     *
     * Open file browser (must be from user input event like mouse)
     *
     * ```js
     *
     *  earthpixi.openFiles(this.onFilesLoad, ".mp3", true, this);
     *
     *  onFilesLoad(evt){
     *    console.log(evt.target.files);
     *  }
     *
     * ```
     *
     * @memberof earthpixi
     * @param onLoad
     * @param fileTypes
     * @param canSelectMultipleFiles
     * @param onLoadScope
     */
    openFiles(onLoad, fileTypes, canSelectMultipleFiles, onLoadScope, onCancel)
    {
        if (_fileInput)
        {
            document.body.removeChild(_fileInput);
        }
        _fileInput = document.createElement("input");
        _fileInput.setAttribute("type", "file");
        _fileInput.setAttribute("accept", fileTypes);
        _fileInput.setAttribute("multiple", canSelectMultipleFiles ? "multiple" : null);
        _fileInput.style.display = "none";
        document.body.appendChild(_fileInput);
        _fileInput.addEventListener("change", (evt) =>
        {
            // console.log("file dialog change", evt);
            const target = evt.target || evt.currentTarget;

            if (target && target.files && target.files.length > 0)
            {
                onLoad.apply(onLoadScope, [evt]);
            }
            else
            if (onCancel) onCancel.apply(onLoadScope, [evt]);
        });

        _fileInput.click();
    },

    registerInstance(gameobj)
    {
        earthpixi.registeredGameObjects.push(gameobj);
    },

    reset()
    {
        let i = 0;
        let saveItems;

        // clear anchors etc, save those from overlay
        saveItems = [];
        for (i = 0; i < earthpixi.resizeUpdateItems.length; i++)
        {
            if (
                earthpixi.resizeUpdateItems[i].parent
                && (earthpixi.resizeUpdateItems[i].parent === earthpixi.stageOverlay
                || earthpixi.resizeUpdateItems[i].parent === earthpixi.stageBackdrop
                || earthpixi.resizeUpdateItems[i].parent.dontDestroy)
            )
            {
                saveItems.push(earthpixi.resizeUpdateItems[i]);
            }
        }
        earthpixi.resizeUpdateItems = saveItems;

        // mask updates save those from overlay
        saveItems = [];
        for (i = 0; i < earthpixi.maskUpdateItems.length; i++)
        {
            if (
                earthpixi.maskUpdateItems[i].parent
                && (earthpixi.maskUpdateItems[i].parent === earthpixi.stageOverlay
                || earthpixi.maskUpdateItems[i].parent === earthpixi.stageBackdrop
                || earthpixi.maskUpdateItems[i].parent.dontDestroy)
            )
            {
                saveItems.push(earthpixi.maskUpdateItems[i]);
            }
        }
        earthpixi.maskUpdateItems = saveItems;

        // clean up any anon update functions
        for (i = 0; i < earthpixi.updateItems.length; i++)
        {
            if (!earthpixi.updateItems[i].dontDestroy)
            {
                earthpixi.updateItems[i].update = null;
            }
        }
        // reset pause items
        for (i = 0; i < earthpixi.pauseItems.length; i++)
        {
            if (!earthpixi.updateItems[i].dontDestroy)
            {
                earthpixi.updateItems[i].pause = null;
            }
        }

        // destroy all game objects that were removed from stage, but still registered
        for (i = 0; i < earthpixi.registeredGameObjects.length; i++)
        {
            const go = earthpixi.registeredGameObjects[i];

            if (!go.dontDestroy)
            {
                earthpixi.registeredGameObjects[i].destroy({ children: true });
            }
        }

        // revoke object urls created that aren't persistent
        for (i = 0; i < earthpixi.objectURLs.length; i++)
        {
            URL.revokeObjectURL(earthpixi.objectURLs[i]);
        }
        earthpixi.objectURLs = [];

        // reset utils
        if (_wheelInstance)
        {
            earthpixi.Wheel.reset();
        }

        if (_gamePadInstance)
        {
            earthpixi.GamePad.reset();
        }

        if (_accessManageInstance)
        {
            earthpixi.Access.hardReset();
        }

        if (_keyboardInstance)
        {
            earthpixi.Keyboard.reset();
        }

        if (_inputsControllerInstance)
        {
            earthpixi.Input.reset();
        }

        if (_hammerInstance)
        {
            earthpixi.Hammer.destroy();
            _hammerInstance = new Hammer(earthpixi.renderer.view);
        }

        earthpixi.registeredGameObjects = [];
        earthpixi.pauseItems = [];
        earthpixi.updateItems = [];
        earthpixi.wheelItems = [];

        earthpixi.Tween.clearAll();
        earthpixi.needsResize = true;
    }

};

// global.earthpixi = exports;
