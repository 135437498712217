/**
 * Tools for working with Spine Resources.
 *
 * If not using assets generated by Spine IDE, or you want to pack all your animation json, or spritesheets yourself you can manually create Spine animations
 *
 * Example creating a spine animation from your own assets
 *```js
 * //add spritesheets and animation json to assets to load in Screen:
 * assetsList.push({name: 'monkey_sprites1', url: "monkey_sprite_sheet1.json"})
 * assetsList.push({name: 'monkey_sprites2', url: "monkey_sprite_sheet2.json"})
 * //change spine json data extension to .spine to stop Spine loader from parsing any textures in it
 * assetsList.push({name: 'monkey_spine_animation', url: "monkey_animation.spine"})
 *
 *
 * //when loaded you can create a spine atlas
 * let monkeyAtlas = earthpixi.utils.createSpineAtlas(['monkey_sprites1', 'monkey_sprites2'], false);
 * let monkeyAnimation = earthpixi.utils.createSpineFromPreloaded(earthpixi.resources.monkey_spine_animation.data, monkeyAtlas, true);
 *```
 *
 * @memberof earthpixi.utils
 * @namespace earthpixi.utils.SpineUtils
 * @static
 */
export default {
    /**
   *
   * Set mipmaps on spine textures that have been loaded into resources (for Spine IDE generated resources)
   *
   * @static
   * @param {string} spineResourceName - name of the already loaded spine resource to be found in earthpixi.resources
   * @param {boolean} value - turn mipmaps on or not
   * @function earthpixi.utils.SpineUtils.setMipmap
   *
   */
    setMipmap(spineResourceName, value)
    {
        const pages = earthpixi.resources[spineResourceName].spineAtlas.pages;

        for (let i = 0; i < pages.length; i++)
        {
            pages[i].rendererObject.mipmap = value;
        }
    },

    /**
   * If not using Spine generated assets, you may need to create a spine atlas from a sprite sheet. For example pack all the spine textures with texture packer and once loaded, use this to create a Spine atlas to use with your spine animation.
   *
   *
   *
   *
   * @function earthpixi.utils.SpineUtils.createSpineAtlas
   * @param {Array} resourceNames - list or texture names that have already been loaded into resources
   * @param {boolean} [stripExtensions=true] - whether to strip ".png" off of the texture names, depending on Texture packing settings, this may need to be false depending on the Spine animation data
   * @returns {PIXI.spine.core.TextureAtlas}
   */
    createSpineAtlas(resourceNames, stripExtensions = true)
    {
        const texs = [];

        for (let i = 0; i < resourceNames.length; i++)
        {
            texs.push(earthpixi.resources[resourceNames[i]].textures);
        }

        const spineAtlas = new PIXI.spine.core.TextureAtlas();
        const allTextures = {};

        for (let t = 0; t < texs.length; t++)
        {
            for (const texName in texs[t])
            {
                if (texs[t].hasOwnProperty(texName))
                {
                    allTextures[texName] = texs[t][texName];
                }
            }
        }
        spineAtlas.addTextureHash(allTextures, stripExtensions || true);

        return spineAtlas;
    },

    /**
   * @function earthpixi.utils.SpineUtils.createSpineFromPreloaded
   * @param {(string | json)} rawSpineData
   * @param {PIXI.spine.SpineRuntime.Atlas} spineAtlas
   * @param {boolean} [parseJson=false] - if spine data is raw string, depending on how you loaded the json, parse it or not
   * @returns {Spine}
   */
    createSpineFromPreloaded(rawSpineData, spineAtlas, parseJson = false)
    {
        const attachmentParser = new PIXI.spine.core.AtlasAttachmentLoader(spineAtlas);
        const spineJsonParser = new PIXI.spine.core.SkeletonJson(attachmentParser);
        let skeletonData;

        if (parseJson == false)
        {
            skeletonData = spineJsonParser.readSkeletonData(rawSpineData);
        }
        else
        {
            skeletonData = spineJsonParser.readSkeletonData(JSON.parse(rawSpineData));
        }
        const spine = new PIXI.spine.Spine(skeletonData);

        spine.update(0);

        return spine;
    }

};

