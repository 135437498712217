export default class SwitchRowSelector
{
    constructor(filter)
    {
        this.maxPerRow = 8;
        this.maxRowHeight = 768 / 4;
        this.rowTolerence = 20;

        // New Keyboard debounce and hold down scan key

        // Access inits row selector

        // row cycles rows based on minPerRow and maxRow height selector chooses row

        // select row then cycles buttons in row, assume buttons dont overlap in a UI

        this.currentRow = -1;
        this.currentItem = -1;
        this._numItems = 0;
        this._overrideRowMode = false;

        this._init = false;
    }

    init()
    {
        if (this._init)
        { return; }

        this._init = true;

        const grp = new PIXI.Graphics();

        grp.beginFill(0xFFFFFF, 0.1);
        grp.drawRoundedRect(0, 0, 256, 256, 10);

        this.displayTex = earthpixi.renderer.generateTexture(grp, PIXI.settings.SCALE_MODE, earthpixi.resolution);
        this.displaySpr = new PIXI.Sprite(this.displayTex);

        this.displayGrp = new PIXI.Graphics();
        earthpixi.renderer.plugins.interaction.on("pointerdown", this.reset, this);
    }

    switch1Down(dirH = 0, dirV = 0, overrideRowMode = false)
    {
        const switchItems = earthpixi.Access.getAvailableSwitchButtons();

        if (!this._init)
        { return; }

        // console.log(dirH, dirV);
        // console.log(this.currentRow, this.currentItem);

        if (this.currentRow === -1 || this._numItems !== switchItems.length || this._overrideRowMode !== overrideRowMode)
        {
            // currently not selecting anything so init selecting
            // console.log("init selecting");
            this.currentItem = -1;
            this.initSelection(switchItems, true, overrideRowMode);
        }
        else
        {
            // we are in selecting state
            // console.log("already selecting");
            // tidy up previous selections
            if (this._overItem)
            {
                this.pointerOutItem(this._overItem);
                this._overItem = null;
            }

            // are we in a row?
            if (this.currentItem === -1)
            {
                // console.log("not in row");

                // no not inside a row, or just finished a row so
                this.currentRow += dirV === 0 ? 1 : dirV;

                if (this.currentRow === -1)
                {
                    this.currentRow = this.rows.length - 1;
                }

                // have we run out of rows? if so reset
                if (this.currentRow === this.rows.length)
                {
                    //   console.log("run out of rows, reset");
                    this.currentRow = -1;
                    this.currentItem = -1;
                    this.initSelection(earthpixi.Access.getAvailableSwitchButtons());

                    return;
                }

                // current row is valid, select it and init first item
                this.selectRow(this.rows[this.currentRow]);

                // if only one item select it automatically
                if (this.rows[this.currentRow].items.length === 1)
                {
                    this.currentItem = 0;
                    this.selectItem(this.rows[this.currentRow].items[this.currentItem]);
                }
            }
            else
            {
                // no dir specified, so just move normal switch right to left
                if (dirH === 0 && dirV === 0)
                {
                    //  console.log("no dir specified, move default right");
                    this.switchScanCurrentRow(1);
                }
                else
                {
                    //   console.log("here", dirH, dirV);

                    if (dirV !== 0)
                    {
                        if (this.rows.length > 1 && !overrideRowMode)
                        {
                            // cheat going to end of row before calling normal switch
                            this.currentItem = dirV === 1 ? this.rows[this.currentRow].items.length - 1 : 0;
                            this.switchScanCurrentRow(dirV);
                        }
                        else
                        {
                            // not in row selector mode, so there is only one row
                            //       console.log("here2", dirH, dirV);
                            this.currentItem = this.getItemIndexInDirection(0, dirV);
                            this.selectItem(this.rows[this.currentRow].items[this.currentItem]);
                        }
                    }

                    if (dirH !== 0)
                    {
                        this.currentItem = this.getItemIndexInDirection(dirH, dirV);
                        if (this.currentItem !== -1) this.selectItem(this.rows[this.currentRow].items[this.currentItem]);
                    }
                }
            }
        }
    }

    getItemIndexInDirection(dirH, dirV)
    {
        const items = this.rows[this.currentRow !== -1 ? this.currentRow : 0].items;
        const currentItem = items[this.currentItem !== -1 ? this.currentItem : 0];
        const currentBounds = currentItem.getBounds();

        let itemsInDir;
        let beamProp;

        if (dirH !== 0)
        {
            itemsInDir = dirH === 1
                ? items.filter((item) => item.getBounds().x > currentBounds.x)
                : items.filter((item) => item.getBounds().x < currentBounds.x);

            beamProp = "y";
        }

        if (dirV !== 0)
        {
            itemsInDir = dirV === 1
                ? items.filter((item) => item.getBounds().y > currentBounds.y)
                : items.filter((item) => item.getBounds().y < currentBounds.y);

            beamProp = "x";
        }

        if (!itemsInDir.length)
        {
            return this.currentItem;
        }

        if (itemsInDir.length > 1)
        {
            const beamSize = beamProp === "y" ? currentBounds.height : currentBounds.width;

            let found = null;
            let beamPadding = 0;

            while (!found)
            {
                // get items straight out in a beam the height/width of item, keep widening the band until found closest

                const foundItems = [];

                for (const item of itemsInDir)
                {
                    const itemBounds = item.getBounds();

                    if (
                        itemBounds[beamProp] < (currentBounds[beamProp] + beamSize + beamPadding)
                        && (itemBounds[beamProp] + (beamProp === "y" ? itemBounds.height : itemBounds.width)) > (currentBounds[beamProp] - beamPadding)
                    )
                    {
                        found = true;
                        foundItems.push(
                            {
                                item,
                                dist: earthpixi.utils.Geom.distance(
                                    { x: currentBounds.x + (currentBounds.width / 2), y: currentBounds.y + (currentBounds.height / 2) },
                                    { x: itemBounds.x + (itemBounds.width / 2), y: itemBounds.y + (itemBounds.height / 2) }
                                )
                            }
                        );
                    }
                }

                if (found)
                {
                    // console.log(foundItems);

                    return items.indexOf(
                        foundItems.sort((a, b) => a.dist - b.dist)[0].item
                    );
                }

                // console.log("adding padding");
                beamPadding += 50;
            }
        }
        else
        {
            return items.indexOf(itemsInDir[0]);
        }
    }

    switchScanCurrentRow(dir) // 1 or -1
    {
        // already inside row, move to next item

        //  console.log(`move current item ${dir === 1 ? "right" : "left"}`);

        this.currentItem += dir;

        // console.log("current item", this.currentItem);
        // console.warn("current row", this.currentRow, dir);

        // if we've gone back to zero, set item to end of row (going backwards)
        if (this.currentItem === -1)
        {
            //  console.log("* current item = -1");
            this.currentRow--;
            if (this.currentRow === -1)
            {
                this.currentRow = this.rows.length - 1;
            }

            // current row is valid, select it and init first item
            this.selectRow(this.rows[this.currentRow]);
            this.currentItem = 0;

            if (this.rows[this.currentRow].items.length === 1)
            {
                this.currentItem = 0;
                this.selectItem(this.rows[this.currentRow].items[this.currentItem]);
            }

            return;
        }

        // check we're not at the end of a row
        if (this.currentItem >= this.rows[this.currentRow].items.length)
        {
            // we are on last item in current row, so exit the row
            this.currentItem = -1;

            // if the row only had only one item, go to next row
            // if (this.rows[this.currentRow].items.length === 1)
            // {
            this.currentRow += dir;

            if (this.currentRow === -1)
            {
                this.currentRow = this.rows.length - 1;
            }

            if (this.currentRow >= this.rows.length)
            {
                this.currentRow = -1;
                this.currentItem = -1;
                this.initSelection(earthpixi.Access.getAvailableSwitchButtons());
            }
            else
            {
                this.selectRow(this.rows[this.currentRow]);

                // if row only has one item enter the row
                if (this.rows[this.currentRow].items.length === 1)
                {
                    this.enterRow();
                }
            }
            // }
            // else
            // {
            //     // this.currentRow = -1;
            //     this.currentItem = -1;
            //     this.selectRow(this.rows[this.currentRow]);
            //
            //     // if row only has one item enter the row
            //     if (this.rows[this.currentRow].items.length === 1)
            //     {
            //         this.enterRow();
            //     }
            // }
        }
        else
        {
            this.selectItem(this.rows[this.currentRow].items[this.currentItem]);
        }
    }

    switchUp()
    {
        // console.log("up");
        this.switch1Down(0, -1);
    }

    switchDown()
    {
        // console.log("down");
        this.switch1Down(0, 1);
    }

    switchLeft()
    {
        // console.log("left");
        this.switch1Down(-1, 0);
    }

    switchRight()
    {
        // console.log("right");
        this.switch1Down(1, 0);
    }

    switch2Down(evt)
    {
        if (!this._init)
        { return; }

        // console.log("switch 2 down");

        if (

            earthpixi.Access.settings.switchConfig.switchMode === 1
            && !evt.gamepad
            && !earthpixi.Access.nowScanning
        )
        {
            return;
        }

        if (
            earthpixi.Access.settings.switchConfig.switchMode === 2
            && this.currentRow === -1
        )
        {
            return;
        }

        if (this.currentRow === -1)
        {
            this.switch1Down();

            return;
        }

        if (this.currentItem === -1)
        {
            if (earthpixi.Access.settings.switchConfig.switchMode === 1)
            {
                earthpixi.Access.initScanTimer();
            }

            this.enterRow();

            return;
        }

        // inside a row so click this item
        this.activateItem(this.rows[this.currentRow].items[this.currentItem]);
        // this.reset();
    }

    selectItem(item)
    {
        // console.log("select", item);

        if (!this._init)
        { return; }

        const bnds = this.getTotalBounds([item]);

        this.pointerOverItem(item);

        this._overItem = item;

        this.drawDisplay(bnds);
    }

    drawDisplay(bnds)
    {
        earthpixi.stageOverlay.addChild(this.displaySpr);
        this.displaySpr.visible = true;
        this.displaySpr.x = bnds.x;
        this.displaySpr.y = bnds.y;
        this.displaySpr.width = bnds.width;
        this.displaySpr.height = bnds.height;

        earthpixi.stageOverlay.addChild(this.displayGrp);
        this.displayGrp.clear();
        this.displayGrp.lineStyle(6, 0xFF00E5, 1);
        this.displayGrp.drawRoundedRect(bnds.x, bnds.y, bnds.width, bnds.height, 10);
    }

    pointerOverItem(item)
    {
        const interactionManager = earthpixi.renderer.plugins.interaction;

        if (!interactionManager.eventData.data)
        {
            interactionManager.eventData.data = {};
        }
        interactionManager.eventData.data.currentTarget = item;

        if (item.switchable && item.userEvents && item.userEvents.pointerover)
        {
            // interactionManager.eventData.data.currentTarget = item;
            // create a fake click for ep access button
            item.onPointerOver({
                currentTarget: item, data: interactionManager.eventData.data, stopPropagation: () => {}
            });
            // interactionManager.dispatchEvent(item, 'pointerup', interactionManager.eventData);
            // earthpixi.Access.eyeGazeItemWasHit(item);
            return;
        }

        // in direct mode
        if (item._events.pointerover)
        {
            interactionManager.dispatchEvent(item, "pointerover", interactionManager.eventData);
        }
    }

    pointerOutItem(item)
    {
        const interactionManager = earthpixi.renderer.plugins.interaction;

        if (!interactionManager.eventData.data)
        {
            interactionManager.eventData.data = {};
        }
        interactionManager.eventData.data.currentTarget = item;

        if (item.switchable && item.userEvents && item.userEvents.pointerout)
        {
            // interactionManager.eventData.data.currentTarget = item;
            // create a fake click for ep access button
            item.onPointerOut({
                currentTarget: item, data: interactionManager.eventData.data, stopPropagation: () => {}
            });
            // interactionManager.dispatchEvent(item, 'pointerup', interactionManager.eventData);
            // earthpixi.Access.eyeGazeItemWasHit(item);
            return;
        }

        // in direct mode
        if (item._events.pointerout)
        {
            interactionManager.dispatchEvent(item, "pointerout", interactionManager.eventData);
        }
    }

    activateItem(item)
    {
        // console.log(item);

        const interactionManager = earthpixi.renderer.plugins.interaction;

        if (!interactionManager.eventData.data)
        {
            interactionManager.eventData.data = {};
        }
        interactionManager.eventData.data.currentTarget = item;

        const eventData = {
            target: item, currentTarget: item, data: interactionManager.eventData.data, stopPropagation: () => {}
        };

        if (item.switchable && item.userEvents && item.userEvents.select)
        {
            // interactionManager.eventData.data.currentTarget = item;
            // create a fake click for ep access button

            // console.log("select");
            item.select(eventData);

            if (
                item.repeat
                && earthpixi.Access.settings.eyeGazeConfig.allowRepeat
                && earthpixi.Access.settings.switchConfig.switchMode !== 1
            )
            {
                // dont reset so they can select the button again
            }
            else
            {
                earthpixi.Access.reset();
            }

            if (!item._destroyed)
            {
                item.onPointerOut(eventData);
            }
            // interactionManager.dispatchEvent(item, 'pointerup', interactionManager.eventData);
            // earthpixi.Access.eyeGazeItemWasHit(item);
            return;
        }

        // normal interactive sprite in direct mode
        if (item._events.pointerdown)
        {
            // console.log("pointerdown");
            interactionManager.dispatchEvent(item, "pointerdown", eventData);
        }

        if (item._events.pointerup && !item._destroyed)
        {
            // console.log("pointerup");
            interactionManager.dispatchEvent(item, "pointerup", eventData);
        }

        earthpixi.Access.reset();
    }

    reset()
    {
        this.currentRow = -1;
        this.currentItem = -1;
        this._numItems = 0;
        this.displaySpr.visible = false;

        if (this._overItem)
        {
            this.pointerOutItem(this._overItem);
            this._overItem = null;
        }

        if (this.displaySpr.parent)
        { earthpixi.stageOverlay.removeChild(this.displaySpr); }

        if (this.displayGrp.parent)
        { earthpixi.stageOverlay.removeChild(this.displayGrp); }
    }

    enterRow()
    {
    // console.log("enter row");
        this.currentItem = 0;
        this.selectItem(this.rows[this.currentRow].items[this.currentItem]);
    }

    initSelection(scanItems, startOnTabIndex = false, overrideRowMode = false)
    {
        // console.log("init", earthpixi.Access.settings.switchConfig.rowSelectorMode, scanItems);

        this._overrideRowMode = overrideRowMode;

        if (earthpixi.currentScreen && earthpixi.currentScreen.onSwitchInit)
        {
            earthpixi.currentScreen.onSwitchInit(scanItems);
        }

        if (scanItems.length === 0)
        {
            console.log("no switch items", earthpixi.Access.soloButtonArr);

            return;
        }

        // init and setup row data

        this._numItems = scanItems.length;

        const scanItemsToSortByTabIndex = scanItems.slice(0);

        scanItemsToSortByTabIndex.sort(earthpixi.Access.sortButtonsOnPositionWithTabIndex);

        if (earthpixi.Access.settings.switchConfig.rowSelectorMode && !overrideRowMode)
        {
            this.rows = this.getRows(scanItems);
        }
        else
        {
            this.rows = [
                { totalBounds: null, items: scanItemsToSortByTabIndex }
            ];
        }

        // set row selecting to active
        this.currentRow = 0;

        // figure out row to start on
        if (startOnTabIndex)
        {
            const firstItem = scanItemsToSortByTabIndex[0];

            for (let r = 0; r < this.rows.length; r++)
            {
                if (this.rows[r].items.includes(firstItem))
                {
                    this.currentRow = r;
                    break;
                }
            }
        }

        if (this.rows[this.currentRow].items.length === 0 && this.rows.length > 0)
        {
            this.currentRow++;
        }

        // select first row
        this.selectRow();
        if (this.rows[this.currentRow].items.length === 1 || this.rows.length === 1)
        {
            this.enterRow();
        }

        // //get overall bounds of all items
        // let bnds = this.getTotalBounds(scanItems);
        // console.log(bnds);
        // this.displaySpr.x = bnds.x;
        // this.displaySpr.y = bnds.y;
        // this.displaySpr.width = bnds.width;
        // this.displaySpr.height = bnds.height;
    }

    sortOnTabIndex(a, b)
    {
        return a.tabIndex - b.tabIndex;
    }

    selectRow(rowData)
    {
        if (this.rows[this.currentRow].items.length === 0)
        { return; }

        const bnds = this.getTotalBounds(this.rows[this.currentRow].items);

        this.drawDisplay(bnds);
    }

    getRows(items)
    {
        const tabIndex = -1;
        const rows = [];
        let currentRowGroup;
        let currentRowY;
        let row;
        let numInCurrentRow;

        for (let i = 0; i < items.length; i++)
        {
            const item = items[i];
            const bnds = item.getBounds();
            const pos = new PIXI.Point(bnds.x, bnds.y);
            const pos2 = new PIXI.Point(bnds.x + bnds.width, bnds.y + bnds.height);
            const local = earthpixi.stageOverlay.toLocal(pos);
            const local2 = earthpixi.stageOverlay.toLocal(pos2);

            if (i === 0)
            {
                // first row
                const row = { totalBounds: null, items: [] };

                rows.push(row);
                currentRowGroup = item.rowGroup;
                currentRowY = local2.y;
            }
            else
            {
                // decide if we need new row
                let newRow = false;

                if (currentRowGroup !== null && item.rowGroup !== currentRowGroup)
                {
                    // console.log("new row, group",i);
                    currentRowGroup = item.rowGroup;
                    newRow = true;
                }
                else if (numInCurrentRow >= this.maxPerRow)
                {
                    // console.log("new row, max per row", numInCurrentRow, i);
                    newRow = true;
                }
                else if (local2.y > currentRowY + this.rowTolerence)
                {
                    // console.log("new row, row tolerence", numInCurrentRow, i);
                    currentRowY = local2.y;
                    newRow = true;
                }

                if (newRow)
                {
                    numInCurrentRow = 0;
                    currentRowY = local2.y;
                    row = { totalBounds: null, items: [] };
                    rows.push(row);
                }
            }

            // add item to row
            rows[rows.length - 1].items.push(item);
            numInCurrentRow++;
        }

        return rows;
    }

    getTotalBounds(items)
    {
        let minX; let minY; let maxW; let
            maxH;

        for (let i = 0; i < items.length; i++)
        {
            const bnds = items[i].getBounds();
            // let localBnds=items[i].getLocalBounds();

            const pos = new PIXI.Point(bnds.x, bnds.y);
            const pos2 = new PIXI.Point(bnds.x + bnds.width, bnds.y + bnds.height);
            const local = earthpixi.stageOverlay.toLocal(pos);
            const local2 = earthpixi.stageOverlay.toLocal(pos2);

            // set if not defined
            minX = minX === undefined ? local.x : minX;
            minY = minY === undefined ? local.y : minY;
            maxW = maxW === undefined ? local2.x : maxW;
            maxH = maxH === undefined ? local2.y : maxH;

            if (local.x < minX)
            { minX = local.x; }

            if (local.y < minY)
            { minY = local.y; }

            if (local2.x > maxW)
            { maxW = local2.x; }

            if (local2.y > maxH)
            { maxH = local2.y; }
        }

        return { x: minX, y: minY, width: maxW - minX, height: maxH - minY };
    }
}
