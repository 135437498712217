import GuideGrid from "../utils/GuideGrid";

/**
 * Base for a game screen. There can only be one Screen active at any time.
 *
 * Just a Pixi Container but we keep tabs on objects and tweens added to it, and clear out old screens when a new one is added.
 *
 * Instantiating a Screen immediately stops the previous/current screen
 *
 * @class
 * @extends {PIXI.Container}
 * @memberof earthpixi
 */
export default class Screen extends PIXI.Container
{
    /**
     *
     * @static
     * @type {Array<Object>}
     */
    static get assetList()
    {
        return [];
    }

    /**
     *
     * @param {boolean} [loading]
     */
    constructor(loading = false)
    {
        super();

        // we're loading a new screen so reset eveything like tweens / updates etc
        earthpixi.reset();

        if (!earthpixi.currentScreen)
        {
            earthpixi.currentScreen = this;
            earthpixi.currentScreen.first = true;
        }
        else
        {
            if (earthpixi.currentScreen.cancel)
            {
                earthpixi.currentScreen.cancel();
            }

            // const bounds = earthpixi.utils.Display.getScreenBounds();
            // //const bounds = earthpixi.stage.getLocalBounds();
            // console.log(bounds);
            // //const grabRect = new PIXI.Rectangle(0,0,Math.round(bounds.x + bounds.width), Math.round(bounds.y+ bounds.height));
            // const grabRect = new PIXI.Rectangle(0,0,earthpixi.config.STAGE_WIDTH,earthpixi.config.STAGE_HEIGHT);
            // const tex = earthpixi.renderer.generateTexture(earthpixi.stage, PIXI.settings.SCALE_MODE ,1);
            // earthpixi.currentScreenGrab = new PIXI.Sprite(tex);
            // earthpixi.currentScreenGrab.width = earthpixi.config.STAGE_WIDTH;
            // earthpixi.currentScreenGrab.height = earthpixi.config.STAGE_HEIGHT;

            earthpixi.stage.removeChild(earthpixi.currentScreen);
            earthpixi.currentScreen.destroy({ children: true, textures: true });
            earthpixi.oldScreen = earthpixi.currentScreen;
        }

        earthpixi.currentScreen = this;
        earthpixi.currentScreen.first = false;

        this.tweens = [];

        /**
         * @type {string | null}
         */
        this.screenMusic = null;

        if (earthpixi._guideGrid)
        {
            earthpixi.stageOverlay.removeChild(earthpixi._guideGrid);
            earthpixi._guideGrid.destroy();
        }

        if (earthpixi.config.SHOW_GRID && !loading)
        {
            earthpixi._guideGrid = new GuideGrid();
            earthpixi.stageOverlay.addChild(earthpixi._guideGrid);
        }
    }

    /**
     * Method called when screen has finished fading in by earthpixi.
     * Override this if you want to run anything when screen has finished fading in, i.e. start a game
     *
     */
    run()
    {
        console.log("YO! Add a run method to this screen if you want to do stuff just after screen fades in, start animations, sounds etc");
    }
}
