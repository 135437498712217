import AudioManager from "../audio/Audio";
import decideRes from "../core/decideResolution";
import appleHighEnd from "./appleHighEnd";
import onRender from "./onRender";
import onRenderFPS from "./onRenderFPS";
import onRenderWithStats from "./onRenderWithStats";
import resize from "./resize";
import ismobilejs from "ismobilejs";
const isMobile = ismobilejs(window.navigator);

import stats from "stats.js";
import getRenderer from "../../libs/getRenderer";

/**
 *
 * Initiates a PIXI Stage
 *
 * @memberof earthpixi
 * @function init
 * @param {HTMLElement | HTMLCanvasElement} [container] - Html element to insert canvas into, optional
 *
 */
export default function (container)
{
    const PIXI = global.PIXI;

    // do some setup
    PIXI.settings.GC_MODE = PIXI.GC_MODES.AUTO;
    PIXI.settings.RENDER_OPTIONS.autoResize = true;
    // PIXI.settings.MIPMAP_TEXTURES = true;

    // set some shortcut properties
    earthpixi.resolution = decideRes();

    earthpixi.rendererOptions = {
        resolution: earthpixi.resolution,
        forceCanvas: earthpixi.config.FORCE_CANVAS,
        transparent: earthpixi.config.TRANSPARENT_STAGE,
        backgroundColor: earthpixi.config.BACKGROUND_COLOR,
        antialias: false,
        // roundPixels:true, // now using internal PIXI ticker, but removing render event so we can call it ourselves
        // sharedTicker: false,
        // autoStart: false
    };

    earthpixi._rendererReady = true;

    if (isMobile.apple.device)
    {
        earthpixi._rendererReady = false;

        getRenderer(
            (glRendererString) =>
            {
                if (earthpixi._rendererReady) return;

                // console.log(glRendererString);

                if (appleHighEnd(glRendererString))
                {
                    earthpixi.resolution = 2;
                    earthpixi.rendererOptions.resolution = 2;
                    earthpixi.rendererOptions.antialias = true;
                }
                else
                {
                    earthpixi.resolution = 1;
                    earthpixi.rendererOptions.resolution = 1;
                    earthpixi.rendererOptions.antialias = false;
                    PIXI.settings.RENDER_OPTIONS.antialias = false;
                    PIXI.settings.SPRITE_BATCH_SIZE = 2048;
                    // PIXI.settings.SPRITE_MAX_TEXTURES = 4; // should be set by PIXI for mobiles anyway
                    // rendererOptions.forceFXAA = true,
                    earthpixi.rendererOptions.legacy = true;
                    earthpixi.config.TARGET_FPS = 31;
                    TweenMax.ticker.fps(31);
                }

                earthpixi._rendererReady = true;
                if (earthpixi._onRendererReady)
                {
                    earthpixi._onRendererReady();
                }

                // window.console.log(`Resolution update: @${earthpixi.resolution}x`);
            }
        );
    }

    earthpixi.container = container;

    // some legacy settings for older devices
    // settings for 1x res / older devices
    if (earthpixi.resolution === 1)
    {
        PIXI.settings.SPRITE_BATCH_SIZE = 2048;
        // PIXI.settings.SPRITE_MAX_TEXTURES = 4; // should be set by PIXI for mobiles anyway
        // rendererOptions.forceFXAA = true,
        earthpixi.rendererOptions.legacy = true;
        earthpixi.config.TARGET_FPS = 31;
        TweenMax.ticker.fps(31);
    }

    // Samsung galaxy problems
    if (isMobile.android.device)
    {
        PIXI.settings.CAN_UPLOAD_SAME_BUFFER = false;
        // preserveDrawingBuffer enable this if you need to call toDataUrl on the webgl context
        earthpixi.rendererOptions.preserveDrawingBuffer = true;
    }

    earthpixi.application = new PIXI.Application(window.innerWidth, window.innerHeight, earthpixi.rendererOptions);
    earthpixi.renderer = earthpixi.application.renderer;
    earthpixi.renderer.plugins.interaction.moveWhenInside = true;

    // if dom container supplied append to this or just append canvas to body of document
    if (container)
    {
        container.appendChild(earthpixi.renderer.view);
    }
    else
    {
        document.body.appendChild(earthpixi.renderer.view);
        earthpixi.renderer.view.style.position = "absolute";
        earthpixi.renderer.view.style.zIndex = "1000";
    }

    /**
   * Pixi root container
   *
   * @private
   */
    earthpixi._stageShell = earthpixi.application.stage;

    // debug antialias experiments
    // PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.HIGH;
    // let fxaaFilter = new PIXI.filters.FXAAFilter();
    // fxaaFilter.resolution = earthpixi.resolution;
    // earthpixi._stageShell.filters = [new PIXI.filters.FXAAFilter()];
    // earthpixi._stageShell.filterArea = earthpixi.renderer.screen;

    earthpixi.stage = new PIXI.Container();
    earthpixi.stageBackdrop = new PIXI.Container();
    earthpixi.stageOverlay = new PIXI.Container();

    earthpixi.stageCover = new PIXI.Graphics();
    earthpixi.stageBG = new PIXI.Graphics();
    // create stage cover
    if (!earthpixi.config.TRANSPARENT_STAGE)
    {
        earthpixi.stageCover.beginFill(0xFFFFFF, 1);
        earthpixi.stageCover.drawRect(0, 0, earthpixi.config.STAGE_WIDTH / 2, earthpixi.config.STAGE_HEIGHT / 2);
        earthpixi.stageCover.endFill();
        earthpixi.stageCover.tint = earthpixi.config.BACKGROUND_COLOR;
        earthpixi.stageCover.scale.set(8, 8);
        earthpixi.stageCover.position.set(-earthpixi.config.STAGE_WIDTH, 0);

        earthpixi.stageBG.beginFill(0xFFFFFF, 1);
        earthpixi.stageBG.drawRect(0, 0, earthpixi.config.STAGE_WIDTH / 2, earthpixi.config.STAGE_HEIGHT / 2);
        earthpixi.stageBG.endFill();
        earthpixi.stageBG.tint = earthpixi.config.BACKGROUND_COLOR;
        earthpixi.stageBG.scale.set(8, 8);
        earthpixi.stageBG.position.set(-earthpixi.config.STAGE_WIDTH, 0);
        earthpixi.stageBackdrop.addChild(earthpixi.stageBG);
    }

    // earthpixi.stageOverlay.addChild(earthpixi.stageCover);
    earthpixi._stageShell.addChild(earthpixi.stageBackdrop);
    earthpixi._stageShell.addChild(earthpixi.stage);
    earthpixi._stageShell.addChild(earthpixi.stageOverlay);

    if (earthpixi.config.SHOW_STATS)
    {
        earthpixi.stats = new stats();
        earthpixi.stats.domElement.style.position = "absolute";
        earthpixi.stats.domElement.style.left = "0px";
        earthpixi.stats.domElement.style.top = "0px";
        document.body.appendChild(earthpixi.stats.domElement);
    }

    /**
   *
   * used with earthpixi.pause method
   *
   * @type {boolean}
   * @private
   */
    earthpixi.onresize = resize;
    earthpixi.needsResize = true;

    earthpixi.renderer.view.style.display = "block";
    earthpixi.renderer.view.style.margin = "auto";
    earthpixi.renderer.view.style.outline = "none";

    window.addEventListener("resize", () =>
    {
        earthpixi.needsResize = true;
    });
    window.addEventListener("orientationchange", () =>
    {
        earthpixi.needsResize = true;
    });

    earthpixi.activateAudio = AudioManager.wakeAudio;
    document.addEventListener("touchend", earthpixi.activateAudio, true);
    earthpixi.renderer.plugins.interaction.on("pointerdown", earthpixi.activateAudio);
    earthpixi.renderer.plugins.interaction.on("pointerup", earthpixi.activateAudio);

    earthpixi.renderer.plugins.interaction.once("pointerdown", () =>
    {
        document.body.focus();
    });

    if (earthpixi.customize)
    {
        earthpixi.customize();
    }

    earthpixi._fpsDelta = 60 / earthpixi.config.TARGET_FPS;
    // we'll call render ourselves
    earthpixi.application.ticker.remove(earthpixi.application.render, earthpixi.application);
    earthpixi.ticker = earthpixi.application.ticker;
    earthpixi.ticker.add(earthpixi.stats ? onRenderWithStats : (earthpixi.config.TARGET_FPS >= 60 ? onRender : onRenderFPS));
    earthpixi.time = 0;

    const epTimeQuery = earthpixi.utils.getQueryStrings("epTime");

    earthpixi.timeScale = epTimeQuery ? parseInt(epTimeQuery, 10) : earthpixi.timeScale;

    earthpixi.ticker.start();
}
