/**
 * Perform simple hit tests.
 *
 * @namespace earthpixi.utils.SimpleCollide
 *
 *
 */
export default {
    /**
   * @static
   * @param {(PIXI.DisplayObject| PIXI.Sprite|PIXI.Container)} object1
   * @param {(PIXI.DisplayObject| PIXI.Sprite|PIXI.Container)} object2
   * @returns {boolean} Whether 2 objects are overlapping
   * @function earthpixi.utils.SimpleCollide#collideCheckBounds
   *
   */
    collideCheckBounds(object1, object2)
    {
        let bounds1; let
            bounds2;

        bounds1 = object1.getBounds();
        bounds2 = object2.getBounds();

        return (bounds1.x < bounds2.x + bounds2.width
      && bounds1.x + bounds1.width > bounds2.x
      && bounds1.y < bounds2.y + bounds2.height
      && bounds1.height + bounds1.y > bounds2.y);
    },

    /**
     *
     * @static
     * @param {PIXI.Point} point1
     * @param {number} rad1
     * @param {PIXI.Point} point2
     * @param {number} rad2
     * @function earthpixi.utils.SimpleCollide#collideCheckCircles
     */
    collideCheckCircles(point1, rad1, point2, rad2)
    {
        const a = point1.x - point2.x;
        const b = point1.y - point2.y;
        const c = (a * a) + (b * b);
        const radii = rad1 + rad2;

        return radii * radii >= c;
    },

    /**
     *
     *  depreciation
     * @private
     * @returns {boolean}
     *
     */
    collideCheck(object1, object2)
    {
        return earthpixi.utils.SimpleCollide.collideCheckBounds(object1, object2);
    }

};
