export default function (versionString)
{
    return (
    // versionString.includes("Apple A8") ||
        // versionString.includes("Apple A9")
        // versionString.includes("Apple A10")
        versionString.includes("Apple A10X")
    || versionString.includes("Apple A11")
    || versionString.includes("Apple A12")
    || versionString.includes("Apple A12")
    || versionString.includes("Apple A13")
    || versionString.includes("Apple A14")
    || versionString.includes("Apple A15")
    );
}
