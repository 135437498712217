/**
 * @namespace earthpixi.utils.Math
 */

export default {

    /**
   * Get a random int from between two ints
   *
   * @memberof earthpixi.utils.Math
   * @static
   * @param low lowest int
   * @param high highest int
   * @returns {number}
   */
    randomIntInRange(low, high)
    {
        return Math.round(low + (Math.random() * (high - low)));
    }

};
