import InputField from "./InputField";

/**
 *
 *
 * Create text inputs and simple forms, perform simple form validation.  Any Input fields you create or onSubmit listeners you add wil be destroyed on loading another screen.
 *
 * Example basic textfield that uses PIXI.Text (no caret or field editing)
 *
 * ```js
 * this.input1 = earthpixi.Input.createInput({
 *   fontFamily: "Arial",
 *   fontSize: 30
 * }, "enter text..", 0xFF00FF, 500, false, false, false);
 *
 * this.input1.position.set(200,200);
 * this.addChild(this.input1);
 *
 * earthpixi.Input.onSubmit = this.onSubmit.bind(this);
 *
 * onSubmit()
 * {
 *    console.log(this.input1.text);
 * }
 *```
 *
 *
 *
 * Example Name and Email Input Form in a Screen with Bitmap font
 *
 *  ```js
 * addInput(){
 *
 *  //load font (would normally add font to Screen asset list)
 *  this.fontLoader = new earthpixi.utils.InScreenLoader(
 *    [
 *     {name: "sweater_font", url: "./font/sweater_bold" + earthpixi.resolution + "x.fnt"}
 *    ],
 *    this.onFontLoaded,
 *    this
 *  );
 *
 *  //set what to do when form submitted (device Enter button hit);
 *  earthpixi.Input.onSubmit = this.onSubmit.bind(this);
 *
 *  //we can listen for when all fields have been filled with valid text
 *  //(ie edited and not empty and email fields have valid email)
 *  earthpixi.Input.onAllValid = this.inputsValid.bind(this);
 *  //listen for change in fields which makes them invalid again
 *  earthpixi.Input.onAllNotValid = this.inputsNotValid.bind(this);
 *
 * }
 *
 *
 * onFontLoaded(){
 *
 *    this.nameInput = earthpixi.Input.createInput(
 *      {font: "30px sweater_bold"},
 *      "enter name...", 0xFF00FF, 500, false, false, true, true
 *    );
 *    this.nameInput.position.set(415, 150);
 *    this.nameInput._activate(); //focus the input field
 *    this.addChild(this.nameInput);
 *
 *    this.emailInput = this.emailInput = earthpixi.Input.createInput(
 *      {font: "30px sweater_school"},
 *      "enter email", 0x000000, 500, true
 *    );
 *    this.emailInput.position.set(415,250);
 *    this.addChild(this.emailInput)
 * }
 *
 * onSubmit(){
 *
 *  console.log(this.nameInput.text)
 *
 * }
 *
 *
 *
 * ```
 *
 * @namespace earthpixi.Input
 *
 * @property {method} onSubmit set this method to be called when current inputs submitted
 * @property {method} onAllValid set this method to be called when all inputs filled correctly
 * @property {method} onAllNotValid set this method to be called when all inputs stop being valid
 *
 *
 * //TODO use own keyboard example ask Rich
 *
 *
 *
 */
export default class InputsController
{
    /**
   * @private
   */
    constructor()
    {
        this.init();

        return this;
    }

    /**
     * @private
     */
    init()
    {
        //console.log("init input controller");

        this.InputField = InputField;

        /**
         * @memberof earthpixi.Input
         * @type {Function | null}
         * @name onSubmit
         */
        this.onSubmit = null;
        this.inputs = {};
        this.inputIDSalt = 100;
        this.onAllValid = null;
        this.onAllNotValid = null;
        this.inputsArray = [];
        this.BACKSPACE = 8;// 'backspace';
        this.DEL = 46;// 'delete';
        this.SHIFT = 16;// 'shift';
        this.ENTER = 13;// 'enter';
        this.SPACE = 32;// 'space';
        this.TAB = 9;// 'space';
        this.LEFT = 37;// 'space';
        this.RIGHT = 39;// 'space';

        this.allValid = false;

        this.codes = {
            8: this.BACKSPACE, 16: this.SHIFT, 13: this.ENTER, 32: " ",
            // and some funny characters
            46: ".", 191: "?", 222: "'"
        };

        this.keyDownBind = this.onKeyDown.bind(this);
        this.keyUpBind = this.onKeyUp.bind(this);
        this.onPressBind = this.onKeyPress.bind(this);
        this.onPasteBind = this.onPaste.bind(this);

        window.addEventListener("keydown", this.keyDownBind);
        window.addEventListener("keyup", this.keyUpBind);
        window.addEventListener("keypress", this.onPressBind);
        window.addEventListener("paste", this.onPasteBind);
    }

    createSoftKeyboardInput()
    {
        if (this.virtualForm)
        { return; }

        earthpixi.renderer.view.setAttribute("tabindex", 0);

        this.virtualForm = document.createElement("form");
        this.virtualForm.addEventListener("submit", this.onFormSubmit.bind(this));
        this.virtualForm.style.position = "absolute";
        this.virtualForm.style.width = "1px";
        this.virtualForm.style.top = "0px";
        this.virtualForm.setAttribute("action", "#");

        this.virtualSubmit = document.createElement("input");
        this.virtualSubmit.style.position = "absolute";
        this.virtualSubmit.style.width = "1px";
        this.virtualSubmit.style.marginLeft = "-1000px";
        this.virtualSubmit.setAttribute("type", "submit");
        this.virtualForm.appendChild(this.virtualSubmit);

        /**
         * @name virtualTF
         * @memberOf earthpixi.Input
         * @type {HTMLInputElement}
         */
        this.virtualTF = document.createElement("input");
        this.virtualTF.style.position = "absolute";
        this.virtualTF.style.width = "1px";
        this.virtualTF.style.top = "0px";
        this.virtualTF.style.marginLeft = "-1000px";

        this.virtualTF.setAttribute("autocomplete", "off");
        this.virtualTF.setAttribute("name", "tf1");
        this.virtualTF.setAttribute("autocorrect", "off");
        this.virtualTF.setAttribute("autocapitalize", "off");
        this.virtualTF.setAttribute("spellcheck", false);

        // ios scroll on keyboard popup/////
        const resetScroll = function ()
        {
            // if (window.top !== window)
            // {
            //     window.top.scrollTop = 0;
            //     window.top.document.body.scrollTop = 0;
            // }
            window.scrollTop = 0;
            window.document.body.scrollTop = 0;
            clearTimeout(earthpixi.virtualFocusTimeOut);
        };

        this.virtualTF.addEventListener("focus", function (e)
        {
            e.preventDefault(); e.stopPropagation();
            if (earthpixi.virtualFocusTimeOut)
            {
                clearTimeout(earthpixi.virtualFocusTimeOut);
            }
            earthpixi.virtualFocusTimeOut = setTimeout(resetScroll, 300);
        });

        this.virtualTF.style.zIndex = -100;
        this.virtualForm.appendChild(this.virtualTF);

        document.body.appendChild(this.virtualForm);
    }

    /**
   * Create a text input
   *
   * originally coded for BitmapText, so pass in bitmap text style format see PIXI.extras.BitmapText
   * @function earthpixi.Input#createInput
   * @static
   * @param {object} style - see PIXI.BitmapText / PIXI.Text for style format
   * @param {string} text - initial value for text input if needed i.e "tap to enter text"
   * @param {number} tint - used for Caret
   * @param {number} width - width of text input needed (overflow text is masked)
   * @param {boolean} isEmail - if email address, will validate for email address when checked
   * @param {boolean} [hasOwnKeyBoard=false] - if using your own in game keyboard, so wont use device keyboard. Use own events and use input.updateText(string) to change value in the input
   * @param {boolean} [bitmapText=true] type of text used for the input
   * @param {boolean} [mask=true] hide text overflow
   * @param {number} [extraHitHeight=90] add more hit area under input
   * @param {boolean} [numeric=false]
   * @param {number} [decLimit=null] number of decimal places if numeric
   * @param {number} [numLines=1] Number of lines to use
   * @param {number} [padding] Background padding
   * @returns {earthpixi.Input.InputField}
   *
   */
    createInput(style, text, tint, width, isEmail = false, hasOwnKeyBoard = false, bitmapText = true, mask = false, extraHitHeight = 90, numeric = false, decLimit = null, numLines = 1, padding = 0)
    {
        const newInput = new InputField(style, text, tint, width, isEmail, hasOwnKeyBoard, bitmapText, mask, extraHitHeight, numeric, decLimit, numLines, padding);

        if (hasOwnKeyBoard)
        {
            //console.log("input has own keyboard so disabling device keyboard, all inputs must have their own keyboard now", this.virtualForm);

            if (this.virtualForm)
            {
                document.body.removeChild(this.virtualForm);
                this.virtualForm = null;
                this.virtualTF = null;
            }

            window.removeEventListener("keydown", this.keyDownBind);
            window.removeEventListener("keyup", this.keyUpBind);
            window.removeEventListener("keypress", this.onPressBind);
        }

        return newInput;
    }

    onFormSubmit(e)
    {
        e.preventDefault();

        // console.log("try form submit");

        if (!this.activeInput || this.formSubmitSuppress)
        {
            // console.log("no active tf");
            return;
        }

        if (this.allValid && this.onSubmit)
        {
            if (this.activeInput)
            {
                this.activeInput._deActivate();
                this.onSubmit();
            }
        }
        else
        {
            for (let i = 0; i < this.inputsArray.length; i++)
            {
                this.inputsArray[i].checkContent(true);
            }
            if (this.activeInput.valid && this.inputsArray.length)
            {
                this.tabInput();
            }
        }
    }

    /**
     * @member earthpixi.Input#reset
     */
    reset()
    {
        this.inputs = {};
        this.inputsArray = [];
        this.onAllValid = null;
        this.onAllNotValid = null;
        this.onSubmit = null;
        this.activeInput = null;

        if (this.virtualTF)
        {
            this.virtualTF.blur();
            if (document.activeElement)
            {
                document.activeElement.blur();
            }
            if (earthpixi.Input.virtualForm
                && earthpixi.Input.virtualForm.parentNode)
            {
                earthpixi.Input.virtualForm.parentNode.removeChild(earthpixi.Input.virtualForm);
            }
        }
    }

    /**
   * @function earthpixi.Input#registerInput
   * @param input
   */
    registerInput(input)
    {
        input.id = this.getID();
        this.inputsArray.push(input);
        this.inputs[input.id] = input;

        if (!input.hasOwnKeyboard && earthpixi.utils.isTouchDevice)
        {
            this.createSoftKeyboardInput();
        }
    }

    /**
   * @function earthpixi.Input#deRegisterInput
   * @param input
   */
    deRegisterInput(input)
    {
        const index = this.inputsArray.indexOf(input);

        this.inputsArray.splice(index, 1);
        this.inputs[input.id] = null;
    }

    /**
   * @function earthpixi.Input#activateInput
   * @param {earthpixi.Input.InputField}
   */
    activateInput(input)
    {
    // activate input
        earthpixi.renderer.view.focus();
        // console.log(this);
        this.deActivateAll();
        input._activate();
        this.activeInput = input;
        this.activeInputID = input.id;
    }

    /**
     * @function earthpixi.Input#activateInput
     * @param {earthpixi.Input.InputField} input
     * @param {boolean} update
     */
    deActivateInput(input, update = true)
    {
        if (update)
        {
            input._deActivate();
        }
        if (this.activeInput === input)
        {
            this.activeInput = null;
            this.activeInputID = null;
        }
    }


    onKeyDown(e)
    {
        if (!this.activeInput || this.keyDownSupress)
        { return; }

        if (this.activeInput.active)
        {
            let str = "";

            if (e.keyCode !== null && e.keyCode !== 0 && e.keyCode !== 229)
            {
                str = this.activeInput.textField.text;

                if (e.keyCode === this.BACKSPACE)
                {
                    if (str.length > 0)
                    {
                        // str = str.substring(0, str.length - 1);
                        if (this.activeInput)
                        { this.activeInput.deleteText(); }
                    }
                    e.preventDefault();
                }
                else if (e.keyCode === this.ENTER)
                {
                    if (this.allValid && this.onSubmit)
                    {
                        if (this.activeInput)
                        {
                            this.onSubmit();
                            this.formSubmitSuppress = true;
                        }
                    }
                }
                else if (e.keyCode === this.TAB)
                {
                    e.preventDefault();
                    this.tabInput();
                }
                else if (e.keyCode === this.LEFT)
                {
                    e.preventDefault();
                    this.activeInput.moveLeft();
                }
                else if (e.keyCode === this.RIGHT)
                {
                    e.preventDefault();
                    this.activeInput.moveRight();
                }
            }
            else
            {
                // android devices dont always return key codes
                this.onKeyUpFlag = true;
            }
        }
    }


    onKeyUp(e)
    {
        if (this.onKeyUpFlag)
        {
            // console.log("on key up");
            const str = this.virtualTF.value;

            this.activeInput.caretIndex = this.virtualTF.selectionStart;
            this.activeInput.updateText(str);

            this.onKeyUpFlag = false;
        }
    }


    onKeyPress(e)
    {
        if (!this.activeInput)
        { return; }

        if (this.activeInput.active)
        {
            const str = this.activeInput.textField.text;

            if (e.keyCode === this.BACKSPACE)
            {
                e.preventDefault();
            }
            else if (e.keyCode === this.ENTER)
            {
                // if (this.allValid && this.onSubmit != null) {
                //   if(this.activeInput){
                //     this.activeInput.deActivate();
                //     this.onSubmit();
                //     this.formSubmitSuppress = true;
                //   }
                // }
            }
            else if (e.keyCode === this.TAB)
            {
                e.preventDefault();
                // this.tabInput();
            }
            else if (e.keyCode === this.SHIFT)
            {
                // this.activeInput.submit()
            }
            else
            {
                // str = str + String.fromCharCode(e.keyCode || e.charCode);
                // this.activeInput.updateText(str);
                this.activeInput.addText(String.fromCharCode(e.keyCode || e.charCode));
            }
        }
    }

    onPaste(evt)
    {
        // console.log("paste!");
        evt.preventDefault();
        evt.stopPropagation();

        // Get the clipboard data
        let paste = (evt.clipboardData || window.clipboardData).getData("text");

        // remove non-UTF-8 characters
        paste = paste.replace(/[^\x20-\xFF]/gi, "");

        if (this.activeInput)
        { this.activeInput.addText(paste); }
    }


    tabInput()
    {
        let tabbed = false;
        let found = false;

        for (let i = 0; i < this.inputsArray.length; i++)
        {
            if (this.inputsArray[i] === this.activeInput)
            {
                found = true;
            }
            else
            if (found && !tabbed)
            {
                this.activateInput(this.inputsArray[i]);
                tabbed = true;
                break;
            }
        }
        if (!tabbed)
        {
            this.activateInput(this.inputsArray[0]);
        }
    }

    getID()
    {
        this.inputIDSalt++;

        return this.inputIDSalt;
    }

    /**
   *  @function earthpixi.Input#deActivateAll
   */
    deActivateAll()
    {
        for (const property in this.inputs)
        {
            if (this.inputs.hasOwnProperty(property))
            {
                this.inputs[property]._deActivate();
            }
        }
    }

    /**
     * @function earthpixi.Input#checkAll
     */
    checkAll()
    {
        let allValid = true;

        for (let i = 0; i < this.inputsArray.length; i++)
        {
            if (!this.inputsArray[i].valid)
            {
                allValid = false;
            }
        }

        this.allValid = allValid;

        if (allValid)
        {
            // e.log("all valid");
            if (this.onAllValid != null)
            {
                this.onAllValid();
            }
        }
        else
        if (this.onAllNotValid != null)
        {
            this.onAllNotValid();
        }
    }
}

