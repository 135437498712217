/**
 * @class
 * @memberof earthpixi.Access
 *
 */
export default class SwitchConfig
{
    constructor()
    {
    /**
     *
     * @type {boolean}
     */
        this.autoStartScanning = false;
        /**
     *
     * @type {number}
     */
        this.scanTime = 2;
        /**
     *
     * @type {number}
     */
        this.switchMode = 2;
        /**
     *
     * @type {boolean}
     */
        this.rowSelectorMode = false;
        /**
     *
     * @type {boolean}
     */
        this.directMode = false;
        /**
     *
     * @type {number}
     */
        this.switch1KeyCode = 32;

     /**
     * which keys do "select" - default Enter, x and a for gamepads
     * @type {number}
     */
        this.switch2KeyCode = 13;
        /**
     *
     * @type {number}
     */
        this.debounceTimeout = 300;// milliseconds
        /**
     *
     * @type {number}
     */
        this.switchAutoKeyTimeout = 500;// milliseconds
    }

    /**
   *
   * @param {SwitchConfig} config
   */
    copy(config)
    {
        if (config.autoStartScanning !== undefined)
        { this.autoStartScanning = config.autoStartScanning; }
        if (config.scanTime !== undefined)
        { this.scanTime = config.scanTime; }
        if (config.switchMode !== undefined)
        { this.switchMode = config.switchMode; }
        if (config.rowSelectorMode !== undefined)
        { this.rowSelectorMode = config.rowSelectorMode; }
        if (config.directMode !== undefined)
        { this.directMode = config.directMode; }
        if (config.switch1KeyCode !== undefined)
        { this.switch1KeyCode = config.switch1KeyCode; }
        if (config.switch2KeyCode !== undefined)
        { this.switch2KeyCode = config.switch2KeyCode; }
        if (config.debounceTimeout !== undefined)
        { this.debounceTimeout = config.debounceTimeout; }
        if (config.switchAutoKeyTimeout !== undefined)
        { this.switchAutoKeyTimeout = config.switchAutoKeyTimeout; }
    }

    /**
   *
   * @return {object}
   */
    toJSON()
    {
        return {
            autoStartScanning: this.autoStartScanning,
            scanTime: this.scanTime,
            switchMode: this.switchMode,
            rowSelectorMode: this.rowSelectorMode,
            directMode: this.directMode,
            switch1KeyCode: this.switch1KeyCode,
            switch2KeyCode: this.switch2KeyCode,
            debounceTimeout: this.debounceTimeout,
            switchAutoKeyTimeout: this.switchAutoKeyTimeout
        };
    }
}
