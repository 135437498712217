/**
 *
 * Extended version of PIXI.Spine to work with earthpixi.
 *
 * Easily handle events, match up to an audio sprite to play sound events from spine.
 *
 *
 * **Spine export settings:**
 *
 * - file Extension = change to **.spine_data** instead of .json (stop Loader trying create spine textures as we'll do this manually)
 * - Format = JSON
 * -
 *
 * **Loading assets:**
 *
 * Create spine texture assets with Texturepacker to make @1x and @2x atlases to load as needed, rather than use Spine IDE texturepacker.
 * To use these,  we then need to create a SpineAtlas, rather than let PIXI spine loader handle this.
 *
 *
 * Example load assets for Yeti animation
 * ```js
 static get assetList() {
    return list = [

      //spine files
      {name: "yeti_spine", url: earthpixi.config.SPINE_ROOT + "yeti.spine_data"},
      {name: "yeti_atlas1", url: earthpixi.config.SPINE_ROOT + "yeti/yeti1@" + earthpixi.resolution + "x.json"},
      {name: "yeti_atlas2", url: earthpixi.config.SPINE_ROOT + "yeti/yeti1@" + earthpixi.resolution + "x.json"},

      //audio sprite to go with animation
      {name: "yeti_audio_sprite", url: earthpixi.config.AUDIO_ROOT + "yeti/yeti_sprite.mp3", options: {
          loadType: PIXI.loaders.Resource.LOAD_TYPE.XHR, xhrType: PIXI.loaders.Resource.XHR_RESPONSE_TYPE.BLOB
        }
      },
      {name: "yeti_audio_sprite_data", url: earthpixi.config.AUDIO_ROOT + "yeti/yeti_sprite.json"},

    ];
  }
 ```
 Create spine animation when assets loaded,  in your Screen:
 ```js

 //optionaly create use an audio sprite for the yeti animation
 const audioBlob = URL.createObjectURL(earthpixi.resources.yeti_audio_sprite.data);
 earthpixi.Audio.createSprite("yeti_sounds", [audioBlob], earthpixi.resources.yeti_audio_sprite_data.data.sprite);

 //create a spine texture atlas using names from asset list
 const spineAtlas = earthpixi.utils.SpineUtils.createSpineAtlas(["yeti_atlas1", "yeti_atlas2"]);

 //create a Spine aninimation Sprite
 const yeti = new earthpixi.utils.SpineEP(spineAtlas, earthpixi.resources.yeti_spine.data, "yeti_sounds", 0.5, true);
 this.addChild(yeti)

 //set skin if it has one
 yeti.setSkin("pink");

 // Set some animations like a normal PIXI.spine.Spine object

 // - setAnimation(trackIndex, animationName, loop)
 // - addAnimation(trackIndex, animationName, loop, delay seconds before starting)
 // trackIndex is for more complex animations, just use 0 / same value or see Spine docs

 yeti.state.setAnimation(0,"wave",false);
 yeti.state.addAnimation(0,"idle",true, 0);

 //listen for end of animation
 yeti.addEvent("complete", this.onYetiComplete,this);

 //listen for a custom event added in Spine authoring
 yeti.addEvent("bounce", this.onYetiBounce,this);

 onYetiBounce(event){
    ...
 }

 ```

 *
 *
 *
 *
 *
 *
 * @memberof earthpixi.utils
 * @extends {PIXI.spine.Spine}
 */
export default class SpineEP extends PIXI.spine.Spine
{
    /**
     * @constructor
     * @param {PIXI.spine.core.TextureAtlas} spineAtlas Create a spineAtlas to pass in with earthpixi.utils.SpineUtils.createSpineAtlas
     * @param {object} spineData Get from loaded resources, eg. earthpixi.resources["my_spine_data"].data
     * @param {string | null} [audioSprite] name of audio sprite to use if you have one,  spine audio events will play the parts in the sprite that match up
     * @param {number | null} [scale] default 1
     * @param {boolean | null} [autoUpdate] set to true for animation to play without having to manually update the animation
     * @param {boolean | null} [alphaFilter] set to true if you want alpha filter, allows setting alpha without seeing spine layers overlapping
     */
    constructor(spineAtlas, spineData, audioSprite, scale, autoUpdate = false, alphaFilter = false)
    {
        // If we're using this class then we shouldn't need this on, we can't turn it off globally for older games so stick this in here
        PIXI.spine.Spine.globalAutoUpdate = false;

        const attachmentParser = new PIXI.spine.core.AtlasAttachmentLoader(spineAtlas);
        const spineJsonParser = new PIXI.spine.core.SkeletonJson(attachmentParser);
        let skeletonData;

        skeletonData = spineJsonParser.readSkeletonData(spineData);

        super(skeletonData);

        if (alphaFilter)
        {
            this._aFilter = new earthpixi.utils.AlphaFilter(1);
            this._aFilter.resolution = earthpixi.resolution;
            this.filters = [this._aFilter];
        }

        this.scale.set(scale ? scale * 0.5 : 0.5);// 0.5 is full size when using earthpixi resolution.. I can't remember why!
        this.ep_events = [];
        this.ep_audio = audioSprite;
        /**
         *
         * @type {boolean}
         */
        this.tryForMissingAudio = true;

        // set spine super object autoupdate to false, use EP update instead;
        this.autoUpdate = false;
        this.update(0);

        this.eventBind = this.onEvent.bind(this);

        this.state.addListener({
            event: this.eventBind,
            complete: this.onComplete.bind(this),
            start: this.onStart.bind(this),
            end: this.onEnd.bind(this),
        });

        if (autoUpdate)
        {
            earthpixi.addUpdateItem(this);
        }
    }

    /**
     * Get / set the alpha value.  See alpha filter option when instantiating SpinEP
     * @type {number}
     *
     */
    get alpha()
    {
        return this._aFilter ? 1 : this._alpha;
    }

    set alpha(val)
    {
        if (this._aFilter)
        {
            this._aFilter.alpha = val;
        }
        else
        {
            this._alpha = val;
        }
    }

    /**
     *
     * @param {string} eventName defaults included are "start", "complete", "allcomplete", "end",
     * @param {function} callback
     * @param scope
     */
    addEvent(eventName, callback, scope)
    {
        // console.log("add event", eventName);
        this.ep_events.push({ eventName, callback, scope });
    }

    /**
     *
     * TODO issue with multiple complete events
     * @param {string} eventName
     */
    removeEvent(eventName)
    {
        const remove = [];
        let i = 0;

        for (i = 0; i < this.ep_events.length; i++)
        {
            if (this.ep_events[i].eventName === eventName)
            {
                remove.push(this.ep_events[i]);
            }
        }
        for (i = 0; i < remove.length; i++)
        {
            this.ep_events.splice(this.ep_events.indexOf(remove[i]), 1);
        }
    }

    onEvent(entry, event)
    {
        // console.log("event", entry, event);

        if (!event)
        {
            return;
        }

        // console.log(this.ep_events);

        const callBacks = [];

        for (let i = 0; i < this.ep_events.length; i++)
        {
            const evt = this.ep_events[i];

            if (event.data.name === evt.eventName)
            {
                callBacks.push({ callback: evt.callback, scope: evt.scope, event });
                // evt.callback.apply(evt.scope, [event]);
            }
        }

        for (const cb of callBacks)
        {
            cb.callback.apply(cb.scope, [cb.event]);
        }

        // console.log(event);

        if (event.stringValue && event.stringValue !== "")
        {
            /**
             *
             * Event fired when a string value is found on a Spine Animation event
             *
             * //TODO think about CC / subtitles for spine animations
             *
             * @event earthpixi.utils.SpineEP#stringonevent
             * @param {string} string that has appeared on an event
             * @param {PIXI.spine.core.Event} event - Spine event
             */
            this.emit("stringonevent", event.stringValue, event);
        }

        if (event.data.name === "stop")
        {
            this.pause();

            return;
        }

        if (event.data.name.startsWith("sfx"))
        {
            const sfxName = event.data.name.replace("sfx_", "");

            this.tryPlaySfx(sfxName);

            return;
        }

        if (event.data.audio && event.data.audio !== "")
        {
            this.tryPlaySfx(event.data.audio);

            return;
        }

        if (event.data.audioPath && event.data.audioPath !== "")
        {
            this.tryPlaySfx(event.data.audioPath);

            return;
        }
    }

    findAndExecuteEvent(eventName, trackEntry)
    {
        const callBacks = [];

        for (let i = 0; i < this.ep_events.length; i++)
        {
            const evt = this.ep_events[i];

            if (evt.eventName === eventName)
            {
                callBacks.push({ callback: evt.callback, scope: evt.scope, params: [eventName, trackEntry, this] });
            }
        }

        for (const cb of callBacks)
        {
            cb.callback.apply(cb.scope, cb.params);
        }
    }

    onStart()
    {
        this.findAndExecuteEvent("start");
    }

    onComplete(trackEntry)
    {
        this.findAndExecuteEvent("complete");

        if (trackEntry.next === undefined || trackEntry.next === null)
        {
            this.findAndExecuteEvent("allcomplete", trackEntry);
        }
    }

    onEnd()
    {
        this.findAndExecuteEvent("end");
    }

    pause()
    {
        if (this.state.timeScale !== 0)
        {
            this.savedTimeScale = this.state.timeScale;
            this.state.timeScale = 0;
        }
    }

    resume()
    {
        if (this.state.timeScale === 0)
        {
            this.state.timeScale = this.savedTimeScale || 1;
        }
    }

    tryPlaySfx(nameOrUrl)
    {
        if (this.ep_audio)
        {
            // console.log("play audio", nameOrUrl, earthpixi.Audio._sprites[this.ep_audio]);
            // remove file extension in string if found
            nameOrUrl = nameOrUrl.replace(/\.[0-9a-z]+$/i, "");

            if (earthpixi.Audio._sprites[this.ep_audio])
            {
                earthpixi.Audio.playSprite(this.ep_audio, nameOrUrl);
            }
        }
        else if (this.tryForMissingAudio)
        {
            console.log(`SPINE EP no audio sprite id or url specified:${nameOrUrl}`);
            // else try playing a sound file if it has a file extension
            if (nameOrUrl.search(/\.[0-9a-z]+$/i) !== -1)
            {
                earthpixi.Audio.playSFX(nameOrUrl);
            }
        }
    }

    destroy(options)
    {
        this.state.clearListeners();
        this.state.setEmptyAnimations(0);
        this.update = () =>
        {
        };
        super.destroy(options);
    }

    update(deltaTime, epTimeElapse)
    {
        super.update(epTimeElapse || deltaTime);
    }

    /**
     *
     * @param {string} skinName
     */
    setSkin(skinName)
    {
        // fixes some issues with skins
        this.skeleton.skin = null;
        // set the skin
        this.skeleton.setSkinByName(skinName);
    }
}
